import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P79-E2",
    exerciseKey: "img/FriendsPlus/Page79/Key/E2answerKey.png",
    component: T6,
    audio: "Audios/2.15.mp3",
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 100,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["about", "at", "in", "of", "on"],
        width: 500,
        borderColor: "#203c8f",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.15 src='Audios/2.15 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Find adjectives 1–8 in the 
        questionnaire and match them with the 
        prepositions in the box. Listen and check.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; font-size: 20px; line-height: 55px; width: 28cm; margin-left: -50px '>
	        <img src="img/FriendsPlus/Page78/E1/1.jpg" style='height:35cm; width: 29cm; margin-left: -20px '/>
          
          <div style="position: absolute; top: 944px; left: 500px">
          <div style='display: flex; margin-top: 10px; margin-left: 30px'>
            <div>
              <div>bad &ensp; &ensp; <i style='color: gray'>at</i></div>
              <div><b>1</b>&ensp;fascinated #</div>
              <div><b>2</b>&ensp;terrified #</div>
              <div><b>3</b>&ensp;happy #</div>
              <div><b>4</b>&ensp;thrilled #</div>
              
            </div>
            <div style='margin-left: 58px'>
              <div></div><br>
              <div><b>5</b>&ensp;keen #</div>
              <div><b>6</b>&ensp;nervous #</div>
              <div><b>7</b>&ensp;scared #</div>
              <div><b>8</b>&ensp;worried #</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        answer: ["by", "of", "about", "at", "on", "about", "of", "about"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P79-E3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E3answerKey.png",
    component: T6,
    audio: "Audios/2.16.mp3",
    // hideBtnFooter: true,
    // recorder: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 23,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.16 src='Audios/2.16 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen. Who does 
        not like being in small places? Who is 
        happy about being in high places?`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 23px; margin-top: 0px; line-height: 48px'>
        <div><div><b>1</b>&ensp;Who does not like being in small places?</div>
        <textarea id='0' rows='2'></div>
        <div><div><b>2</b>&ensp;Who is happy about being in high places?</div>
        <textarea id='1' rows='2'></div>
        
        
      </div>
        
        `,
        answer: [
          "Shri doesn't like being in small places.",
          "Amelia doesn't have a problem with heights.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P79-E3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/2.16.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    // recorder: true,
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.16 src='Audios/2.16 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen again. Choose the correct options.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "snakes / spiders",
          "hospitals / the_dentist", //2
          "high_places / small_spaces",
          "Swimming / Dancing",
        ],
        answers: ["0-4", "1-0", "2-4", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 50px; font-size: 23px'>
       <div style='display: flex; width: 25cm; line-height: 50px; '>
         <div>
           <div style=""><b>1</b> &ensp; Amelia is scared of <input id='0' type='Circle' />.</div>
    
           <div style=""><b>2</b> &ensp; Harry gets nervous about <input id='1' type='Circle' />.</div>

           <div style=""><b>3</b> &ensp; Shri finds <input id='2' type='Circle' />
           scary.</div>

           <div style=""><b>4</b> &ensp; <input id='3' type='Circle' /> makes Lilly happy.</div>

         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P79-E5",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    // maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/7.jpg'/>
        Work in pairs. Ask and answer questions about how you feel 
        in certain situations using the key phrases.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 150px' >
        <img style="width:13.5cm; height: 8cm; margin-left: 50px" src='img/FriendsPlus/Page79/E5/1.jpg' />
        <img style="width:15cm; height: 5.5cm; margin-left: 50px" src='img/FriendsPlus/Page79/E5/2.jpg' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P79-E6",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    // recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Write about a time you were scared of, or nervous about something. What happened?`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],

    questions: [
      {
        title: `
        <textarea id='0' rows='10'>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
