import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  12: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P49-E12",
    exerciseKey: "img/FriendsPlus/Page49/Key/E12answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "12",
        title: `Choose the correct options.`,
        color: "#4F3C94",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "can / could / will_be_able_to",
          "will_be_able_to / could / couldn't",
          "have_to / should / mustn't",
          "don't_have_to / mustn't / must",
          "shouldn't / should / mustn't",
        ],
        answers: ["0-4", "1-0", "2-8", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; width:25cm; font-size: 23px'>
        <b>1</b>&ensp;When my dad was younger, he <input id='0' type='Circle'/> play the violin really well.<br>
        <b>2</b>&ensp;If we sit at the front tomorrow, we <input id='1' type='Circle'/> hear the singer better.<br>
        <b>3</b>&ensp;You <input id='2' type='Circle'/> listen to 
        music on your bike because you can’t hear the cars.<br>
        <b>4</b>&ensp;We <input id='3' type='Circle'/> perform 
        in the concert. We can choose to watch instead.<br>
        <b>5</b>&ensp;It’s good to listen to classical music when 
        you study. You <input id='4' type='Circle'/> try it.<br>
      </div>
      `,
    },
  },
  13: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P49-E13",
    exerciseKey: "img/FriendsPlus/Page49/Key/E13answerKey.png",
    component: T6,
    // audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "To achieve",
          "as well as",
          "much consideration",
          "positive impact",
          "great potential",
          "competitive",
          "I'm confident that",
          "stay in touch",
        ],
        width: 880,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "13",
        title: `Correct the words in bold. Then practise with your partner.`,
        color: "#4F3C94",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; height: 650px'>
          <div style=' position: absolute; top: 15px; left: 14px; width: 27cm; line-height: 45px; font-size: 21px'>
          
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Adele</div>
            <div style='width: 21.2cm'>What’s the matter? You look stressed.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Erin</div>
            <div style='width: 21.2cm'>I am! I found out that I failed the history test.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Adele</div>
            <div style='width: 21.2cm'><sup><b>1</b></sup><b>I</b> really sorry to hear that. #</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Erin</div>
            <div style='width: 21.2cm'>I have to do the test again on Friday.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Adele</div>
            <div style='width: 21.2cm'>Oh no! You <sup><b>2</b></sup><b>might</b> be feeling really fed up! #</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Erin</div>
            <div style='width: 21.2cm'>Yes, I don’t want to do it again.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Adele</div>
            <div style='width: 21.2cm'>I <sup><b>3</b></sup><b>must</b> imagine. #<br>
            But listen, <sup><b>4</b></sup><b>not</b> worry! I can help you to revise. #</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Erin</div>
            <div style='width: 21.2cm'>Thanks, Alison. That’s really kind of you.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Adele</div>
            <div style='width: 21.2cm'>Yes, cheer <sup><b>5</b></sup><b>down</b>! #<br>
            And <sup><b>6</b></sup><b>watch</b> on the bright side. #<br>
            It’s on Friday, so your weekend is still 
            free. It’s not the end of the <sup><b>7</b></sup><b>life</b>! #
            </div>
            </div>
            <div style='display: flex'>
              <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Erin</div>
              <div style='width: 21.2cm'>Yes, you’re right, Adele.</div>
            </div>
            <div style='display: flex'>
              <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Adele</div>
              <div style='width: 21.2cm'>You just <sup><b>8</b></sup><b>has</b> to think positively! #</div>
            </div>
          </div>

          
          
        </div>
          
        </div><br>
        `,
        answer: ["I'm", "must", "can", "don't", "up", "look", "world", "have"],
      },
    ],
  },
  14: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P49-E14",
    exerciseKey: "img/FriendsPlus/Page49/Key/E14answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "14",
        title: `Choose the correct words.`,
        color: "#4F3C94",
        left: -30,
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-0", "2-8", "3-8", "4-0", "5-8", "6-4", "7-0"],
        initialValue: [],
      },
      Layout: `

      <div style='width:25cm; font-size: 22px'>
        <sup><b>1</b></sup>..................... Quynh Mi,<br>
        This is an annoying situation for you, isn’t it? <sup><b>2</b></sup>.....................
        have a think. For a <sup><b>3</b></sup>..................... , you should talk about this 
        with your friend. Explain that you like talking to her 
        outside lessons but you need to study in class. <br>
        What <sup><b>4</b></sup>..................... reckon? <br>
        What <sup><b>5</b></sup>..................... ? You could speak to another person 
        about the situation. I <sup><b>6</b></sup>..................... your teacher will listen 
        and understand. Perhaps your teacher could put 
        you next to another student sometimes?<br>
        And one <sup><b>7</b></sup>..................... thing, you were right to ask for help.
        It’s always important to share problems, IMO.<br>
        <sup><b>8</b></sup>..................... , good luck and I hope that everything works 
        out for you at school.<br>
        Phuong Trúc
          
      </div>
      <div style='position:relative; line-height:43.4px; font-size: 22px'>
        
        <div style='position:relative'>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='5' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='6' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='7' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;'>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
         
        </div>
        
        <div style='position:absolute;top:0px;left:65px'>
          <div>Here</div>
          <div>Let’s</div>
          <div>starting</div>
          <div>are you</div>
          <div>else</div>
          <div>worry</div>
          <div>another</div>
          <div>Anyway</div>
        </div>
        <div style='position:absolute;top:0px;left:320px'>
          <div>Hey</div>
          <div>Let us</div>
          <div>starts</div>
          <div>did you</div>
          <div>other</div>
          <div>sure</div>
          <div>more</div>
          <div>Anything</div>
        </div>
        <div style='position:absolute;top:0px;left:580px'>
          <div>How</div>
          <div>Let</div>
          <div>start</div>
          <div>do you</div>
          <div>also</div>
          <div>bet</div>
          <div>lastly</div>
          <div>Anytime</div>
        </div>
      </div>

      `,
    },
  },
};

export default json;
