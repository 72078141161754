import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Culture",
    id: "SB9-2024-Cul-P97-E1",
    exerciseKey: "img/FriendsPlus/Page97/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/3.02.mp3",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 500,
      paddingTop: 5,
      // fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Look at the photos. Which period of history do 
        you think these constructions and objects are 
        from? Read the article and check your ideas.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px'>
          <img src="img/FriendsPlus/Page97/E1/1.jpg" style='height:21cm; margin-top: 50px'/>
          <div style="margin-top: 20px">
          <b> Which period of history do you think these constructions and objects are from?</b><br>
          <textarea id='0' rows='2'><br>

        </div>
        </div> 
      
        
        `,
        answer: ["They are from a period around 5,000 years ago."],
      },
    ],
  },
  2: {
    unit: "Culture",
    id: "SB9-2024-Cul-P97-E2",
    exerciseKey: "img/FriendsPlus/Page97/Key/E2answerKey.png",
    audio: "Audios/3.08.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 170,
      paddingTop: 10,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "abandoned",
          "approached",
          "carved",
          "exposed",
          "hunted",
          "inhabited",
          "passageways",
          "severe",
        ],
        width: 850,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=3.08 src='Audios/3.08 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Check the meaning of the words in 
        the box and complete the article. Then listen and check.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; font-size: 21px; line-height: 35px'>
        <div style='height: 750px'>
        <img style="width:26.5cm; height: 14cm" src='img/FriendsPlus/Page97/E2/1.jpg' />
        
        <div style='position: absolute; top: 152px; left: -6px; width: 19cm'>
          Winter in the northern isles of Scotland is a stormy 
          season. Every year, heavy rain and strong winds 
          hit the coast of the Orkney Islands. In 
          the winter of 1850, there was a 
          very <sup><b>1</b></sup># storm on 
          Mainland, the largest of the 
          Orkneys. It caused terrible 
          damage to the island, but 
          it also revealed an amazing 
          archaeological treasure. 
          When the storm cleared, the 
          outline of an ancient village 
          was <sup><b>2</b></sup>#. Since then, 
          the site has been excavated and 
          explored by archaeologists and 
          they have made some remarkable 
          discoveries.<br>

          The ancient village of Skara Brae was built more 
          than 5,000 years ago and was probably <sup><b>3</b></sup>#
          between around 3200 BC and 2200 BC. That makes 
          it older than the Great Pyramid at Giza. It’s easy to 
          see why this period of history is called the Stone 
          Age – the houses of Skara Brae and everything in 
          them are made from stone.<br>

          The village is so well preserved that it has changed 
          little in 5,000 years. The eight stone houses are 
          connected by low <sup><b>4</b></sup># with stone doors. 
          They all have a similar layout: one main room with 
          a central fireplace and stone beds on both sides.
        </div>
        </div>

        <div>
        
        <img style="width:24cm; height: 11cm" src='img/FriendsPlus/Page97/E2/2.jpg' />

        <div style='width: 26cm'>
          Opposite the entrance is a large 
          stone dresser, a kind of cupboard. 
          It sounds basic to today’s visitor, 
          but in the Stone Age these were 
          warm and comfortable houses. <br>
          
          The archaeologists’ investigations 
          have given us lots of information 
          about the people who lived at 
          Skara Brae and what life was like 
          for them. They were farmers who 
          kept sheep and cattle. They also ate 
          fish, and <sup><b>5</b></sup># deer. Every part 
          of the animal was used, including the 
          bones. The archaeologists found jewellery, hair pins, 
          needles, bowls and cups, all <sup><b>6</b></sup># from 
          animal bones and stone. They also found stone pots 
          that contained red colouring material and some 
          mysterious carved stone balls. <br>

          Around 2200 BC, Skara Brae was <sup><b>7</b></sup># and 
          slowly covered by the earth and sands. We don’t 
          know why these people left their small community. 
          It is possible that farming became more difficult 
          because of changes to the environment. We also 
          know that, as the Bronze Age <sup><b>8</b></sup>#, society 
          began to change and small farming communities 
          broke up. Skara Brae was left to the winds.
        </div>
        </div>

        </div>
        
        `,
        answer: [
          "severe",
          "exposed",
          "inhabited",
          "passageways",
          "hunted",
          "carved",
          "abandoned",
          "approached",
        ],
      },
    ],
  },
  3: {
    unit: "Culture",
    id: "SB9-2024-Cul-P97-E3",
    exerciseKey: "img/FriendsPlus/Page97/Key/E3answerKey.png",
    // audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 880,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Read the article again. Answer the questions.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    // character: ",",
    questions: [
      {
        title: `
        <img style="width:27cm; height:21cm; margin-left: -20px" src='img/FriendsPlus/Page97/E1/1.jpg' />

        <div style='font-size: 22px; line-height: 30px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;What is Mainland?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;When and how was the ancient village of 
          Skara Brae uncovered?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;How many houses were found?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;What did the houses look like inside?</div>
          <div><textarea id='3' rows='2'></div>

          <div style='font-weight: 600'><b>5</b>&ensp;What two possible reasons does the author 
          give for the people of Skara Brae leaving their village?</div>
          <div><textarea id='4' rows='2'></div>

        </div>
       
        `,
        answer: [
          "The largest of the Orkney Islands",
          "In a storm in 1850",
          "Eight",
          "They have one main room, with a fireplace in the middle and stone beds on both sides.",
          "Changes to the climate or changes to society",
        ],
      },
    ],
  },
  4: {
    unit: "Culture",
    id: "SB9-2024-Cul-P97-E4",
    exerciseKey: "img/FriendsPlus/Page97/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix text='YOUR CULTURE'></prefix>
        Work in pairs. Think of an important archaeological site in Việt Nam. 
        Ask and answer the questions.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;What period of history is it from?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;What was discovered there?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;What does it tell us about the people who 
          lived in Việt Nam at that time? What does it tell us about their way of life?</div>
          <div><textarea id='2' rows='2'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Culture",
    id: "SB9-2024-Cul-P97-E5",
    // exerciseKey: "img/FriendsPlus/Page92/Key/E5answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "carved stone balls",
          "dresser",
          "needles",
          "red colouring material",
          "stone boxes",
        ],
        width: 650,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<prefix text='USE IT!'></prefix>
        Work in pairs. Speculate about the use of the following objects in the 
        community of Skara Brae. Use the photos and the text to help you.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img style="width:26.5cm; height: 21cm; margin-top: 20px" src='img/FriendsPlus/Page97/E1/1.jpg' />
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px; margin-left: 50px'>
          <div><textarea id='0' rows='8'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
