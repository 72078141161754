import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  7: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P84-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page84/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P84-E1",
    exerciseKey: "img/FriendsPlus/Page84/Key/E1answerKey.png",
    audio: "Audios/2.22.mp3",
    component: T6,
    // audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 21,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.22 src='Audios/2.22 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Complete the dialogue with the 
        correct words. Then watch or listen and check. What happened to Chloe? What advice does Joe give her?`,
        color: "#DC1E74",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px'>
        <img style="width:28cm; height:20cm" src='img/FriendsPlus/Page84/E1/1.jpg' />
          <div style=' position: absolute; top: 3px; left: 25px; width: 18cm; line-height: 40px'>
          
          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Hey, Chloe! What’s wrong? Are you OK?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>No. I’ve hurt my arm.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>How did you do it?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>I fell <sup><b>1</b></sup># I was skateboarding. I was 
            going too fast and I <sup><b>2</b></sup># stop. It’s really painful.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Let me see. Oh dear! You’ve got a <sup><b>3</b></sup>#
            on your arm.</div>
          </div>
          
          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>Ouch! Don’t touch. It really hurts.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>And your elbow’s very red.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>Really?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Can you move it?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>No, I can’t move it at all. It’s too painful. 
            Do you think I’ve <sup><b>4</b></sup># it?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>I don’t know, but it <sup><b>5</b></sup># look good. 
            I think you should see a doctor.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>I’ll call Mum and she can take me.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Come on. <sup><b>6</b></sup># go over there and 
            wait for her.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 2.2cm; font-weight: 600; margin-right: 10px'>Chloe</div>
            <div style='width: 22.2cm'>Thanks, Joe.</div>
          </div>

          </div>
        </div>

        <div style='font-size: 21px'>
          <b>What happened to Chloe?</b><br>
          <div><textarea id='6' rows='2'></div>
        </div>
        <div style='font-size: 21px'>
          <b>What advice does Joe give her?</b><br>
          <div><textarea id='7' rows='2'></div>
        </div>
          
        </div>

        `,
        answer: [
          "while",
          "couldn't",
          "bruise",
          "broken",
          "doesn't",
          "Let's",
          "Chloe fell while she was skateboarding.",
          "Joe thinks she should go to the doctor.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P84-E2",
    exerciseKey: "img/FriendsPlus/Page84/Key/E2answerKey.png",
    audio: "Audios/2.22.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.22 src='Audios/2.22 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Cover the dialogue and choose the 
        correct words in the Key Phrases. Watch or listen 
        again and check. Which phrases are for describing 
        an accident and which are for responding? Write 
        <i>A</i> (accident) or <i>R</i> (response).`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          width: 35,
          background: "none",
          fontSize: 21,
          height: 27,
          textTransform: "uppercase",
          textAlign: "center",
          paddingTop: 5,
          fontWeight: 600,
          maxLength: 1,
        },
        answers: ["R", "A", "A", "R", "A", "R", "A", "R"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "wrong / the_problem",
          "OK / fine", //2
          "the / my",
          "really / amazing",
          "Let / Let's",
          "hurts / painful",
          "can / can't",
          "should / will",
        ],
        answers: ["2-4", "3-0", "0-0", "1-0", "4-0", "5-0", "6-4", "7-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 100px'>
      <img style="width:18cm; height: 15cm; " src='img/FriendsPlus/Page84/E2/1.jpg' />
       
       <div style='position: absolute; top: 120px; left: 46px; width: 17cm; line-height: 44.5px; font-size: 23px'>
           <div style=""><b>1</b> &ensp;What’s <input id='0' type='Circle' />? </div>
    
           <div style=""><b>2</b> &ensp;Are you <input id='1' type='Circle' />?</div>

           <div style=""><b>3</b> &ensp;I’ve hurt <input id='2' type='Circle' /> arm.</div>

           <div style=""><b>4</b> &ensp;It’s <input id='3' type='Circle' /> painful.</div>

           <div style=""><b>5</b> &ensp;<input id='4' type='Circle' /> me see.</div>

           <div style=""><b>6</b> &ensp;It really <input id='5' type='Circle' />.</div>

           <div style=""><b>7</b> &ensp;Can you move it?</div>

           <div style=""><b>8</b> &ensp;I <input id='6' type='Circle' /> move it at all.</div>

           <div style=""><b>9</b> &ensp;I think you <input id='7' type='Circle' /> see a doctor.</div>
       </div>
    
       <div style='position: absolute; top: 158px; left: 551px'><input id='0'/></div>
       <div style='position: absolute; top: 203px; left: 551px'><input id='1'/></div>
       <div style='position: absolute; top: 248px; left: 551px'><input id='2'/></div>
       <div style='position: absolute; top: 292px; left: 551px'><input id='3'/></div>

       <div style='position: absolute; top: 338px; left: 551px'><input id='4'/></div>
       <div style='position: absolute; top: 383px; left: 551px'><input id='5'/></div>
       <div style='position: absolute; top: 427px; left: 551px'><input id='6'/></div>
       <div style='position: absolute; top: 472px; left: 551px'><input id='7'/></div>
       </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P84-E3",
    // exerciseKey: "img/FriendsPlus/Page84/Key/E1answerKey.png",
    // audio: "Audios/2.22.mp3",
    component: T6,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 21,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: ` Work in pairs. Practise the dialogue.`,
        color: "#DC1E74",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-left: -80px'>
        <img style="width:29cm; height:19.5cm" src='img/FriendsPlus/Page84/E3/1.png' />
          
        </div>

        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P84-E4",
    // exerciseKey: "img/FriendsPlus/Page84/Key/E1answerKey.png",
    // audio: "Audios/2.22.mp3",
    component: T6,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 21,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. Ask and answer questions using the key phrases.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-left: 130px'>
        <img style="width:20cm; height:13cm" src='img/FriendsPlus/Page84/E4/2.png' />
        <img style="width:17cm; height:10cm" src='img/FriendsPlus/Page84/E4/1.jpg' />
          
        </div>

        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P84-E5",
    exerciseKey: "img/FriendsPlus/Page84/Key/E5answerKey.png",
    audio: "Audios/2.23.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.23 src='Audios/2.23 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        <b style='color: #DC1E74;'>PRONUNCIATION: Consonant clusters </b>
        Underline the consonant cluster(s) in these words. Then listen and check. `,
        color: "#DC1E74",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          width: 35,
          background: "none",
          fontSize: 21,
          height: 27,
          textTransform: "uppercase",
          textAlign: "center",
          paddingTop: 5,
          fontWeight: 600,
          maxLength: 1,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        limitSelect: 10,
        listWords: [
          "s p r a i n",
          "f r u i t",
          "s n a c k",
          "c a m p",
          "u n p l e a s a n t",
          "c o a s t e r",
          "r i s k s",
          "h o s p i t a l",
          "s p e n d",
          "j u m p",
          "f a c t o r",
          "t h r i l l e d", //2
        ],
        answers: [
          "0-0",
          "0-2",
          "1-0",
          "1-2",
          "2-0",
          "2-2",
          "3-4",
          "3-6",
          "4-4",
          "4-6",
          "5-6",
          "5-8",
          "6-4",
          "6-6",
          "6-8",
          "7-4",
          "7-6",
          "8-0",
          "8-2",
          "9-4",
          "9-6",
          "10-4",
          "10-6",
          "11-0",
          "11-2",
          "11-4",
          "11-10",
          "11-12",
          "11-14",
        ],
        initialValue: [],
      },
      Layout: `
      <div style=' margin-left: 200px; border: 2px solid rgb(220,30,116); border-radius: 20px; padding: 20px'>
           <div style=""><input id='0' type='Circle' /> </div>
    
           <div style=""><input id='1' type='Circle' /></div>
           <div style=""><input id='2' type='Circle' /></div>
           <div style=""><input id='3' type='Circle' /></div>
           <div style=""><input id='4' type='Circle' /></div>
           <div style=""><input id='5' type='Circle' /></div>
           <div style=""><input id='6' type='Circle' /></div>
           <div style=""><input id='7' type='Circle' /></div>
           <div style=""><input id='8' type='Circle' /></div>
           <div style=""><input id='9' type='Circle' /></div>
           <div style=""><input id='10' type='Circle' /></div>
           <div style=""><input id='11' type='Circle' /></div>
       </div>
      
      `,
    },
  },
  6: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E6",
    // exerciseKey: "img/FriendsPlus/Page81/Key/E3answerKey.png",
    component: T6,
    // maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Work in pairs. Prepare a dialogue 
        for the following situation. Use the key phrases 
        and the dialogue in exercise 1 to help you. Then change roles.`,
        color: "#DC1E74",
        left: 50,
        width: "28cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 50px' >
          <img style="width:16.5cm; height: 7cm; " src='img/FriendsPlus/Page84/E6/1.jpg' />
          <img style="width:19.5cm; height: 13cm; " src='img/FriendsPlus/Page84/E6/2.png' />
          <img style="width:28.5cm; height: 20cm; margin-left: -50px " src='img/FriendsPlus/Page84/E6/3.png' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
