import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P87-E5",
    exerciseKey: "img/FriendsPlus/Page87/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 850,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write sentences in the past, present or future passive.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 45px; width: 28.5cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br><br>
          <b>6</b>&ensp;<br><br>
          <b>7</b>&ensp;<br><br>
          <b>8</b>&ensp;<br><br>
          
        </div>

        <div style=''>
         <div>the coins / keep / in a jug until last year</div>
         <div>#</div>

         <div>the old painting / display / next July</div>
         <div>#</div>

         <div>ancient things / discover / every year</div>
         <div>#</div>

         <div>the museum / not clean / on Sundays</div>
         <div>#</div>

         <div>when / the Roman coins / make / ?</div>
         <div>#</div>

         <div>the treasure / sell / very soon / ?</div>
         <div>#</div>

         <div>the exhibit / not open / until next spring</div>
         <div>#</div>

         <div>the king's body / not find / in 2011</div>
         <div>#</div>
         
        </div>
        </div>
        
        `,
        answer: [
          "The coins were kept in a jug until last year.",
          "The old painting will be displayed next July.",
          "Ancient things are discovered every year.",
          "The museum isn't cleaned on Sundays.",
          "When were the Roman coins made?",
          "Will the treasure be sold very soon?",
          "The exhibit won't be opened until next spring.",
          "The king's body wasn't found in 2011.",
        ],
      },
    ],
  },
  6: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P87-E6",
    exerciseKey: "img/FriendsPlus/Page87/Key/E6answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 250,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "By contrast",
          "could be",
          "the first picture",
          "I prefer photo",
          "it looks like",
          "It's hard to",
          "maybe it's",
          "not sure",
          "two pictures show",
        ],
        width: 900,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Jake is describing the two photos. Complete the 
        description with the phrases. There is one extra phrase.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative; margin-top: 20px; margin-left: 50px'>
          <img style="width:25cm; height:17cm" src='img/FriendsPlus/Page87/E6/1.jpg' />

          <img style="width:19cm; height:15cm; margin-left: 100px" src='img/FriendsPlus/Page87/E6/2.jpg' />
          
          <div style='position: absolute; top: 10px; left: 37px; font-size: 21px; line-height: 50px; width: 22.5cm'>
          These <sup><b>1</b></sup># the same street, but in different years. This one is an old photo of the street a long time ago. Perhaps it was taken in the 1920s.<br>
          In <sup><b>2</b></sup># there are lots of carriages
          with horses. <sup><b>3</b></sup># see, but I don't think there are any cars at all. There's a big building in the distance - <sup><b>4</b></sup># a cathedral, 
          but I'm <sup><b>5</b></sup>#. There's something in the middle of the street - this could be a bridge because it goes high across the road.<br>
          <sup><b>6</b></sup># , in this picture, there's only a small number of vehicles and I think there might only be one or two people - <sup><b>7</b></sup># early in the
          morning. The buildings have all been renovated and they look like offices with shops under them. 
          It seems like the bridge has been destroyed.<br>
          <sup><b>8</b></sup>#. A because it's busy, lively and more interesting to look at.

          </div>
        </div>
        
        `,
        answer: [
          "two pictures show",
          "the first picture",
          "It's hard to",
          "it looks like",
          "not sure",
          "By contrast",
          "maybe it's",
          "I prefer photo",
        ],
      },
    ],
  },
  7: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P87-E7",
    exerciseKey: "img/FriendsPlus/Page87/Key/E7answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Choose the correct options.`,
        color: "#4F3C94",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "ones / one / some",
          "is_located / located / locates",
          "were_built / was_build / was_built",
          "was_destroyed / were_destroyed / were_destroy",
          "to / up / at",
          "upside / onside / inside",
          "there / that / then",
          "to_visit / a_visit / for_visit",
        ],
        answers: ["0-4", "1-0", "2-8", "3-4", "4-4", "5-8", "6-0", "7-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; line-height:50px; width:24cm; font-size: 21px; margin-left: 100px'>

      <img style="width:21cm; height:24cm" src='img/FriendsPlus/Page87/E7/1.jpg' />
      <div style='position: absolute; top: 83px; left: 50px; width: 18cm'>
        The Royal Exhibition Building is <sup><b>1</b></sup><input id='0' type='Circle'/>
        of the most famous 19th century buildings in Australia. 
        It <sup><b>2</b></sup><input id='1' type='Circle'/> in the centre of 
        Melbourne. It <sup><b>3</b></sup><input id='2' type='Circle'/>
        in 1880 as a place for large exhibitions and it is still a 
        working exhibition centre today. Parts of the building 
        <sup><b>4</b></sup><input id='3' type='Circle'/>
        by fire in the 20<sup>th</sup> century, but the main building with 
        its impressive roof is still standing. If you look <sup><b>5</b></sup><input id='4' type='Circle'/>,
        you can see the large dome with a flag on
        the top. If you go <sup><b>6</b></sup><input id='5' type='Circle'/> the 
        building, you'll find one of the lastest exibitions 
        <sup><b>7</b></sup><input id='6' type='Circle'/>. Outside you can see beautiful 
        gardens. The place is well worth <sup><b>8</b></sup><input id='7' type='Circle'/>.
      </div>
      
      </div>
      `,
    },
  },
};

export default json;
