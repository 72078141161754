import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P75-E1",
    exerciseKey: "img/FriendsPlus/Page75/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 270, paddingTop: 10, fontSize: 22 },
    hintBox: [
      {
        src: [
          "are discovered",
          "hasn't been removed",
          "might be hidden",
          "was examined",
          "was killed",
          "will be displayed",
        ],
        width: 800,
        borderColor: "#5cc9dd",
        marginLeft: 50,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the passive sentences with the words 
          in the box. Then answer questions 1–5 below.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height: 40px; font-size: 22px'>
          <b>a</b>&ensp;Many ancient things#accidentally by ordinary people.<br>
          <b>b</b>&ensp;The skeleton#by experts.<br>
          <b>c</b>&ensp;They hope it#in a museum one day.<br>
          <b>d</b>&ensp;Richard III#by a soldier.<br>
          <b>e</b>&ensp;It’s amazing to think of the treasures that#under your feet.<br>
          <b>f</b>&ensp;The ship#yet.
          <div style='margin-top:20px'>
            <b>1</b>&ensp;Which sentence is in the present simple?<br><input id='6' width='800px'></input><br>
            <b>2</b>&ensp;Which two sentences are in the past simple?<br><input id='7' width='800px'></input><br>
            <b>3</b>&ensp;Which sentence is in the present perfect?<br><input id='8' width='800px'></input><br>
            <b>4</b>&ensp;How are future and modal passives formed?<br><input id='9' width='800px'></input><br>
            <b>5</b>&ensp;When do we use by?<br><input id='10' width='800px'></input>
          </div>
        </div>
      
        
        `,
        answer: [
          "are discovered",
          "was examined",
          "will be displayed",
          "was killed",
          "might be hidden",
          "hasn't been removed",
          "a",
          "b, d|d, b",
          "f",
          "with will or modal  verb + be + past participle",
          "We use by to say who did the action.",
        ],
      },
    ],
  },

  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P75-E2",
    exerciseKey: "img/FriendsPlus/Page75/Key/E2answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    // maxLength:5,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 350,
      paddingTop: 5,
      // fontWeight: 600,
    },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the verbs in 
          brackets.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm;line-height:50px'>
          <b>1</b>&ensp;Locals believe that treasure#near here. But no one has found it yet. (bury)<br>
          <b>2</b>&ensp;The Lascaux cave drawings#by children who fell into a hole. (find)<br>
          <b>3</b>&ensp;DNA tests#out by Dr Stevens next month. (carry)<br>
          <b>4</b>&ensp;The ship#because it is under a pub. (can / not remove)<br>
          <b>5</b>&ensp;The site#yet. (not excavate)<br>
          <b>6</b>&ensp;The treasure#until next year. (not display)<br>
          <b>7</b>&ensp;Ancient treasure#under your house. (may / hide)<br>
          <b>8</b>&ensp;Viking ships like this#in England 1,000 years ago.<br> (not build)<br>
        </div>
        
      
        
        `,
        answer: [
          "is buried",
          "were found",
          "will be carried",
          "can't be removed",
          "hasn't been excavated",
          "won't be displayed",
          "may be hidden",
          "hadn't been built|weren't been built | weren't / hadn't been built",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P75-E3",
    audio: "Audios/2.13.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page75/Key/E3answerKey.png",
    component: T6,
    inputSize: 500,
    stylesTextInput: { width: 250, paddingTop: 10 },
    textAlign: "center",
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    giaiThua: true,
    character: ",",
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.13 src='Audios/2.13 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
         <span style='color: #5cc9dd'>PRONUNCIATION: Sentence stress</span>  Listen to the sentences. Which auxiliary verbs are 
          weak? Which are strong? Listen again and repeat.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
        // prefix:{text:'PRONUNCIATION: Sentence stress'}
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:46px'> 
            <b>1</b>&ensp;The remains <b>were</b> discovered in 2016.<br>
            <b>2</b>&ensp;The ship <b>hasn’t been</b> moved yet.<br>
            <b>3</b>&ensp;Many treasures <b>aren’t</b> found by experts.<br>
            <b>4</b>&ensp;Nothing <b>has been</b> found at the site yet<br>
        </div>
        <b style='font-style:italic'>Answer:</b>
        <div>
          # are weak <br>
          # are strong 
        </div>
      
        
        `,
        answer: ["were, has, been", "hasn't, aren't"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P75-E4",
    exerciseKey: "img/FriendsPlus/Page75/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      width: 200,
      fontSize: 23,
      background: "none",
      borderBottom: "none",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "cover",
          "<s>discover</s>",
          "examine",
          "keep",
          "label make",
          "put",
          "take",
          "not touch",
          "unearth",
          "write",
        ],
        width: 850,
        borderColor: "#5cc9dd",
        marginLeft: 50,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete the newspaper article. Use past, present 
          and future passive forms of the verbs in the box.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; margin-left: 50px'>
	        <img src="img/FriendsPlus/Page75/E4/1.jpg" style='height:20cm'/>
          <div style=" position: absolute; top: 169px; left: 435px; ">#</div>   
          <div style=" position: absolute; top: 205px; left: 360px; ">#</div>   
          <div style=" position: absolute; top: 240px; left: 410px; ">#</div>   
          <div style=" position: absolute; top: 311px; left: 431px; ">#</div>   
          <div style=" position: absolute; top: 347px; left: 350px; ">#</div>   
          <div style=" position: absolute; top: 383px; left: 319px; ">#</div>   
          <div style=" position: absolute; top: 454px; left: 225px; ">#</div>   
          <div style=" position: absolute; top: 525px; left: 136px; ">#</div>   
          <div style=" position: absolute; top: 561px; left: 108px; ">#</div>   
          <div style=" position: absolute; top: 632px; left: 46px; ">#</div>   
        </div>
      
        
        `,
        answer: [
          "was unearthed",
          "was covered",
          "wasn't touched",
          "was taken",
          "was examined",
          "was made",
          "is kept",
          "will be put",
          "will be labelled",
          "will be written",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P75-E5",
    exerciseKey: "img/FriendsPlus/Page75/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      width: 60,
      fontSize: 23,
      background: "none",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "cover",
          "discover",
          "examine",
          "keep",
          "label make",
          "put",
          "take",
          "not touch",
          "unearth",
          "write",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 100,
      },
    ],
    giaiThua: true,
    character: ",",
    titleQuestion: [
      {
        num: "5",
        title: `Study the passive questions a–f 
          and answer questions 1 and 2.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 45px'>
          <b>a</b>&ensp;How was the ship located?<br>
          <b>b</b>&ensp;Was the skeleton found by builders?<br>
          <b>c</b>&ensp;Has the ship been removed yet?<br>
          <b>d</b>&ensp;When will the ship be restored?<br>
          <b>e</b>&ensp;Where is the treasure buried?<br>
          <b>f</b>&ensp;How can ancient objects be dated?  <br>
          <div style='line-height:46px;margin-top:20px'>
            <b>1</b>&ensp;Which questions are in the present simple, 
            past simple, present perfect and future?<br>
                Present simple: #, Past simple: #, 
                Present perfect: #, Future: # <br>
            <b>2</b>&ensp;Where do we put be in questions in future 
            and modal forms?<br><input id='4' width='800px' ></input>
          </div>
        </div>
      
        
        `,
        answer: [
          "e",
          "a, b",
          "c",
          "d",
          "We put be after the subject or before the past participle.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P75-E6",
    exerciseKey: "img/FriendsPlus/Page75/Key/E6answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 670, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<prefix></prefix> Write questions about the text in 
          exercise 4. Then ask and answer with a partner.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:20cm; height:17cm" src='img/FriendsPlus/Page75/E6/1.png' />
          <div style='margin-left:0px'>
            <b>1</b>&ensp;discovered / the / was / how / treasure / ?<br>#<br>
            <b>2</b>&ensp;taken / where / the / were / jug and coins / ?<br>#<br>
            <b>3</b>&ensp;the / made / coins / all / of / are / gold / ?<br>#<br>
            <b>4</b>&ensp;been / the / have / yet / dated / coins / ?<br>#<br>
            <b>5</b>&ensp;are / where / now / jug and coins / kept / the / ?<br>#<br>
            <b>6</b>&ensp;the / what / labelled / treasure / be / will / ?<br>#<br>
          </div>
          <div style='line-height:47px'>
            <b>Answer:</b><br>
            <b>1</b>&ensp;#.<br>
            <b>2</b>&ensp;#.<br>
            <b>3</b>&ensp;#.<br>
            <b>4</b>&ensp;#.<br>
            <b>5</b>&ensp;#.<br>
            <b>6</b>&ensp;#.<br>
          </div>
        
        `,
        answer: [
          "How was the treasure discovered?",
          "Where were the jug and coins taken?",
          "Are all the coins made of gold?",
          "Have the coins been dated yet?",
          "Where are the jug and coins kept now?",
          "What will the treasure be labelled?",
          "It was discovered by a builder during a house renovation",
          "They were taken to the British Museum",
          "No, they aren't. They're made of gold and silver",
          "Yes, they have",
          "They're kept at the British Museum",
          "It will be labelled 'The Mason Hoard'",
        ],
      },
    ],
  },
};

export default json;
