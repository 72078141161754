import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P86-E1",
    exerciseKey: "img/FriendsPlus/Page86/Key/E1answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Finally",
          "Furthermore",
          "suggests",
          "I believe",
          "in favour of",
          "In the first place",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the words in the online forum post.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; '>
          <img style="width:21cm; height:16cm" src='img/FriendsPlus/Page86/E1/1.jpg' />
          
          <div style='position: absolute; top: 92px; left: 50px; font-size: 21px; line-height: 50px; width: 17.5cm'>
          Our town is fun when you have free time because 
          there are lots of <sup><b>1</b></sup>l# facilities. The best 
          thing is the new <br><sup><b>2</b></sup>s# park – you’ll find 
          me there a lot! There are nice green spaces, too, 
          and they’ve opened the <sup><b>3</b></sup>a# ruins where 
          you can hang out in the summer. If you fancy 
          going to a concert with friends, there’s also a cool 
          <sup><b>4</b></sup>m# venue.<br>
          There’s one big problem with our town – it’s 
          quite polluted because there’s always <sup><b>5</b></sup>h#
          traffic and there’s air pollution, too, from the big 
          factory at the <sup><b>6</b></sup>i# site to the east.

          </div>
        </div>
        
        `,
        answer: ["eisure", "kate", "ncient", "usic", "eavy", "ndustrial"],
      },
    ],
  },
  2: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P86-E2",
    exerciseKey: "img/FriendsPlus/Page86/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 230,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct 
        comparative or superlative form of the words in brackets.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>This article is # than the entry in the online encyclopaedia. (long)</div>

         <div>This review describes # concert tickets that you can buy. (expensive)</div>

         <div>That blogger is one of # 
         travel writers on the internet. (talented)</div>

         <div>Which guide discusses # 
         places to go skateboarding in London? (good)</div>

         <div>Is this description of London # 
         than the one you read yesterday? (formal)</div>

        </div>
        </div>
        
        `,
        answer: [
          "longer",
          "the most expensive",
          "the most talented",
          "the best",
          "more formal",
        ],
      },
    ],
  },
  3: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P86-E3",
    exerciseKey: "img/FriendsPlus/Page86/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 800,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Rewrite the sentences.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 28.5cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br><br>
          
        </div>

        <div style=''>
         <div>The city is so interesting that it takes tourists 
         four hours to go sightseeing.</div>
         <div>It is such #.</div>

         <div>The room was so comfortable that the students 
         all could fall asleep easily.</div>
         <div>It is such #.</div>

         <div>The soup is so tasty that my English friend 
         wants to try more.</div>
         <div>It is such #.</div>

         <div>It was such good weather that our class had a 
         great time on our trip to Củ Chi Tunnels.</div>
         <div>The weather was #.</div>

         <div>They are such hospitable city dwellers that they 
         have made a good impression on all visitors.</div>
         <div>The city dwellers are #.</div>
         
        </div>
        </div>
        
        `,
        answer: [
          "an interesting city that it takes tourists four hours to go sightseeing",
          "a comfortable room that the students all could fall asleep easily",
          "a tasty soup that my English friend wants to try more",
          "so good that our class had a great time on our trip to Củ Chi Tunnels",
          "so hospitable that they have made a good impression on all visitors",
        ],
      },
    ],
  },
  4: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P86-E4",
    exerciseKey: "img/FriendsPlus/Page86/Key/E4answerKey.png",
    audio: "Audios/2.24.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Finally",
          "Furthermore",
          "suggests",
          "I believe",
          "in favour of",
          "In the first place",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.24 src='Audios/2.24 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to the conversation. Complete the online article with words from the conversation.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-left: 100px'>
          <img style="width:21cm; height:20cm" src='img/FriendsPlus/Page86/E4/1.jpg' />
          
          <div style='position: absolute; top: 120px; left: 50px; font-size: 21px; line-height: 50px; width: 17.5cm'>
          An ancient hoard was discovered last month near the 
          town of Louth in the east of the UK. The hoard of treasure 
          was <sup><b>1</b></sup># by a local metal detector enthusiast, 
          Alan Smith. It was <sup><b>2</b></sup># in the middle of a field 
          of <sup><b>3</b></sup># .<br>
          The hoard was <sup><b>4</b></sup># in the grave of an ancient 
          ruler around <sup><b>5</b></sup># years ago. The hoard consists 
          of a gold necklace, two large bowls and some ancient 
          weapons. These were <sup><b>6</b></sup># from the ground by 
          a group of archaeologists, and they were <sup><b>7</b></sup>#
          by experts at the British Museum. The necklace, in 
          particular, is well <sup><b>8</b></sup># and it is worth more than 
          £50,000.

          </div>
        </div>
        
        `,
        answer: [
          "unearthed",
          "located",
          "potatoes",
          "buried",
          "1500",
          "removed",
          "examined",
          "preserved",
        ],
      },
    ],
  },
};

export default json;
