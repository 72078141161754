import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P13-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Study these sentences from the text. Then 
            choose the correct options to complete the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "before / after",
          "was / had", //2
          "affirmative / negative", //3
          "infinitive / past_participle",
        ],
        answers: ["0-0", "1-4", "2-4", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
      <img style="width:14.5cm; height:6.5cm; margin-left: 100px" src='img/FriendsPlus/Page13/E1/1.jpg' />
       <img style="width:21.5cm; height:12cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
       <div style='display: flex; position: absolute; top: 341px; left: 38px; width: 19cm; line-height: 38px'>
         <div>
           <div style=""><b>1</b> &ensp;The past perfect describes a past action or 
           event that took place <input id='0' type='Circle' /> another 
           past action or event.
           </div>
    
           <div style=""><b>2</b> &ensp;We form the past perfect with <input id='1' type='Circle' /> + a 
           past participle.
           </div>
    
           <div style=""><b>3</b> &ensp;
           We use not or never to make <input id='2' type='Circle' /> sentences.
           </div>
    
           <div style=""><b>4</b> &ensp;
           The question form is auxiliary verb + subject 
+ <input id='3' type='Circle' />.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },

  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P13-E2",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct options.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "read / had_read",
          "turned_off / had_turned_off", //2
          "Did_Jane_learn / Had_Jane_learnt", //3
          "started / had_started",
          "didn't_have / hadn't_had",
          "were / had_been",
          "played / had_played",
          "visited / had_visited",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-4", "5-0", "6-4", "7-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm'>
       
         <div>
           <div style=""><b>1</b> &ensp;After Tom <input id='0' type='Circle' /> the email, he 
           <input id='1' type='Circle' /> his computer.
           </div>
    
           <div style=""><b>2</b> &ensp;<input id='2' type='Circle' /> to read 
           and write before she <input id='3' type='Circle' /> school?
           </div>
    
           <div style=""><b>3</b> &ensp;
           We <input id='4' type='Circle' /> a foreign 
           holiday until last summer.
           </div>
    
           <div style=""><b>4</b> &ensp;
           We <input id='5' type='Circle' /> tired because we <input id='6' type='Circle' /> football all afternoon.
           </div>

           <div style=""><b>5</b> &ensp;
            I <input id='7' type='Circle' /> five cities in southern 
            Việt Nam by 2001.</div>
         </div>
       
    
       </div>
      
      `,
    },
  },

  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P13-E3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 250,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the past perfect 
        form of the verbs in brackets.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>They # (never / play) 
         football until last week.
         </div>
         <div># (you / buy) a ticket 
         before you arrived at the station?
         </div>
         <div>I was sad because she # (forget) my birthday.
         </div>
         <div># (he / leave) the house 
         when you called?         
         </div>
         <div>The children # (not / do) 
         their homework, so they were in trouble.
         </div>
        </div>
        </div>
        
        `,
        answer: [
          "had never played",
          "Had you bought",
          "had forgotten",
          "Had he left",
          "hadn't done",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P13-E4",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix></prefix> Work in pairs. Take turns to say 
        what you think had happened before each situation.`,
        color: "#5cc9dd",
        left: 50,
        width: "25cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:16cm; height:8cm; margin-top: 20px; margin-left: 100px" src='img/FriendsPlus/Page13/E4/1.jpg' />
       
        `,
        answer: [""],
      },
    ],
  },

  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P13-E5",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Imagine you were late for school because you 
        had gone on foot instead of by bicycle. Write 
        sentences about what happened to you using 
        the past perfect.`,
        color: "#5cc9dd",
        left: 50,
        width: "25cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='8'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
