import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P21-E1",
    exerciseKey: "img/FriendsPlus/Page21/Key/E1answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      width: 200,
      // height: 20,
      paddingTop: 15,
      background: "none",
      borderBottom: "dotted 2px",
      fontSize: 21,
      fontWeight: 600,
    },
    selectStyle: {
      height: 35,
      width: 85,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["a", "b", "c"],
    titleQuestion: [
      {
        num: "1",
        title: `Study examples a-c. Then answer questions 1-3.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px; line-height: 50px'>
	        <div style='display: flex'>
            <div>
              <div><b>a</b>&ensp;</div>
              <div><b>b</b>&ensp;</div>
              <div><b>c</b>&ensp;</div>
            </div>
            <div>
              <div>Lauren has been blogging about her lifestyle <span style='color: rgb(54,166,215)'>for</span> the last few years.</div>
              <div><span style='color: rgb(54,166,215)'>Since</span> then, she hasn’t used any ‘disposable’ products.</div>
              <div>He hasn't used money <span style='color: rgb(54,166,215)'>since</span> 2008.</div>
            </div>
          </div>
          
          <div style='margin-top: 10px '>
              <div>
                <div><b>1&ensp;</b>Which sentence emphasises that an action is in progress and will probably continue?</div>
                <select id=2></select>
              </div>
              <div>
                <div><b>2&ensp;</b>Which word do we use with a period of time?</div>
                <input id=0/>
              </div>
              <div>
                <div><b>3&ensp;</b>Which word do we use to give a point in time in the past?</div>
                <input id=1/>
              </div>
             
          </div>
        </div> 
        `,
        answer: ["for", "since", "a"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P21-E2",
    exerciseKey: "img/FriendsPlus/Page21/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 300,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the verbs in brackets. Use the present perfect simple or continuous.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div># (it / rain) since last night?</div>
         <div># (He / write) three books and he is working on another one.</div>
         <div># (Nhi / not attend) the class for many days. She's ill.</div>
         <div>#  (you / see) my pen? I can't find it.</div>
         <div># (I / watch) a film but I’ve 
         paused it for a minute.</div>
        </div>
        </div>
        
        `,
        answer: [
          "Has it been raining",
          "He's written",
          "Nhi hasn't been attending",
          "Have you seen",
          "I've been watching",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P21-E3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 150,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text with <i>for</i> or <i>since</i>.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 22px; line-height: 50px; width: 22cm; margin-left: 30px'>
        As an experiment, I’m not using my phone today. 
        I haven’t looked at it <sup><b>1</b></sup>&ensp;# nine o’clock this 
        morning. I’ve had the phone <sup><b>2</b></sup>&ensp;# years and 
        I’ve carried it around everywhere with me <sup><b>3</b></sup>&ensp;# 
        the day I bought it. It feels weird not 
        to have received a text <sup><b>4</b></sup>&ensp;# one whole day.
        </div>
        `,
        answer: ["since", "for", "since", "for"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P21-E4",
    exerciseKey: "img/FriendsPlus/Page21/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 500,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix></prefix> Complete the sentences with information about yourself. 
        Use <i>for</i> or <i> since</i>. Then compare your answer with a partner's.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 50px; width: 26cm; color: gray'><i>I’ve been friends with Linh for three years.</i></div>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div># (be) friends with …</div>
         <div># (know) my teacher …</div>
         <div># (have) these shoes …</div>
         <div># (live) in my house / flat …</div>
         <div># (study) English …</div>
        </div>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P21-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    // textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 500,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Write your partner's answer to the questions in exercise 4.`,
        color: "#5cc9dd",
        left: 50,
        width: "25cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 50px; width: 26cm; color: gray'><i>I’ve been friends with Linh for three years.</i></div>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div># (be) friends with …</div>
         <div># (know) my teacher …</div>
         <div># (have) these shoes …</div>
         <div># (live) in my house / flat …</div>
         <div># (study) English …</div>
        </div>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
