import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P56-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page56/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P56-E1",
    exerciseKey: "img/FriendsPlus/Page56/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 156,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["in favour of", "against"],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task. Then read opinions 1–4.
        Which comments are in favour of space exploration? 
        Which are against?`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:14cm; height:6cm; margin-left: 100px" src='img/FriendsPlus/Page56/E1/1.jpg' />
        <div style='display: flex;  width: 25cm; line-height: 30px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;It would be better to spend money on other 
            things.&ensp; <select id=0></select></div>

            <div style="display: flex"><b>2</b> &ensp;We need to know what’s out there. &ensp;<select id=1></select></div> 

            <div style="display: flex"><b>3</b> &ensp;Scientists do useful experiments there. &ensp;<select id=2></select></div>

            <div style="display: flex"><b>4</b> &ensp;Earth has its own problems. We should fix them 
            first. &ensp; <select id=3></select></div>

          </div>
        </div>
        `,
        // InputRong: true,
        answer: ["against", "in favour of", "in favour of", "against"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P56-E2",
    exerciseKey: "img/FriendsPlus/Page56/Key/E2answerKey.png",
    audio: "Audios/1.36.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.36 src='Audios/1.36 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Watch or listen to Sam giving his 
        opinion about the topic. Choose the correct option.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c <br> d"],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
  <div style='font-size: 23px; margin-left: 50px; margin-top: 20px; height: 500px'>
   
   <div style='display: flex; width: 26cm; line-height: 45px'>
   <div style='width: 25cm; display: flex'>
      <div style='font-weight: 600; margin-left: 50px'><input id='0' type='Circle' /></div>  
      
      <div style='margin-left: 20px'>
        <div style="">He’s mostly in favour of space exploration.</div>
        <div style="">He’s completely in favour of space exploration.</div>
        <div style="">He’s mostly against space exploration.</div>
        <div style="">He’s completely against space exploration.</div>
       </div>
    </div>
        
    </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P56-E3",
    exerciseKey: "img/FriendsPlus/Page56/Key/E3answerKey.png",
    audio: "Audios/1.36.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.36 src='Audios/1.36 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        When we give opinions about something, it’s a good idea to vary the phrases 
        that we use to introduce those opinions. Listen or 
        watch again. Which key phrases does Sam use?`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 8,
        listWords: [
          "I_believe_/_don't_believe_(that)_… <br> In_my_opinion,_… <br> I_doubt_that_… <br> There's_no_doubt_(in_my_mind)_that_… <br> It's_true_/_It_isn't_true_that_… <br> I_can_/_can't_see_why_people_think_that_… <br> As_far_as_I_can_see,_… <br> If_/_When_you_think_about_it,_…",
        ],
        answers: ["0-0", "0-4", "0-8", "0-12", "0-24", "0-28"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; font-size: 22px; margin-left: 50px; margin-top: 20px; height: 500px'>
      <img style="width:19cm; height:12cm" src='img/FriendsPlus/Page56/E3/1.jpg' />
       <div style='position: absolute; width: 25cm; top: 112px; left: 28px; line-height: 38px'>
          <div style=''><input id='0' type='Circle' /></div>  

        </div>

      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U5-P56-E4",
    exerciseKey: "img/FriendsPlus/Page56/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.2cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Follow the steps in the Speaking Guide.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:15cm; height:19.5cm; margin-left: 170px" src='img/FriendsPlus/Page56/E4/1.jpg' />
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
