import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P78-E2",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page78/E2/1.jpg" }]],
  },
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P78-E2",
    exerciseKey: "img/FriendsPlus/Page78/Key/E1answerKey.png",
    component: T6,
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 100,
      paddingTop: 5,
      // fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "although",
          "became",
          "'ve become",
          "despite",
          "in spite",
          "started",
          "'ve started",
          "never",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "1",
        title: `Look at the title of the questionnaire. Do 
        the <i>Risk Factor</i> questionnaire. Then look at 
        the key. Do you agree?`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
          <img src="img/FriendsPlus/Page78/E1/1.jpg" style='height:29cm; width: 28cm'/><br>
          <div style='position: absolute; top: 796px; left: 539px'>
          <div><b>Answer:</b></div>
          <div style='display: flex'>
          <div style='margin-right: 50px'>
            <b>1</b> - # <br>
            <b>2</b> - # <br>
            <b>3</b> - # <br>
          </div>
          <div>
            <b>4</b> - # <br>
            <b>5</b> - # <br>
            <b>6</b> - # <br>
          </div>
          </div>
          </div>
        </div> 
      
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
