import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P18-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page18/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P18-E1",
    exerciseKey: "img/FriendsPlus/Page18/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      width: 230,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Check the meaning of the words and 
        phrases in <span style='color: #5cc9dd'>blue</span> in the questionnaire. 
        Match eight of the words and phrases with definitions 1–8.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 21px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page18/E1/1.jpg" style='height:22.5cm; width: 28cm '/>
          <div style='display: flex; justify-content: center'>
            <div>
              <div><b>1</b>&ensp;say yes to something<br>#</div>
              <div><b>2</b>&ensp;not make an effort to do something<br>#</div>
              <div><b>3</b>&ensp;postpone something<br>#</div>
              <div><b>4</b>&ensp;decide something too quickly<br>#</div>
            </div>
            <div style="margin-left: 70px">
              <div><b>5</b>&ensp;decide<br>#</div>
              <div><b>6</b>&ensp;not hurry<br>#</div>
              <div><b>7</b>&ensp;not change<br>#</div>
              <div><b>8</b>&ensp;choose not to participate<br>#</div>
            </div>
          
          </div>
           
        </div> 
        `,
        answer: [
          "commit",
          "don't bother",
          "put off",
          "rush into",
          "make up my mind",
          "take my time",
          "stick with",
          "drop out",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P18-E2",
    exerciseKey: "img/FriendsPlus/Page18/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      width: 60,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `<img style='height:1.1cm; width: 1.3cm; margin-bottom: 5px' src='img/FriendsPlus/icon/11.jpg'/> 
        Do the questionnaire with a partner. Then check your answers with the key.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page18/E1/1.jpg" style='height:22.5cm; width: 28cm '/>
        <img src="img/FriendsPlus/Page18/E1/2.jpg" style='height:14.5cm; width: 16cm; margin-left: 478px; '/>
        
        `,
        answer: [
          "commit",
          "don't bother",
          "put off",
          "rush into",
          "make up my mind",
          "take my time",
          "stick with",
          "drop out",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P18-E3",
    audio: "Audios/1.10.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.10 src='Audios/1.10 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Watch or listen to three people discussing their results from the questionnaire. Match 1–3 with a–c.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "144px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "53px",
              left: "144px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "85px",
              left: "144px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "228px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "53px",
              left: "228px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "85px",
              left: "228px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: ["0-5", "1-3", "2-4"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width: 23cm; height: 11cm" src='img/FriendsPlus/Page18/E3/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />

        <input id='5' type= 'boxMatch' />
        
				
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P18-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 3.6cm; margin-bottom: 5px' src='img/FriendsPlus/icon/7.jpg'/> Work in groups. Talk about 
        your results from the questionnaire. Say 
        how decisive you are and give examples. 
        Use the key phrases.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
        <img src="img/FriendsPlus/Page18/E1/1.jpg" style='height:22.5cm; width: 28cm '/>
        <div style='display: flex'>
        <img src="img/FriendsPlus/Page18/E1/2.jpg" style='height:14.5cm; width:14cm '/>
        <img src="img/FriendsPlus/Page18/E4/1.jpg" style='height:8.5cm; width:14cm '/>
        </div>
        
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P18-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Write three tips to help people become more 
        decisive. Use words and phrases from the 
        questionnaire. Share your tips with the class. 
        Which are the best ones?`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='8'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
