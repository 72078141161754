import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P25-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 200,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page25/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P25-E1",
    exerciseKey: "img/FriendsPlus/Page25/Key/E1answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "left",
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    stylesTextInput: {
      width: 750,
      paddingTop: 15,
      background: "none",
      borderBottom: "solid 1.5px",
      fontSize: 21,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "1",
        title: `Read the survey results and the report and
        answer the questions.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    isHello: true,
    KeyMau: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
        <div style='margin-left: 100px'>
        <img src="img/FriendsPlus/Page25/E1/1.jpg" style='height:17cm; width: 19cm; '/>
        <img src="img/FriendsPlus/Page25/E1/2.jpg" style='height:17cm; width: 17cm; '/>
        </div>
          

          <div style=' margin-top: 10px; margin-left: 50px '>
              <div>
                <div><b>1&ensp;</b>There is one error in the report. What is it?</div>
                <textarea id='0' rows='2'>
              </div>
              <div>
                <div><b>2&ensp;</b>Do you agree or disagree with the majority of people in this survey?</div>
                <textarea id='1' rows='2'>
              </div>

          </div>
        </div>
        `,
        answer: ["", ""],
      },
    ],
  },

  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P25-E2",
    exerciseKey: "img/FriendsPlus/Page25/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Study the Key Phrases. Which of the phrases is not in the report?`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
          fontWeight: 600,
        },
        limitSelect: 6,
        listWords: [
          "Those_in_favour_said_that_… <br> Those_against_commented_that_… <br> People_who_said_'yes'_/_'no'_think_that_… <br> All_in_all,_the_results_indicate_that_… <br> Most_students_/_the_majority_of_students_… <br> An_equal_number_of_people_thought_that_…",
        ],
        answers: ["0-20"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 23px; line-height: 38px; margin-left: 100px'>
      <img src="img/FriendsPlus/Page25/E1/2.jpg" style='height:17cm; width: 17cm; '/>
      <img style="width:18cm; height:10.5cm" src='img/FriendsPlus/Page25/E2/1.jpg' />
        <div style='position: absolute; top: 774px; left: 32px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P25-E3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E3answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "left",
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    stylesTextInput: {
      width: 750,
      paddingTop: 15,
      background: "none",
      borderBottom: "solid 1.5px",
      fontSize: 21,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "3",
        title: `Study the expressions in <span style='color: #5cc9dd'>blue</span> in the report. Rewrite 
        sentences 1–4. Include the words in brackets.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; width: 27cm'>
        <div style='margin-left: 100px'>
        <img style="width:17cm; height:16.5cm" src='img/FriendsPlus/Page25/E1/2.jpg' />
        </div>

          <div style=' margin-top: 10px; margin-left: 50px '>
              <div>
                <div><b>1&ensp;</b>Most students don’t like exams. Many teachers 
                think they are useful. (whereas)</div>
                <textarea id='0' rows='2'>
              </div>
              <div>
                <div><b>2&ensp;</b>I like trying out new things. I don’t always like 
                the things I try. (although)</div>
                <textarea id='1' rows='2'>
              </div>
              <div>
                <div><b>3&ensp;</b>I find maths and science really easy. Subjects 
                like literature and English are difficult for me. (In contrast)</div>
                <textarea id='2' rows='2'>
              </div>

              <div>
                <div><b>4&ensp;</b>I think exams are important. I think we have too 
                many. (However)</div>
                <textarea id='3' rows='2'>
              </div>

          </div>
        </div>
        `,
        answer: [
          "Most students don't like exams, whereas many teachers think they are useful.|Most students do not like exams, whereas many teachers think they are useful.",
          "I like trying out new things, although I don't always like the things I try.|I like trying out new things, although I do not always like the things I try.",
          "I find maths and science really easy. In contrast, subjects like literature and English are difficult for me.",
          "I think exams are important. However, I think we have too many.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P25-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/>
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style=' margin-left: -30px' >
        <img style="width:15cm; height:18cm; margin-left: 0px; margin-left: 100px" src='img/FriendsPlus/Page25/E4/1.jpg' />
        <textarea id='1' rows='10'>
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
