import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P70-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page70/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P70-E1",
    exerciseKey: "img/FriendsPlus/Page70/Key/E1answerKey.png",
    component: T6,
    stylesTextInput: { width: 500, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "although",
          "became",
          "'ve become",
          "despite",
          "in spite",
          "started",
          "'ve started",
          "never",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read about the city improvement projects. 
          Which project interest most stakers?`,
        color: "#203c8f",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <img src="img/FriendsPlus/Page70/E1/1.jpg" style='height:17cm'/>
          <div style=" position: absolute; top: 10px; left: 47px; ">
            Which project does not exist yet?<br>#
          </div>
        </div> 
      
        
        `,
        answer: ["Projects 1 and 3|Projects 3 and 1"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P70-E2",
    exerciseKey: "img/FriendsPlus/Page70/Key/E2answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete city features 1–12 with one word 
          from the text. Which project<br> would you 
          most like to see in your city?`,
        color: "#203c8f",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page70/E1/1.jpg" style='height:17cm'/>
        <div style='display:flex;line-height:50px'>
          <div>
            <b>1</b>&ensp;skate #<br>
            <b>2</b>&ensp;railway #<br>
            <b>3</b>&ensp;pedestrian #<br>
            <b>4</b>&ensp;amusement #<br>
            <b>5</b>&ensp;leisure #<br>
            <b>6</b>&ensp;ancient #<br>                             
          </div>
          <div style='margin-left:100px'>
            <b>7</b>&ensp;open-air  #<br>
            <b>8</b>&ensp;roller  #<br>
            <b>9</b>&ensp;heavy  #<br>
            <b>10</b>&ensp; industrial  #<br>
            <b>11</b>&ensp; green  #<br>
            <b>12</b>&ensp; music  #<br>
          </div>
        </div>
      
        
        `,
        answer: [
          "park",
          "line",
          "walkway(s)|walkways|walkway",
          "park",
          "facilities",
          "ruins",
          "cinema",
          "gardens",
          "traffic",
          "site",
          "space",
          "venues",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P70-E3",
    audio: "Audios/2.10.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E3answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 860, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    giaiThua: true,
    character: ",",
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.10 src='Audios/2.10 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Watch or listen. What 
          problems do the people talk about?`,
        color: "#203c8f",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          What problems do the people talk about?<br>
          <b>Theo</b>:#<br> 
          <b>Olivia</b>:#<br> 
          <b>Sam</b>:#<br> 
          <b>Kaitlin</b>:#<br> 
        </div>
      
        
        `,
        answer: [
          "A peaceful neighbourhood lacking  in leisure facilities for teenagers",
          "A lack of public transport to the  city centre",
          "Too many old, abandoned  buildings with graffiti, not enough green  spaces",
          "Noise from the stadium, too much heavy traffic",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P70-E4",
    audio: "Audios/2.10.mp3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.10 src='Audios/2.10 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
         Watch or listen again. Which of 
          the key phrases do you hear?`,
        color: "#203c8f",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          // border: "2px solid",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          // borderColor: "gray",
        },
        limitSelect: 6,
        listWords: [
          `There_isn’t_/_aren’t_enough_… <br> 
          There’s_a_lack_of_… <br> 
          There’s_too_much_/_too_little … <br> 
          There_are_too_many_/_too_few … <br> 
          I_think_my_town_needs_… <br> 
          I_wish_we_had_…`,
        ],
        answers: ["0-0", "0-26", "0-52", "0-80", "0-108"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
	        <img src="img/FriendsPlus/Page70/E4/1.jpg" style='height:95mm'/>
          <div style=" position: absolute; top: 70px; left: 47px; ">
            <b style='color:#00aeef'>Talking about your neighbourhood</b>
            <div><input id='0'  type='Circle' /></div>
          </div>   
        </div> 
      
      `,
    },
  },
  5: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P70-E5",
    titleQuestion: [
      {
        num: "5",
        title: `<prefix></prefix> Work in groups. Use the key 
          phrases and the ideas in the box to talk about 
          one project to improve your neighborhood.`,
        color: "#203c8f",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    hideBtnFooter: true,
    // textAlign:"center",
    stylesTextInput: { width: 860, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "fun events",
          "green spaces",
          "leisure facilities",
          "public transport",
          "sharing",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative;'>
	        <img src="img/FriendsPlus/Page70/E4/1.jpg" style='height:95mm'/>
          <div style=" position: absolute; top: 70px; left: 47px; ">
            <b style='color:#00aeef'>Talking about your neighbourhood</b>
            <div>There isn’t / aren’t enough …<br>
              There’s a lack of …<br>
              There’s too much / too little …<br>
              There are too many / too few …<br>
              I think my town needs …<br>
              I wish we had …
            </div>
          </div>   
        </div> 
      
      
        
        `,
        answer: [
          "A peaceful neighbourhood lacking  in leisure facilities for teenagers",
          "A lack of public transport to the  city centre",
          "Too many old, abandoned  buildings with graffiti, not enough green  spaces",
          "Noise from the stadium, too much heavy traffic",
        ],
      },
    ],
  },
};

export default json;
