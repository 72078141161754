import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  // 1: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P4-E1",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //    //  maxLength: 25,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   stylesTextInput: {
  //     width: 180,
  //     paddingTop: 5,
  //     background: "none",
  //     borderBottom: "dotted 2px",
  //     fontSize: 21,
  //   },
  //   hintBox: [
  //     {
  //       src: [
  //         "celebrities",
  //         "comments",
  //         "links",
  //         "music",
  //         "passwords",
  //         "players",
  //         "profiles",
  //         "requests",
  //         "selfies",
  //         "<s>time</s>",
  //         "Wi-Fi",
  //       ],
  //       width: 900,
  //       borderColor: "#016DB5",
  //       marginLeft: 0,
  //       fontSize: "21px",
  //     },
  //   ],
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `Check the meaning of the verbs in <span style="color: #5cc9dd">blue</span> in the
  //       internet survey. Then complete it with the words
  //       in the box.`,
  //       color: "#016DB5",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <hintbox id='0'></hintbox>
  //       <div style='font-size: 25px; font-weight: 900; margin: 15px 0px 15px 10px'>Internet survey</div>
  //       <div style='display: flex;font-size: 21px; background-color: rgb(250,229,174); border-radius: 25px; padding: 20px 20px 20px 20px'>
  //       <div style='margin-right: 15px'>
  //         <b>1</b><br>
  //         <b>2</b><br>
  //         <b>3</b><br>
  //         <b>4</b><br>
  //         <b>5</b><br><br>
  //         <b>6</b><br>
  //         <b>7</b><br>
  //         <b>8</b><br><br>
  //         <b>9</b><br>
  //         <b>10</b><br>
  //         <b>11</b><br>
  //       </div>
  //       <div style=''>
  //         How much <i style='color: gray'>time</i> do you <span style='color: rgb(29,181,230)'>spend</span> online each day?<br>
  //         How often do you <span style='color: rgb(29,181,230)'>check</span> your phone to see if you have any new messages or friend #?<br>
  //         How often do you <span style='color: rgb(29,181,230)'>update</span> your social media #?<br>
  //         How many # do you normally take and <span style='color: rgb(29,181,230)'>upload</span> every week?<br>
  //         What websites do you normally look at? <br>
  //           Do you <span style='color: rgb(29,181,230)'>share</span> # to things which you like?<br>
  //         Do you ever <span style='color: rgb(29,181,230)'>post</span> # on blogs or news websites?<br>
  //         Do you <span style='color: rgb(29,181,230)'>follow</span> any # on social media? Who do you follow?<br>
  //         How often do you play games online? <br>
  //           Do you <span style='color: rgb(29,181,230)'>chat</span> to the other # ?<br>
  //         Do you usually <span style='color: rgb(29,181,230)'>download</span> #, TV shows and films or do you stream them?<br>
  //         Are hackers a problem? How often do you <span style='color: rgb(29,181,230)'>reset</span> your #?<br>
  //         When you meet friends, do you usually go to places where you can <span style='color: rgb(29,181,230)'>connect</span> to free #?<br>
  //           Where do you go?<br>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["so", "such", "so", "such"],
  //     },
  //   ],
  // },
  // 2: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P4-E2",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //   // maxLength:4,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   titleQuestion: [
  //     {
  //       num: "2",
  //       title: `Complete the dialogue using the correct
  //       form of the verbs in <span style="color: #5cc9dd">blue</span>. Then listen and check.`,
  //       color: "#016DB5",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <img style="width:27cm; height:15cm" src='img/FriendsPlus/Page4/E2/1.png' />
  //       <div style='display: flex; font-size: 21px'>
  //       <div style='margin-right: 15px'>
  //         <b>Rob</b>&ensp;<br><br>
  //         <b>Eve</b>&ensp;<br><br>
  //         <b>Rob</b>&ensp;<br>
  //         <b>Eve</b>&ensp;<br>
  //         <b>Rob</b>&ensp;<br>
  //         <b>Eve</b>&ensp;<br>
  //         <b>Rob</b>&ensp;<br>
  //         <b>Eve</b>&ensp;<br>
  //         <b>Rob</b>&ensp;<br>
  //       </div>
  //       <div style=''>
  //        <div>Look – it’s another picture of Alicia Vikander.<br>
  //        Every time I <sup><b>1</b></sup># my Facebook, I see a
  //        new picture of her!</div>
  //        <div>Well, she is very cool. I <sup><b>2</b></sup># her on
  //        Twitter. Yesterday, she <sup><b>3</b></sup># a link to the
  //        trailer of that new film she's in. It looks amazing!</div>
  //        <div>Yeah – I want to see that, too.</div>
  //        <div>Hey, are you online right now? I’m trying to
  //        <sup><b>4</b></sup># to the Wi-Fi but it’s not working.</div>
  //        <div>That’s because they <sup><b>5</b></sup># the password
  //        here every day. It’s Coffeetime200 today.</div>
  //        <div>Oh, OK … great. I’m online.</div>
  //        <div>We should go now. The bus is about to leave!</div>
  //        <div>Hang on! I’m just <sup><b>6</b></sup># my profile
  //        picture. There – do you like my latest selfie?</div>
  //        <div>I love it. Now come on! Let’s get out of here!</div>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["so", "such", "so", "such"],
  //     },
  //   ],
  // },
  // 3: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P4-E3",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //   // maxLength:4,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   textareaStyle: {
  //     // background: 'none',
  //     width: 800,
  //     fontSize: 22,
  //     height: 200,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "3",
  //       title: `Study the Key Phrases. Make sentences using
  //       the words in <b>bold</b>.`,
  //       color: "#016DB5",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <img style="width:17cm; height:8cm" src='img/FriendsPlus/Page4/E3/1.jpg' />
  //       <textarea id='0' rows='6'>
  //       `,
  //       answer: [""],
  //     },
  //   ],
  // },
  // 4: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P4-E4",
  //   exerciseKey: "img/FriendsPlus/Page4/Key/E4answerKey.png",
  //   component: T6,
  //   maxLength: 1,
  //   hideBtnFooter: true,
  //   textAlign: "center",
  //   // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "none",
  //     fontSize: 22,
  //     height: 27,
  //     textTransform: "uppercase",
  //     borderStyle: "dotted",
  //     textAlign: "center",
  //     width: 80,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "4",
  //       title: `<prefix></prefix> Work in pairs. Ask and answer the
  //       questions in the internet survey. Then choose a
  //       description from A–C for your partner.`,
  //       color: "#203c8f",
  //       left: 50,
  //       width: "25cm",
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <img style="width:19cm; height:8cm" src='img/FriendsPlus/Page4/E4/1.jpg' />
  //       <div style='margin-left: 50px;margin-top: 50px;display:flex '>
  //             <b>ANSWER:</b><br>
  //             &ensp;<b>#</b>
  //       </div>
  //       `,
  //       answer: ["A | B | C"],
  //     },
  //   ],
  // },
};

export default json;
