import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  12: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P29-E12",
    exerciseKey: "img/FriendsPlus/Page29/Key/E12answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "although",
          "became",
          "'ve become",
          "despite",
          "in spite",
          "started",
          "'ve started",
          "never",
        ],
        width: 800,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "12",
        title: `Complete the sentences with the words.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          <b>7</b>&ensp;<br>
          <b>8</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>I # business college two weeks ago.</div>
         <div>We # the creative writing course and we’re really enjoying it so far.</div>
         <div>This is a useful lesson. We # more 
         confident at speaking in front of people.</div>
         <div>Tanvi # a member of the school council last year.</div>
         <div>I still can't remember his house # I have been there several times.</div>
         <div>He was very fast # of being terribly overweight.</div>
         <div>My sister has # travelled abroad.</div>
         <div>They arrived late # leaving in plenty of time.</div>
        </div>
        </div>
        
        `,
        answer: [
          "started",
          "'ve started",
          "'ve become",
          "became",
          "although",
          "in spite",
          "never",
          "despite",
        ],
      },
    ],
  },
  13: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P29-E13",
    exerciseKey: "img/FriendsPlus/Page29/Key/E13answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 470,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "But it's up to you",
          "So I'm leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It's a tricky decision",
        ],
        width: 800,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "13",
        title: `Complete the dialogue. Then practise with 
        your partner.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='display: flex; font-size: 21px; line-height: 50px; width: 27.5cm'>
        <div style='margin-right: 10px'>
          <b>Mark</b>&ensp;<br>
          <b>Jack</b>&ensp;<br>
          <b>Mark</b>&ensp;<br>
          <b>Jack</b>&ensp;<br><br>
          <b>Mark</b>&ensp;<br><br>
          <b>Jack</b>&ensp;<br><br><br><br>
          <b>Mark</b>&ensp;<br>
          <b>Jack</b>&ensp;<br>
          
        </div>

        <div style='width: 27cm'>
         <div><sup><b>1</b></sup># , Jack?</div>
         <div>I’m choosing a school club for this year. Can you help me?</div>
         <div>OK. <sup><b>2</b></sup># ?</div>
         <div>Well, Friday is good for me and there’s 
         basketball club and film club on that day. I’m interested in both of them.</div>
         <div>Hmm … <sup><b>3</b></sup># .<sup><b>4</b></sup># ? 
         Maybe in town?</div>
         <div>Yes, there’s a basketball club at the 
         sports centre on Thursdays. <br>
         <sup><b>5</b></sup># . <sup><b>6</b></sup># is that it 
         sometimes finishes late. What do you reckon is best?</div>
         <div>Well, the film club sounds like the best 
         option. <sup><b>7</b></sup>#.</div>
         <div>Yes, I think you’re right. I’ll choose that.</div>
        </div>
        </div>
        
        `,
        answer: [
          "What are you up to",
          "What are your options",
          "It's a tricky decision",
          "Have you considered another basketball club",
          "So I'm leaning towards the film club",
          "The only bad thing about that club",
          "But it's up to you",
        ],
      },
    ],
  },
  14: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P29-E14",
    exerciseKey: "img/FriendsPlus/Page29/Key/E14answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "14",
        title: `Choose the correct options.`,
        color: "#4F3C94",
        left: -30,
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-8", "2-0", "3-8", "4-4", "5-0", "6-8"],
        initialValue: [],
      },
      Layout: `

      <div style='width:20cm'>
          In our survey about voting ages, we asked people 
          'Is sixteen old enough to vote in general elections?' 
          Of the 25 people who took part, 59% said 'yes' and 
          32% said 'no'. These people had strong opinions. 
          However, 9% of people were <sup><b>1</b></sup>..................... .<br>
          Those <sup><b>2</b></sup>..................... commented that it's important for 
          young people to have a voice, whereas those 
          <sup><b>3</b></sup>..................... said that politics doesn't affect young people. 
          Another comment from the 'yes' side was that 
          teenagers follow the news and know plenty about 
          the world. In contrast, people who said 'no' 
          <sup><b>4</b></sup>..................... that teenagers haven't had enough 
          experience of life.<br>
          Most people who were undecided did not make 
          comments, although one person said 'Everyone is 
          different'.<br>
          <sup><b>5</b></sup>..................... , the results <sup><b>6</b></sup>..................... that <sup><b>7</b></sup>............. of people 
          think that sixteen is old enough to vote.
          
      </div>
      <div style='position:relative; line-height:43.4px'>
        
        <div style='position:relative'>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='5' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='6' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;'>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
         
        </div>
        
        <div style='position:absolute;top:0px;left:65px'>
          <div>decided</div>
          <div>with favour</div>
          <div>against</div>
          <div>thinking</div>
          <div>All of all</div>
          <div>indicate</div>
          <div>most</div>
        </div>
        <div style='position:absolute;top:0px;left:320px'>
          <div>undecided</div>
          <div>as a favour</div>
          <div>agreeing</div>
          <div>thinks</div>
          <div>All in all</div>
          <div>showing</div>
          <div>the most</div>
        </div>
        <div style='position:absolute;top:0px;left:580px'>
          <div>unchosen</div>
          <div>in favour</div>
          <div>not favour</div>
          <div>think</div>
          <div>All with all</div>
          <div>shows</div>
          <div>the majority</div>
        </div>
      </div>

      `,
    },
  },
};

export default json;
