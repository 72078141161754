import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P10-E2",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page10/E2/1.jpg" }]],
  },
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P10-E1",
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    component: T6,
    maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      width: 36,
      fontSize: 21,
      backgroundColor: "beige",
      border: "1px solid",
      textTransform: "uppercase",
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the internet posts and check the 
        meaning of the words in <span style='#5cc9dd'>blue</span>. Match photos 
        A–E with the people who wrote the posts.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative;'>
	        <img src="img/FriendsPlus/Page10/E1/1.jpg" style='height:28cm; width: 26cm'/>
          <div style=" position: absolute; top: 21px; left: 773px; ">#</div>   
          <div style=" position: absolute; top: 244px; left: 748px; ">#</div>   
          <div style=" position: absolute; top: 455px; left: 752px; ">#</div>   
          <div style=" position: absolute; top: 677px; left: 761px; ">#</div>   
          <div style=" position: absolute; top: 882px; left: 761px; ">#</div>   
          
        </div>
      
        
        `,
        answer: ["C", "D", "E", "A", "B"],
      },
    ],
  },
};

export default json;
