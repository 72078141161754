import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  6: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page82/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E1",
    audio: "Audios/2.19.mp3",
    exerciseKey: "img/FriendsPlus/Page82/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.19 src='Audios/2.19 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Check the meaning of the <span style='color: #5cc9dd'>blue</span> words and choose the correct options.
        Listen and check.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "cut / burn",
          "broken / bruised", //2
          "a_bruise / an_injury",
          "burned / sprained",
          "burned / sprained",
          "cut / injured",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
      <img style="width:27cm; height:17cm; " src='img/FriendsPlus/Page82/E1/1.jpg' />
       
       <div style="position: absolute; top: 189px; left: 31px; width: 7cm">
       <b>1</b> &ensp;How did you <input id='0' type='Circle' /> your finger?
       </div>

       <div style="position: absolute; top: 189px; left: 404px; width: 7cm">
       <b>2</b> &ensp;I’m afraid your ankle is <input id='1' type='Circle' />.
       </div>

       <div style="position: absolute; top: 189px; left: 707px; width: 9cm">
       <b>3</b> &ensp;I fell off my bike. I’ve got <br> <input id='2' type='Circle' /> on my knee.
       </div>

       <div style="position: absolute; top: 469px; left: 31px; width: 7cm">
       <b>4</b> &ensp;I’ve <input id='3' type='Circle' /> my wrist again.
       </div>

       <div style="position: absolute; top: 469px; left: 404px; width: 7cm">
       <b>5</b> &ensp;She’s been in the sun and her nose is <input id='4' type='Circle' />.
       </div>

       <div style="position: absolute; top: 469px; left: 707px; width: 9cm">
       <b>6</b> &ensp;Their best player has <input id='5' type='Circle' /> his shoulder.
       </div>

    
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E2",
    audio: "Audios/2.20.mp3",
    exerciseKey: "img/FriendsPlus/Page82/Key/E2answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 25,
      height: 27,
      width: 180,
      // textTransform: 'uppercase',
      textAlign: "center",
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: ["past", "present", "will"],
        width: 350,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.20 src='Audios/2.20 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read the Study Strategy. Complete the table with words from exercise 1. Then listen and check.`,
        color: "#F78024",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width: 24cm; height: 14.5cm; margin-left: 44px" src='img/FriendsPlus/Page82/E2/1.png' />
        <img style="width:16cm; height: 5cm; margin-left: 170px" src='img/FriendsPlus/Page82/E2/2.jpg' />
        <div style='position: relative; font-size: 22px; margin-left: 80px'>
        <img style="width:23cm; height:13cm;" src='img/FriendsPlus/Page82/E2/3.jpg' />
        
        <div style='position: absolute; top: 121px; left: 64px'>#</div>
        <div style='position: absolute; top: 179px; left: 305px'>#</div>
        <div style='position: absolute; top: 237px; left: 64px'>#</div>

        <div style='position: absolute; top: 290px; left: 64px'>#</div>
        <div style='position: absolute; top: 290px; left: 305px'>#</div>
        <div style='position: absolute; top: 353px; left: 305px'>#</div>

        <div style='position: absolute; top: 353px; left: 604px'>#</div>
        <div style='position: absolute; top: 411px; left: 305px'>#</div>

        </div>
        `,
        // InputRong: true,
        answer: [
          "cut",
          "broken",
          "burn",
          "bruise",
          "bruised",
          "injured",
          "an injury",
          "sprained",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E3",
    exerciseKey: "img/FriendsPlus/Page82/Key/E3answerKey.png",
    audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.21 src='Audios/2.21 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen to interviews with three young people and answer the questions.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:17cm; height:8cm" src='img/FriendsPlus/Page82/E3/1.jpg' /><br>
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;Who has never broken any bones?</div>
          <div><textarea id='0' rows='1'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;Who has been to hospital?</div>
          <div><textarea id='1' rows='1'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;Who has had an operation?</div>
          <div><textarea id='2' rows='1'></div>

        </div>
       
        `,
        answer: ["Owen and Tim", "Laura and Rachel", "Rachel"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E4",
    exerciseKey: "img/FriendsPlus/Page82/Key/E4answerKey.png",
    audio: "Audios/2.21.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 230,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.21 src='Audios/2.21 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen again and complete the sentences.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 55px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>Laura’s favourite sport is #.</div>
         <div>She broke # when she was learning.</div>
         <div>Owen goes mountain biking with #.</div>
         <div>Owen’s record speed for downhill biking is #.</div>
         <div>Rachel plays goalie in #.</div>
         <div>She broke # when she fell off her bike.</div>
        </div>
        </div>
        
        `,
        answer: [
          "snowboarding",
          "her wrist",
          "his friend, Tim",
          "32 km/hour",
          "a team",
          "her ankle",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P82-E5",
    // exerciseKey: "img/FriendsPlus/Page81/Key/E3answerKey.png",
    component: T6,
    // maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/9.jpg'/>
        Work in pairs. Talk about injuries you or your family have had.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 50px' >
        <img style="width:19.5cm; height: 20cm; " src='img/FriendsPlus/Page82/E5/1.jpg' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
