import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  7: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page38/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E1",
    exerciseKey: "img/FriendsPlus/Page38/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      width: 230,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the Emotions test and match the adjectives 
        in <span style='color: #5cc9dd'>blue</span> with nouns 1–12.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 21px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page38/E1/1.jpg" style='height:22.5cm; width: 28cm '/>
          <div style='display: flex; justify-content: center'>
            <div>
              <div><b>1</b>&ensp;surprise<br>#</div>
              <div><b>2</b>&ensp;disgust<br>#</div>
              <div><b>3</b>&ensp;stress<br>#</div>
              <div><b>4</b>&ensp;worry<br>#</div>
              
            </div>
            <div style="margin-left: 70px">
            <div><b>5</b>&ensp;annoyance<br>#</div>
            <div><b>6</b>&ensp;anger<br>#</div>
            <div><b>7</b>&ensp;embarrassment<br>#</div>
            <div><b>8</b>&ensp;fear (x2)<br>#</div>

            </div>
            <div style="margin-left: 70px">
              
              <div><b>9</b>&ensp;boredom<br>#</div>
              <div><b>10</b>&ensp;sympathy<br>#</div>
              <div><b>11</b>&ensp;excitement<br>#</div>
              <div><b>12</b>&ensp;relaxation<br>#</div>

            </div>
          
          </div>
           
        </div> 
        `,
        answer: [
          "surprising",
          "disgusting",
          "stressful",
          "worried",
          "annoyed",
          "angry",
          "embarrassing",
          "afraid, frightening",
          "boring",
          "sympathetic",
          "exciting",
          "relaxing",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E2",
    exerciseKey: "img/FriendsPlus/Page38/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      width: 50,
      paddingTop: 5,
      textTransform: "uppercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Do the <i>Emotions test</i>. Then check your answers 
        with the key. How many did you get right?`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page38/E2/1.jpg" style='height:34.5cm; width: 25cm; margin-bottom: 20px '/>
          <div style='position: absolute; top: 1094px; left: 597px'>
            <b>Answer:</b><br>
            <div style='display: flex'>
              <div><b>1</b>&ensp;- # &ensp;</div>
              <div><b>2</b>&ensp;- # &ensp;</div>
              <div><b>3</b>&ensp;- # &ensp;</div>
            </div>
            <div style='display: flex'>
              <div><b>4</b>&ensp;- # &ensp;</div>
              <div><b>5</b>&ensp;- # &ensp;</div>
              <div><b>6</b>&ensp;- # &ensp;</div>
            </div>
            <div style='display: flex'>
              <div><b>7</b>&ensp;- # &ensp;</div>
              <div><b>8</b>&ensp;- # &ensp;</div>
            </div>
          </div>
           
        </div> 
        `,
        answer: ["E", "F", "C", "D", "A", "G", "B", "H"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E3",
    exerciseKey: "img/FriendsPlus/Page38/Key/E3answerKey.png",
    audio: "Audios/1.24.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.24 src='Audios/1.24 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Choose the correct words to complete the questions. Then watch or listen. Which 
        questions do the people discuss?`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    character: ",",
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          width: 70,
          background: "none",
          fontSize: 23,
          width: 200,
          paddingTop: 5,
          textAlign: "center",
        },
        answers: ["2, 3, 5"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "embarrassed / embarrassing",
          "stressed / stressful", //2
          "relaxed / relaxing", //3
          "frightened / frightening",
          "annoyed / annoying",
        ],
        answers: ["0-0", "1-4", "4-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 50px'>
       <div style='width: 25cm; line-height: 50px; font-size: 23px'>
         <div>
           <div style=""><b>1</b> &ensp;When did you last feel <input id='0' type='Circle' />?
           </div>
    
           <div style=""><b>2</b> &ensp;What kind of situations are <input id='1' type='Circle' /> for you?
           </div>
    
           <div style=""><b>3&ensp;</b> What activities do you find most <input id='2' type='Circle' />?
           </div>
    
           <div style=""><b>4</b> &ensp;What are you <input id='3' type='Circle' /> of?
           </div>

           <div style=""><b>5&ensp;</b> What is your most <input id='4' type='Circle' /> habit?
           </div>
    
         </div><br>
          <div>
          <b>Which questions do the people discuss?</b><br>
          <div><i style='font-size:18px; color: rgb(245,54,92)'>*Write the number of questions and between numbers seperated by commas (,).</i></div>
          <div>The people discuss questions: <input id='0' /></div>
          </div>
       </div>
    
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E4",
    exerciseKey: "img/FriendsPlus/Page38/Key/E4answerKey.png",
    audio: "Audios/1.24.mp3",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 900,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.24 src='Audios/1.24 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Study the Key Phrases. Then watch or listen again. What answers are given to the 
        questions in exercise 3?`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 40px'>
	        <img src="img/FriendsPlus/Page38/E4/1.jpg" style='height:7.5cm; width: 16cm '/>
            <div>
              <div><b>1</b>&ensp;When did you last feel embarrassed?</div>
              <div>#</div>
              <div><b>2</b>&ensp;What kind of situations are stressful for you?</div>
              <div>#</div>
              <div><b>3</b>&ensp;What activities do you find most relaxing?</div>
              <div>#</div>
              <div><b>4</b>&ensp;What are you frightened of?</div>
              <div>#</div>
              <div><b>5</b>&ensp;What is your most annoying habit?</div>
              <div>#</div>
              
          </div>
           
        </div> 
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E5",
    // exerciseKey: "img/FriendsPlus/Page38/Key/E4answerKey.png",
    // audio: "Audios/1.24.mp3",
    component: T6,
    textAlign: "center",
    recorder: true,
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 900,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/7.jpg'/>
        Work in pairs. Ask and answer the 
        questions in exercise 3. Use the key phrases.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 40px'>
	        <img src="img/FriendsPlus/Page38/E4/1.jpg" style='height:7.5cm; width: 16cm '/>
            <div>
              <div><b>1</b>&ensp;When did you last feel embarrassed?</div>
              <div><b>2</b>&ensp;What kind of situations are stressful for you?</div>
              <div><b>3</b>&ensp;What activities do you find most relaxing?</div>
              <div><b>4</b>&ensp;What are you frightened of?</div>
              <div><b>5</b>&ensp;What is your most annoying habit?</div>
              
          </div>
           
        </div> 
        `,
        answer: [""],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P38-E6",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Think of alternative adjectives to describe the emotions in the photos.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],

    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page38/E5/1.jpg" style='height:32.5cm; width: 23cm '/>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
