import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  5: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P76-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 50,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page76/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P76-E1",
    audio: "Audios/2.14.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E1answerKey.png",
    component: MatchDots,
    // maxLength:4,
    // hideBtnFooter:true,

    hintBox: [
      {
        src: [
          "are discovered",
          "hasn’t been removed",
          "might be hidden",
          "was examined",
          "was killed",
          "will be displayed",
        ],
        width: 500,
        borderColor: "#dc1e74",
        marginLeft: 200,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.14 src='Audios/2.14 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
         Study the sentences from two 
          different descriptions and match them with 
          photos A and B. Then watch or listen and check.`,
        color: "#dc1e74",
        left: -30,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "26px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "126px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "226px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "276px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "324px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "377px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "93px",
              left: "779px",
              width: "365px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "366px",
              left: "817px",
              width: "440px",
              height: "185px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
        ],
        answers: ["0-6", "3-6", "5-6", "1-7", "2-7", "4-7"],
        initialValue: [],
      },
      Layout: `
        <input id='0' type='boxMatch'/>
        <input id='1' type='boxMatch'/>
        <input id='2' type='boxMatch'/>
        <input id='3' type='boxMatch'/>
        <input id='4' type='boxMatch'/>
        <input id='5' type='boxMatch'/>
        <input id='6' type='boxMatch'/>
        <input id='7' type='boxMatch'/>
        <div   style="font-size: 25px;display:flex">
          <div style='margin-right:200px;line-height:50px;width:105mm'>
            <b>1</b>&ensp;I don’t think there are any cafés or shops.<br>
            <b>2</b>&ensp;Maybe it’s the only park in this part of the city.<br>
            <b>3</b>&ensp;This could be a bicycle lane.<br>
            <b>4</b>&ensp;It looks like an industrial site.<br>
            <b>5</b>&ensp;The area has been renovated.<br>
            <b>6</b>&ensp;One is a black and white photo of the city<br>
          </div>
          <div>
            <div>	<img style='height:5cm;margin-bottom:20px' src="img/FriendsPlus/Page76/E1/2.jpg"/></div>
            <div>	<img style='height:7cm' src="img/FriendsPlus/Page76/E1/1.jpg"/></div>
          </div>
        </div>
        
      `,
    },
  },

  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P76-E2",
    audio: "Audios/2.14.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:5,
    // hideBtnFooter:true,
    textareaStyle: { width: 280, paddingTop: 3 },
    stylesTextInput: { width: 450, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.14 src='Audios/2.14 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Watch or listen again. Which photo 
          does Tegan prefer?`,
        color: "#dc1e74",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
        <div style='display:flex;justify-content:space-between;margin-bottom:30px'>
          <img  style='height:5cm' src="img/FriendsPlus/Page76/E1/2.jpg"/>
          <img  style='height:5cm' src="img/FriendsPlus/Page76/E1/3.jpg"/>
          <img  style='height:5cm' src="img/FriendsPlus/Page76/E1/4.jpg"/>
        </div>
        <div style='display:flex'>
          <img  style='height:75mm;margin:0px' src="img/FriendsPlus/Page76/E1/1.jpg"/>
          
          <div style='margin:50px 20px'><b>Answer:</b>#</div>
        </div>

        `,
        answer: ["She prefers photo B. | Tegan prefers photo B."],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P76-E3",

    video: "",
    exerciseKey: "img/FriendsPlus/Page76/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 250, paddingTop: 10 },
    textAlign: "center",
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Study the Key Phrases. When we aren’t sure, we 
          can use phrases to speculate. Which phrases do 
          this?`,
        color: "#dc1e74",
        left: -30,
        width: "28cm",
        // prefix:{text:'PRONUNCIATION: Sentence stress'}
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: "1px 4px",
          borderRadius: "50%",
          // color:"black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 5,
        listWords: [
          `These two pictures show the same neighbourhood 
          of a city.<br>
          In the first picture, there are a few cars in front of 
          the church.<br>
          It’s hard to see, but I don’t think there are any 
          cafés or shops.<br>
          It_looks_like an industrial site, but_I’m_not_sure.
          By contrast, in this picture, there’s a large park 
          with trees. <br>
          Maybe_it’s the only park in this part of the city.<br>
          This_could_be a bicycle lane because I can’t see 
          any cars.`,
        ],
        answers: ["0-202", "0-210", "0-298", "0-338", "0-346"],
        initialValue: [],
      },
      Layout: `

            <div style='position: relative;width:195mm; margin-left: 50px'>
	            <img style='height:16cm;width:205mm' src="img/FriendsPlus/Page76/E2/1.jpg"/>
              <div style=" position: absolute; top: 115px; left: 40px; ">
                <input id=0 type='Circle'/>
              </div>   
            </div>
              
      `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P76-E4",
    exerciseKey: "img/FriendsPlus/Page76/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    textareaStyle: {
      width: 500,
      paddingTop: 3,
    },
    stylesTextInput: {
      width: 200,
      fontSize: 23,
      background: "none",
      borderBottom: "none",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "cover",
          "discover",
          "examine",
          "keep",
          "label make",
          "put",
          "take",
          "not touch",
          "unearth",
          "write",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 100,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<prefix></prefix> Complete the newspaper article. Use past, present 
          and future passive forms of the verbs in the box.`,
        color: "#dc1e74",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div>
              <textarea id=0 rows=10></textarea>
            </div>
            <img style='height:18cm' src="img/FriendsPlus/Page76/E4/1.jpg"/>
          </div>
      
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
