import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  7: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page12/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E1",
    exerciseKey: "img/FriendsPlus/Page12/Key/E1answerKey.png",
    audio: "Audios/1.05.mp3",
    component: T6,
    textAlign: "left",
    // maxLength: 1,
    stylesTextInput: { width: 500, paddingTop: 10 },
    textareaStyle: {
      // background: 'none',
      width: 500,
      paddingTop: 5,
      // fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.05 src='Audios/1.05 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Read and listen to the fact file. How 
        many types of vehicles are mentioned? What 
        are they?`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex'>
	        <img src="img/FriendsPlus/Page12/E1/1.jpg" style='height:29cm; width: 13cm '/>
          <div style="margin-left: 20px">
            <b>1</b>&ensp;How 
            many types of vehicles are mentioned?<br> #<br><br>
            <b>2</b>&ensp;What 
            are they?<br> <textarea id='1' rows='3'><br>
            
          </div>   
        </div> 
        `,
        answer: [
          "Three types of vehicles are mentioned.",
          "They are bicyles, motorbikes and cars.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E2",
    exerciseKey: "img/FriendsPlus/Page12/Key/E2answerKey.png",
    // audio: "Audios/2.12.mp3",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 180,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Read the text again. Find out the adjectives that 
        describe the forms of transport.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex'>
	        <img src="img/FriendsPlus/Page12/E1/1.jpg" style='height:29cm; width: 13cm '/>
          <div style="margin-left: 50px">
            #<br>
            #<br>
            #<br>
            #<br>
            #<br>
            #<br>
            #<br>
            #<br>
          </div>   
        </div> 
        `,
        answer: [
          "modern | important | fast | efficient | modern-day | main | convenient | affordable",
          "important | modern | fast | efficient | modern-day | main | convenient | affordable",
          "fast | modern | important | efficient | modern-day | main | convenient | affordable",
          "efficient | modern | important | fast | modern-day | main | convenient | affordable",
          "modern-day | modern | important | fast | efficient | main | convenient | affordable",
          "main | modern | important | fast | efficient | modern-day | convenient | affordable",
          "convenient | modern | important | fast | efficient | modern-day | main | affordable",
          "affordable | modern | important | fast | efficient | modern-day | main | convenient",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E3answerKey.png",
    // audio: "Audios/2-12.mp3",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    textareaStyle: {
      // background: 'none',
      width: 500,
      fontSize: 22,
      paddingTop: 5,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions.`,
        color: "#D92F3B",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; width: 26cm'>
	        <img src="img/FriendsPlus/Page12/E1/1.jpg" style='height:29cm; width: 13cm '/>
          <div style="margin-left: 20px">
            <div><b>1</b>&ensp;When were bicycles the important means of 
            transport in Việt Nam?<br> <textarea id='0' rows='2'></div>
            <div><b>2</b>&ensp;Why did people ride motorbikes instead of 
            bicycles?<br> <textarea id='1' rows='2'></div>
            <div><b>3</b>&ensp;What was the government trying to do during 
            the 1990s?<br> <textarea id='2' rows='2'></div>
            <div><b>4</b>&ensp;What vehicles do city dwellers prefer?<br> <textarea id='3' rows='2'></div>
            <div><b>5</b>&ensp;What services are becoming more 
            competitive?<br> <textarea id='4' rows='2'></div>
            
          </div>  
        </div> 
        `,
        answer: [
          "In the mid-20th century.",
          "Because motorbikes were faster and more efficient.",
          "The government was trying to invest in new infrastructure, like highways and railways.",
          "They prefer cars and motorbikes.",
          "Tech-based services are becoming more competitive.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E4",
    exerciseKey: "img/FriendsPlus/Page12/Key/E4answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 270,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color: #D92F3B; font-weight: 900'>VOCABULARY PLUS</span> &ensp;Match the phrasal verbs in 
        <span style='color: #5cc9dd'>blue</span> with the following meanings.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page12/E1/1.jpg" style='height:29cm; width: 13cm '/>
          <div style="margin-left: 20px">
            <div><b>1</b>&ensp;being caused by<br><span style='color: gray; font-weight: 600'>&ensp;resulting from&ensp;</span></div>
            <div><b>2</b>&ensp;caused (things) to happen<br>#</div>
            <div><b>3</b>&ensp;go or travel <br>#</div>
            <div><b>4</b>&ensp;need the help of<br>#</div>
            <div><b>5</b>&ensp;spend money or time on<br>#</div>
            
          </div>  
        </div> 
        `,
        answer: ["led to", "get around", "rely on", "invest in"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E5",
    exerciseKey: "img/FriendsPlus/Page12/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the correct forms 
        of phrasal verbs in the text.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 40px'>
        <img src="img/FriendsPlus/Page12/E1/1.jpg" style='height:29cm; width: 13cm '/>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style='width: 12cm'>
         <div>My grandmother is finding it harder to # these days.</div>
         <div>The city plans to # its educational system this year.</div>
         <div>Reducing speed limits has # fewer deaths on the roads.</div>
         <div>Many people now # the internet for news.</div>
         <div>His difficulty in walking # a 
         childhood illness.</div>
        </div>
        </div>
        
        `,
        answer: [
          "get around",
          "invest in",
          "led to",
          "rely on",
          "results from",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P12-E6",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/1.jpg' /> Work in pairs. Discuss the questions. 
        Then share your ideas with another pair.`,
        color: "#D92F3B",
        left: 50,
        width: "25cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px'>
        <b>1</b>&ensp;How many different kinds of transport have 
        you used?<br>#<br>
        <b>2</b>&ensp;Which transport do you prefer - air, road or 
        water?<br>#<br>
        <b>3</b>&ensp;Have you got a smartphone app to call a taxi?<br>#<br>
        <b>4</b>&ensp;How do you travel to school every day?<br>#<br>
        
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
