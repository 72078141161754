import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P53-E1",
    exerciseKey: "img/FriendsPlus/Page53/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["Rule 1", "Rule 2"],
    titleQuestion: [
      {
        num: "1",
        title: `Study examples a–f. Then match them with rules 1–2.`,
        color: "#5cc9dd ",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:15cm; height:7cm; margin-left: 100px" src='img/FriendsPlus/Page53/E1/1.jpg' />
        <div style='display: flex;  width: 25cm; line-height: 38px; margin-top: 20px'>
          <div>
            <div style=""><b>a</b> &ensp;Scientists like <span style='color: rgb(54,166,215)'> employing </span> English to share 
            the knowledge of inventions.&ensp; <select id=0></select></div>

            <div style=""><b>b</b> &ensp;English serves as a medium for 
            communication with those who love 
            <span style='color: rgb(54,166,215)'>listening</span> to new updates. &ensp;<select id=1></select></div> 

            <div style=""><b>c</b> &ensp;English enables scientists <span style='color: rgb(54,166,215)'>to collaborate</span> on 
            new ideas. &ensp;<select id=2></select></div>

            <div style=""><b>d</b> &ensp;Translating books into English allows them 
            <span style='color: rgb(54,166,215)'>to be</span> more accessible to the masses. &ensp; <select id=3></select></div>

            <div style=""><b>e</b> &ensp;Those who are not fluent in English hate 
            <span style='color: rgb(54,166,215)'>using</span> English to write scientific journals. &ensp; <select id=4></select></div>

            <div style=""><b>f</b> &ensp;They dislike <span style='color: rgb(54,166,215)'>working</span> in groups with lazy 
            classmates. &ensp; <select id=5></select></div>

          </div>
        </div>
        `,
        // InputRong: true,
        answer: ["Rule 1", "Rule 1", "Rule 2", "Rule 2", "Rule 1", "Rule 1"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P53-E2",
    exerciseKey: "img/FriendsPlus/Page53/Key/E2answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 130,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["Rule 1", "Rule 2"],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the gerund or 
        infinitive form of the verbs in brackets. Which 
        rule (1–2) in exercise 1 explains your answers?`,
        color: "#5cc9dd ",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:15cm; height:7cm; margin-left: 100px" src='img/FriendsPlus/Page53/E1/1.jpg' />
        <div style='display: flex;  width: 25cm; line-height: 45px; margin-top: 20px'>
          <div>
            <div style=""><b>1</b> &ensp;<span style='color: rgb(54,166,215)'>I hate </span># (do) business because I don't 
            like # (calculate).&ensp; </div><br>

            <div style=""><b>2</b> &ensp;<span style='color: rgb(54,166,215)'>I love </span># (learn) social work because 
            <span style='color: rgb(54,166,215)'> I have a passion </span> for community service. &ensp;</div> <br>

            <div style=""><b>3</b> &ensp;Knowing English <span style='color: rgb(54,166,215)'> enables me </span># 
            (discuss) with my foreign friends. &ensp;</div><br>

            <div style=""><b>4</b> &ensp;<span style='color: rgb(54,166,215)'>I dislike </span> # (become) a chef because 
            I'm not good at cooking. &ensp; </div><br>

            <div style=""><b>5</b> &ensp;In the future, <span style='color: rgb(54,166,215)'>I aim </span> # (be) a great 
            scientist because I <span style='color: rgb(54,166,215)'>really enjoy </span> # (find) out new things. &ensp; </div><br>

          </div>
          <div style='position: absolute; top: 331px; left: 106px'><select id=7></select></div>
          <div style='position: absolute; top: 331px; left: 593px'><select id=8></select></div>

          <div style='position: absolute; top: 422px; left: 106px'><select id=9></select></div>

          <div style='position: absolute; top: 511px; left: 341px'><select id=10></select></div>

          <div style='position: absolute; top: 602px; left: 123px'><select id=11></select></div>

          <div style='position: absolute; top: 693px; left: 243px'><select id=12></select></div>
          <div style='position: absolute; top: 693px; left: 822px'><select id=13></select></div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "doing",
          "calculating",
          "learning",
          "to discuss",
          "becoming",
          "to be",
          "finding",
          "Rule 1",
          "Rule 1",

          "Rule 1",

          "Rule 2",

          "Rule 1",

          "Rule 2",
          "Rule 1",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P53-E3",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "left",
    recorder: true,
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 130,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/3.jpg' /> 
        Talk about your likes and dislikes 
        about your future career. Use the blue phrases from exercise 2.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:17.5cm; height:7cm; margin-left: 100px" src='img/FriendsPlus/Page53/E3/1.jpg' />
        <div style='display: flex;  width: 25cm; line-height: 45px; margin-top: 20px; font-size: 23px'>
          <div>
            <div style=""><b>1</b> &ensp;<span style='color: rgb(54,166,215)'>I hate </span>doing (do) business because I don't 
            like calculating (calculate). </div>

            <div style=""><b>2</b> &ensp;<span style='color: rgb(54,166,215)'>I love </span>learning (learn) social work because 
            <span style='color: rgb(54,166,215)'> I have a passion </span> for community service.</div> 

            <div style=""><b>3</b> &ensp;Knowing English <span style='color: rgb(54,166,215)'> enables me </span> to discuss 
            (discuss) with my foreign friends.</div>

            <div style=""><b>4</b> &ensp;<span style='color: rgb(54,166,215)'>I dislike </span> becoming (become) a chef because 
            I'm not good at cooking.</div>

            <div style=""><b>5</b> &ensp;In the future, <span style='color: rgb(54,166,215)'>I aim </span> to be (be) a great 
            scientist because I <span style='color: rgb(54,166,215)'>really enjoy </span> finding (find) out new things.</div>

          </div>
        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P53-E4",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Write a paragraph about your likes and dislikes 
        for your future career using your answers from exercise 3.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='10'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
