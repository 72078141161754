import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P46-E2",
    exerciseKey: "img/FriendsPlus/Page46/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 120,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 650,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the words in the dialogues.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
   
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br><br>
          <b>6</b>&ensp;<br><br>
          
        </div>

        <div style='width: 24cm'>
         <div>'Do you think this gadget is expensive?'<br>
         'No, not at all. It's a b# !'</div>

         <div>'Who's that celebrity in the advert?'<br>
          'It's Keira Knightley. She always p#
          that perfume.'</div>

         <div>'Why does your neighbour drive a huge car?'<br>
          'Probably because it's a status s# .'</div>

         <div>'Shall we go to that restaurant over there?' <br>
          'No, it's a rip-# ! Let's try this one.'</div>

         <div>'Your new ring looks really valuable.' <br>
         'Sadly, it's plastic and it isn't w# much.'</div>

         <div>'Are you going to come to the concert?' <br>
          'No, I can't a# it. I've spent all my money.'</div>
        </div>
        </div>
        
        `,
        answer: ["argain", "romotes", "ymbol", "off", "orth", "fford"],
      },
    ],
  },
  2: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P46-E2",
    exerciseKey: "img/FriendsPlus/Page46/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Match 1–6 with a–f.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "26px",
              left: "445px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "62px",
              left: "449px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "97px",
              left: "407px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "131px",
              left: "430px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "166px",
              left: "428px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "200px",
              left: "474px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "36px",
              left: "638px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "69px",
              left: "638px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "106px",
              left: "638px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "140px",
              left: "638px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "638px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "638px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: ["0-7", "1-6", "10-2", "11-3", "4-8", "5-9"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width: 22cm; height: 7cm" src='img/FriendsPlus/Page46/E2/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />

        <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
			  <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
        
				
       </div>
      
      `,
    },
  },
  3: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P46-E3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Find the mistakes in these sentences and correct them.`,
        color: "#4F3C94",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [""],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          background: "none",
          // borderBottom: "2px dotted",
          fontSize: 22,
          height: 27,
          // textTransform: "uppercase",
          // textAlign: "center",
          width: 930,
          paddingTop: 5,
        },
        answers: [
          "Her sister can't get the good job as she doesn't have enough qualifications. / Her sister can't get the good job since she doesn't have enough qualifications. / Her sister can't get the good job because she doesn't have enough qualifications.",
          "Because of getting the poor scores in the exam, he felt deeply disappointed.",
          "We couldn't drive to the wedding party on time because of the bumpy road.",
          "That boy isn't allowed to enter the club since he is not old enough.",
          "Because the weather is terribly bad, the drivers have to drive very slowly. / As the weather is terribly bad, the drivers have to drive very slowly. / Since the weather is terribly bad, the drivers have to drive very slowly.",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          "Her sister can't get the good job so she doesn't have enough qualifications",
          "As getting the poor scores in the exam, he felt deeply disappointed",
          "We couldn't drive to the wedding party on time because the bumpy road",
          "That boy isn't allowed to enter the club since he not old enough",
          "Because_of the weather is terribly bad, the drivers have to drive very slowly",
        ],
        answers: ["0-14", "1-0", "2-18", "3-20", "4-0"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; width:27cm; font-size: 22px; margin-bottom: 100px'>
        <b>1</b>&ensp;<input id='0' type='Circle'/>.<br>
        <div><input id=0></div>
        <b>2</b>&ensp;<input id='1' type='Circle'/>.<br>
        <div><input id=1></div>
        <b>3</b>&ensp;<input id='2' type='Circle'/>.<br>
        <div><input id=2></div>
        <b>4</b>&ensp;<input id='3' type='Circle'/>.<br>
        <div><input id=3></div>
        <b>5</b>&ensp;<input id='4' type='Circle'/>.<br>
        <div><input id=4></div>
      </div>
      `,
    },
  },
  4: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P46-E4",
    exerciseKey: "img/FriendsPlus/Page46/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 750,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.30 src='Audios/1.30 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to the dialogue in a clothes shop and complete the text.`,
        color: "#4F3C94",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; margin-left: 50px'>

        <img src='img/FriendsPlus/Page46/E4/1.jpg' alt='' style='height:20cm; width: 20cm'/>
        
        <div style='position: absolute; top: 120px; left: 39px; line-height: 43px; width: 17cm'>
        You’re very welcome to <sup><b>1</b></sup># clothes in one 
        of our <sup><b>2</b></sup># changing rooms – you can find 
        these on the ground and <sup><b>3</b></sup># floors.
        If, for any reason, you’d like to bring clothes back, 
        please return them to the shop within <sup><b>4</b></sup>#
        days – you’ll need to bring along the <sup><b>5</b></sup># .
        We’re always happy to give customers their money 
        back or <sup><b>6</b></sup># items. You can do this in the 
        shop where you bought the clothes or in one of our 
        <sup><b>7</b></sup># other shops around the country.
        Don’t forget that if you can’t find your size, you can 
        order it at the <sup><b>8</b></sup># and we’ll <sup><b>9</b></sup>#
        it to you within <sup><b>10</b></sup># working days. You can 
        also order from our website: www.topwear.com.
        </div>

        </div>
        `,
        answer: [
          "try on",
          "eighteen | 18",
          "second | 2nd",
          "twenty-one | 21",
          "receipt",
          "exchange",
          "forty-five | 45",
          "checkout",
          "deliver",
          "seven | 7",
        ],
      },
    ],
  },
};

export default json;
