import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P74-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 20,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page74/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P74-E1",
    exerciseKey: "img/FriendsPlus/Page74/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the <i>Hidden History</i> web page and check the 
          meaning of the verbs in <span style='color:#5cc9dd'>blue</span>. Write the infinitive 
          forms of the verbs.`,
        color: "#f48328",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative;width:18cm;margin-left:300px; font-size: 21px; line-height: 40px'>
          <div><img src="img/FriendsPlus/Page74/E1/1.jpg" style='height:15cm'/></div>
          It’s amazing to think of the treasures that might be 
          <span style='color:rgb(92,201,221)'>hidden</span> under your feet. Did you know that many 
          ancient things are discovered accidentally by ordinary 
          people in everyday locations?<br>
          In 2007, Britain’s only complete Viking ship was 
          <span style='color:rgb(92,201,221)'>located</span> under a pub near Liverpool when the owner 
          decided to <span style='color:rgb(92,201,221)'>renovate</span> the building. Construction workers 
          were <span style='color:rgb(92,201,221)'>excavating</span> an area in order to <span style='color:rgb(92,201,221)'>build</span> a car park, 
          when they accidentally <span style='color:rgb(92,201,221)'>unearthed</span> the 1,000-year-old 
          ship. Thick mud had protected it. <br>
          The ship hasn’t been <span style='color:rgb(92,201,221)'>removed</span> yet, but archaeologists 
          plan to <span style='color:rgb(92,201,221)'>restore</span> it. They hope that it will be <span style='color:rgb(92,201,221)'>displayed</span> in 
          a museum one day.<br>
          Sometimes, archaeologists know what they’re looking 
          for and get lucky. The skeleton of King Richard III of 
          England was lost for centuries, since the church where 
          he was <span style='color:rgb(92,201,221)'>buried</span> had been <span style='color:rgb(92,201,221)'>destroyed</span>. But recently, the 
          king’s skeleton was <span style='color:rgb(92,201,221)'>uncovered</span> in a car park in Leicester. 
          It was <span style='color:rgb(92,201,221)'>examined</span> by experts, who used DNA techniques 
          to confirm the identity of the king.<br>
          Hidden History takes a closer look at some incredible 
          stories of accidental or lucky discoveries.
          <div style='position:absolute;top:0px;left:-300px'>
            <i style='color:gray;font-weight:bold'>hidden - hide</i><br>
            located - #<br>
            renovate - #<br>
            excavating - #<br>
            build - #<br>
            unearthed - #<br>
            removed - #<br>
            restore - #<br>
            displayed - #<br>
            buried - #<br>
            destroyed - #<br>
            uncovered - #<br>
            examined - #
          </div>
        </div>
      
        
        `,
        answer: [
          "locate",
          "renovate",
          "excavate",
          "build",
          "unearth",
          "remove",
          "restore",
          "display",
          "bury",
          "destroy",
          "uncover",
          "examine",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P74-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page74/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Circle the two words in each list that are synonyms.`,
        color: "#f48328",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 2,
        listWords: [
          "destroy|, demolish|, reconstruct",
          "build|, construct|, destroy",
          "bury|, find|, locate",
          "display|, uncover|, unearth",
          "examine|, excavate|, inspect",
          "remove|, renovate|, restore",
        ],
        answers: [
          "2-4",
          "2-8",
          "1-4",
          "1-0",
          "0-4",
          "0-0",
          "3-4",
          "3-8",
          "4-8",
          "4-0",
          "5-4",
          "5-8",
        ],
        initialValue: ["0-0", "0-4"],
      },
      Layout: `
      
              
              <div style="line-height:50px">
                <input id='0'  type='Circle' /><br>
                <b>1</b>&ensp;<input id='1'  type='Circle' /><br>
                <b>2</b>&ensp;<input id='2'  type='Circle' /><br>
                <b>3</b>&ensp;<input id='3'  type='Circle' /><br>
                <b>4</b>&ensp;<input id='4'  type='Circle' /><br>
                <b>5</b>&ensp;<input id='5'  type='Circle' /><br>
              </div>   
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P74-E3",
    audio: "Audios/2.12.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E3answerKey.png",
    component: T6,
    inputSize: 500,
    // textAlign:"center",
    // maxLength:5,
    stylesTextInput: { width: 740, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.12 src='Audios/2.12 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
         Listen to the radio programme 
          <i>Hidden History</i>. Which discovery is the topic 
          of the programme?`,
        color: "#f48328",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b style="font-style:italic">Answer:</b>#
        </div>
        
      
        
        `,
        answer: ["The discovery of King Richard III's skeleton"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P74-E4",
    audio: "Audios/2.12.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 230, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.12 src='Audios/2.12 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
         Read the sentences and decide what 
          type of information is missing: a date, number 
          or time. Then listen again and complete the 
          sentences.`,
        color: "#f48328",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:46px'> 
            <b>1</b>&ensp;King Richard III’s skeleton was found in# .<br>
            <b>2</b>&ensp;Richard III was killed on# .<br>
            <b>3</b>&ensp;He was King of England for#years.<br>
            <b>4</b>&ensp;Greyfriars Church was destroyed#years after Richard’s death.<br>
            <b>5</b>&ensp;Archaeologists started excavating the car park in# .<br>
            <b>6</b>&ensp;Richard was#years old when he died.<br>
            <b>7</b>&ensp;The skeleton was dated to between#and# .<br>
            <b>8</b>&ensp;Richard was reburied in# .<br>
        </div>
      
        
        `,
        answer: [
          "February 2013",
          "22nd August 1485",
          "two",
          "one hundred",
          "August 2012",
          "32",
          "1455",
          "1540",
          "March 2015",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB9-2024-Unit 7-P74-E5",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    stylesTextInput: { width: 230, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<prefix></prefix> Work in groups. Choose a historic 
          site in Việt Nam. Discuss the points in the box.`,
        color: "#f48328",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page74/E5/1.jpg" style='height:9cm;margin-left:100px'/>
      
        
        `,
        answer: [
          "February 2013",
          "22nd August 1485",
          "two",
          "one hundred",
          "August 2012",
          "32",
          "1455",
          "1540",
          "March 2015",
        ],
      },
    ],
  },
};

export default json;
