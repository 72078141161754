/* eslint-disable no-unused-expressions */
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import moment from "moment";
import "moment/locale/vi";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Container, Input, Modal, ModalBody, Row } from "reactstrap";
import {
  BigPlayButton,
  ClosedCaptionButton,
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  Player,
  ReplayControl,
} from "video-react";
import "video-react/dist/video-react.css";
import Animate from "../../../Animate";
import FooterIeltsMindset from "../../../FooterModal";
import Firework from "../../../ResultsAndEffects/fireworks.js";
import TitleQuestion from "../../../TitleQuestion";
// import Finished from "../../../Utilities/Finished";
import HintBox from "../../../Utilities/HintBox";
import styles from "./styles.module.css";
import MyWork from "../../../Utilities/MyWork";
import Recorder from "../../../Recorder";
import Listen from "../../../Listening";
moment.locale("vi");

const TypeIn6 = (props) => {
  // const inputTag = '#';
  const countInput = count(); // initialize the count function
  const [form] = Form.useForm();
  const [reset, setReset] = useState([]);
  const [coupleArray, setCoupleArray] = useState({
    addArray: [],
  });

  // let kaka = ["i","j","t","l",",",".","'"]
  // let rummenigge = ["A","B","C","D","E","F","G","H","K","L","N","O","P","Q","R","S","T","U","V","X","Y","Z"]
  // let torres = ["m","M","W","w"]
  // const LongWord = React.useCallback(()=>{
  //   if(props.questions[0]?.initialValue){
  //     const  gerrard = props.questions[0]?.initialValue.map(item => item.split(""))
  //     const result = gerrard.map(a => a.map(b => kaka.includes(b)));
  //     const hello =  result.map(i => i.filter(a => a === true).length)
  //     let thucNguyen =  props.questions[0]?.initialValue.map((item, index)=>
  //       {
  //         const wordUppercase = gerrard.map(a => a.map(b => torres.includes(b)))
  //         const longWord = wordUppercase.map(i=> i.filter(a=> a===true).length)
  //         const upper = gerrard.map(a => a.map(b => rummenigge.includes(b)))
  //         const Word = upper.map(i=> i.filter(a=> a===true).length)
  //        return hello[index] < item.length ? Word[index]/4 +  item.length + longWord[index] - hello[index]/2 : item.length + longWord[index] + Word[index]/4
  //       }
  //      )
  //   return   thucNguyen
  //   } return []
  //    })
  // const  [widthInput, setWidthInput] = useState(LongWord())
  // const [giaiThua,setGiaiThua] = useState([])
  // const [ ketqua,setKetqua] = useState(0)
  const [state, setState] = React.useState({
    star: 0,
    audioUrl: null,
    videoUrl: null,
    sentences: null,
    isPointed: false,
    studentAnswer: [],
    videoVisible: false,
    isDisabledRetry: true,
    isHello: props.isHello || false,
    isDisabledSubmit: true,
    booleanArray: [],
  });
  const [modal, setModal] = useState(false);

  React.useEffect(() => {
    const sentences = JSON.parse(JSON.stringify(props.questions));
    sentences.titleImage = props.titleImage;
    sentences.titleQuestion = props.titleQuestion;
    const audioUrl = props.audio;
    const videoUrl = props.video;
    const maxInput = sentences[0].answer.length;
    const isHello = props.isHello || false;
    const inputArray = [];
    //thay thế dấu # thành thẻ input
    sentences[0].title = sentences[0].title.replaceAll("#", "<input/>");
    setState((prevState) => ({
      ...prevState,
      sentences,
      audioUrl,
      videoUrl,
      inputArray,
      maxInput,
      isHello,
    }));
  }, [props]);
  const toggleState = React.useCallback(
    (fieldName) => () => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: !prevState[fieldName],
      }));
    },
    []
  );

  const onPlayVideo = React.useCallback(() => {
    toggleState("videoVisible")();
  }, [toggleState]);

  const onModal = React.useCallback(() => {
    setModal(true);
    setTimeout(() => {
      setModal(false);
    }, 3000);
  }, []);

  const onSubmit = React.useCallback(() => {
    form.submit();
    // setState(pre => ({ ...pre, isDisabledSubmit: true }))
    // show Modal Start
    onModal();
  }, [form, onModal]);

  const onRetry = React.useCallback(() => {
    form.setFieldsValue(reset);
    // form.resetFields();
    setState((preState) => ({
      ...preState,
      isDisabledInput: false,
      isDisabledRetry: true,
      isPointed: false,
      resultString: null,
      isDisabledSubmit: true,
      booleanArray: [],
      inputArray: reset,
    }));
  }, [form, reset]);
  // Khi hoàn thành các field
  const onFinish = React.useCallback(
    (value) => {
      let arrayChecked = [];
      let Check = [];
      let AnswerNotDuplicate = [];
      let CheckSymbols = [];
      let chau = [];
      let DTh = [];
      const studentAnswer = Object.values(value);
      const studentAnswerNotNull = studentAnswer.map((e) =>
        e === null ? "" : e
      );
      const veoveo = props.character ?? "|";
      const Answers = props.questions[0].answer.map((item) =>
        item.split(veoveo)
      );
      const notSpaces = studentAnswerNotNull.map((str) => str.split(veoveo));
      const notSpacesAnswer = Answers.map((i) =>
        i.map((a) =>
          a
            .trim()
            .replace(/\s|[\.\?!]$/g, "")
            .toLowerCase()
        )
      );
      chau = notSpaces.map((item) =>
        item.map((a) =>
          a
            .trim()
            .replace(/\s|[\.\?!]$/g, "")
            .toLowerCase()
        )
      );

      // if (props.characterCoupleWork) {
      //   const hoho = chau.map((a) => {
      //     const ohyeah = a.map((i) => i.split(props.characterCoupleWork));
      //     return ohyeah;
      //   });
      //   const uida = notSpacesAnswer.map((a) => {
      //     const ohyeah = a.map((i) => i.split(props.characterCoupleWork));
      //     return ohyeah;
      //   });
      //   console.log("🚀 ~ uida ~ uida:", uida);
      //   console.log("🚀 ~ hoho ~ hoho:", hoho);
      // }
      // let resultCharacter;
      // resultCharacter = notSpacesAnswer.map((a) => {
      //   a.forEach((i) => (chau.flat().includes(i) ? i : ""));
      // });
      DTh = chau.map((cuacua, cococ) => {
        const vailua = cuacua.some((e) => !notSpacesAnswer[cococ].includes(e));
        return vailua !== true ? studentAnswerNotNull[cococ] : "";
      });
      if (props.questions[0]?.notSymbol === true) {
        DTh = notSpaces.map((i, index) =>
          notSpacesAnswer[index].some((v) => v === i)
            ? studentAnswerNotNull[index]
            : ""
        );
      }

      let mama = [];

      let cheche;
      for (let i = 0; i < studentAnswerNotNull.length; i++) {
        const cheo = DTh[i]
          .toLowerCase()
          .replace(/\s|\.$/g, "")
          .split(",");
        cheo.forEach((e) => (cheche = mama.flat().includes(e)));
        if (cheche) {
          mama.push([""]);
        } else {
          mama.push(cheo);
        }
        const value = DTh[i].toLowerCase();
        if (cheche) {
          AnswerNotDuplicate.push("");
        } else {
          AnswerNotDuplicate.push(value);
        }
      }
      // lọc đáp án không trùng lặp:  Cách 1 dùng for , Cách 2 dùng map dòng dưới.

      // AnswerNotDuplicate = DTh.map((e,index)=>{
      //   const cheo = e
      //   .toLowerCase()
      //   .replace(/\s|\.$/g, "")
      //   .split(",");
      //   cheo.forEach((e) => (cheche = mama.flat().includes(e)));
      //   if (cheche) {
      //     mama.push([""]);
      //   } else {
      //     mama.push(cheo);
      //   }
      //   const value = e.toLowerCase()
      //   if(cheche){return ""}else{ return value}
      // }) cách 2 lọc đáp án k lặp

      if (props.checkDuplicated === true) {
        const C = DTh.map((a, index) =>
          a !== "" && a.toLowerCase() === AnswerNotDuplicate[index]
            ? studentAnswerNotNull[index]
            : ""
        );
        setReset(C);
      } else {
        setReset(DTh);
      }
      // const BooleanMap = props.characterNotBoth ? Answers.flat() : Answers;
      const booleanArray = Answers.map((item, index) => {
        if (props.checkDuplicated) {
          Check = DTh.map((e) => e.toLowerCase());
          const isDupicated = AnswerNotDuplicate[index] === "";
          if (studentAnswerNotNull[index] !== "" && isDupicated) return false;
        }
        arrayChecked.push(Check[index]);
        if (props.questions[0]?.InputRong === true) {
          if (!item[0] === "") {
            return false;
          }
        } else {
          if (!item[0]) return true;
        }

        if (props.questions[0]?.notSymbol === true) {
          CheckSymbols = notSpaces.map((e) => e.replace(",", "-"));
          const chese = notSpacesAnswer[index].some(
            (e) => e === CheckSymbols[index]
          );
          if (chese === true) return true;
        }
        if (props.select === true) {
          const converStA = studentAnswerNotNull.map((e) =>
            e === "✓" ? "a" : "b"
          );
          const converAns = props.questions[0].answer.map((e) =>
            e === "✓" ? "a" : "b"
          );
          return converStA[index] === converAns[index];
        }
        const ThHaha = !chau[index].some(
          (ye) => !notSpacesAnswer[index].includes(ye.replace(/\s|\.$/g, ""))
        );
        return item.some(
          (x) =>
            props.checkUppercase
              ? x.trim() === studentAnswerNotNull[index].trim() // chữ hoa khác chữ thường
              : ThHaha // ko phân biệt hoa thường, bỏ dấu cuối câu
        );
      });
      const correctAnswer = booleanArray.filter((x) => x === true).length;
      const percent = parseInt((correctAnswer / booleanArray.length) * 100);
      const resultString = `${correctAnswer}/${booleanArray.length}`;
      const star = percent / 20;
      const params = {
        score: percent,
        unit: props.unit,
        results: resultString,
        exerciseId: props.id,
        sreatedDate: moment().format(),
        studentAnswerNotNull: JSON.stringify(studentAnswerNotNull),
        isHello: props.isHello ?? false,
      };
      props.postAnswerToApi(params);
      setState((preState) => ({
        ...preState,
        resultString,
        isPointed: true,
        booleanArray,
        isDisabledRetry: false,
        sentences: state.sentences,
      }));
      // onModal()
      setState((prevState) => ({
        ...prevState,
        isDisabledSubmit: true,
        booleanArray,
        studentAnswerNotNull: value,
        star,
      }));
    },
    [props, state.sentences, state.studentAnswerNotNull]
  );

  //kiểm tra các field
  const handleChange = React.useCallback(
    (changedValues, allValues) => {
      // let Array = [];
      // const value =  allValues.filter(item => item.value !== '').map(item => item.value)
      // for (let i = 0; i < parseFloat(value[0]); i++) {
      //   const element = parseFloat(value[0]) - i
      //   Array.push(element)
      // }
      // setGiaiThua(Array)
      // const isDisabledSubmit = allValues.some(item => !item.value) // tìm field còn trống
      const count_answer_none = state.sentences[0].answer.filter(
        (x) => x === "none"
      ).length;
      // const  gerrard = allValues.map(item => item.value.split(""))
      // const result = gerrard.map(a => a.map(b => kaka.includes(b)));
      // const hello =  result.map(i => i.filter(a => a === true).length)
      // let thucNguyen =  allValues.map((item, index)=>
      //   {
      //     const wordUppercase = gerrard.map(a => a.map(b => torres.includes(b)))
      //     const longWord = wordUppercase.map(i=> i.filter(a=> a===true).length)
      //     const upper = gerrard.map(a => a.map(b => rummenigge.includes(b)))
      //     const Word = upper.map(i=> i.filter(a=> a===true).length)
      //    return hello[index] < item.value.length ? Word[index]/4 +  item.value.length + longWord[index] - hello[index]/2 : item.value.length + longWord[index] + Word[index]/4
      //   }
      //  )
      // setWidthInput(thucNguyen)
      const count_input_empty = allValues.filter((item) => !item.value).length;

      const isDisabledSubmit =
        props.questions[0]?.InputRong === true
          ? count_input_empty === allValues.length
          : count_input_empty > count_answer_none;
      setState((pre) => ({ ...pre, isDisabledSubmit }));
    },
    [state]
  );

  const renderCheckIcon = React.useCallback(() => {
    return (
      !props.isHello &&
      state.isPointed &&
      props.isHiddenCheck &&
      (state.booleanArray[countInput.next()] ? (
        <CheckCircleOutlined style={{ fontSize: 25, color: "#2ecc71" }} />
      ) : (
        <CloseCircleOutlined style={{ fontSize: 25, color: "#e74c3c" }} />
      ))
    );
  });
  const onStopRecording = React.useCallback((recordURL) => {
    setState((prevState) => ({ ...prevState, recordURL: recordURL.blobURL }));
  }, []);
  const transform = React.useCallback(
    (node, index) => {
      if (node.type === "tag" && node.name === "input") {
        //render tag Input
        // let countWord = widthInput[index] > 0 ? widthInput[index] : 5
        // if(state.isPointed){
        //   countWord = widthInput[index]
        // }

        let currentInputNo = countInput.next();
        const { id, group, ...elementStyle } = node.attribs;
        //
        const maxLength = node.attribs.maxlength ?? props.maxLength;
        let Color = "black";
        if (state.isPointed) {
          Color =
            props.isHello ?? state.booleanArray?.[currentInputNo]
              ? "#2ecc71"
              : "#e74c3c";
        }
        return (
          <div key={index} style={{ display: "inline-flex", marginBottom: 0 }}>
            <Form.Item
              className="ml-1 mr-1"
              name={`${currentInputNo}`}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required:
                    props.questions[0]?.InputRong !== true &&
                    !props.questions[0].initialValue
                      ? !props.hideRulesRequired
                      : false,
                  message: "Please fill the answer",
                },
              ]}
              initialValue={
                props.questions[0]?.initialValue
                  ? props.questions[0]?.initialValue[id]
                  : null
              }
            >
              <Input
                maxLength={maxLength ?? props?.stylesTextInput.maxLength}
                autoComplete="off"
                disabled={state.isPointed}
                className={styles.input}
                style={{
                  height: 30,
                  // width: props.questions[0].initialValue ? countWord*14  : props.inputSize,
                  width: props.inputSize,
                  fontSize: 24,
                  fontWeight: "400",
                  textTransform: props.textTransform ?? "",
                  textAlign: props.textAlign ?? "",
                  border: "0px solid #A8A8A8",
                  borderRadius: 0,
                  borderBottomWidth: "2px",
                  padding: 10,
                  display: "inline",
                  backgroundColor: "white",
                  boxShadow: "none",
                  color: Color,
                  ...props.stylesTextInput,
                  ...elementStyle,
                }}
              />
            </Form.Item>
            {renderCheckIcon()}
          </div>
        );
      }
      if (node.type === "tag" && node.name === "textarea") {
        //render tag textarea
        //<div><textarea id="0" rows="7" text-indent='200px' color='red'  margin-top='500px' ></textarea></div>
        const { id, rows, ...elementStyle } = node.attribs;
        const { textareaStyle } = props;
        let Color = "black";
        if (state.isPointed) {
          Color =
            props.isHello ?? state.booleanArray?.[id] ? "#2ecc71" : "#e74c3c";
        }
        textareaStyle.color = Color;
        return (
          <div key={index}>
            <Form.Item
              name={id}
              initialValue={
                props.questions[0]?.initialValue
                  ? props.questions[0]?.initialValue[id]
                  : null
              }
            >
              <textarea
                rows={rows}
                className={styles.notes}
                style={{
                  fontSize: 24,
                  border: "none",
                  ...props.textareaStyle,
                  ...elementStyle,
                }}
              ></textarea>
            </Form.Item>
          </div>
        );
      }
      if (node.type === "tag" && node.name === "select") {
        //render tag select
        const { id, ...elementStyle } = node.attribs;
        const { isPointed } = state;
        const selectStyle = Object.assign({}, props.selectStyle);
        const options = props.selectOptionValues.map((item) => ({
          value: item,
        }));
        //
        if (isPointed) {
          selectStyle.color = state.booleanArray?.[id] ? "#2ecc71" : "#e74c3c";
        }
        return (
          <div key={index}>
            <Form.Item name={id} initialValue="">
              <Select
                options={options}
                open={isPointed ? false : undefined} // sau khi submit đóng bảng chọn
                style={{ fontSize: 24, ...selectStyle, ...elementStyle }}
              ></Select>
            </Form.Item>
          </div>
        );
      }
      if (node.type === "tag" && node.name === "hintbox") {
        const { id, ...elementStyle } = node.attribs;
        const styleElementHintBox = props?.styleHint?.styleElementHintBox;
        return props.hintBox.map((v, i) => {
          if (id !== `${i}`) return null;
          // cont
          const {
            src,
            style,
            marginLeft,
            borderColor,
            width,
            position,
            arrow,
            styleElement,
          } = v;
          return (
            <HintBox
              styleElement={styleElement ?? styleElementHintBox}
              key={i}
              src={src}
              borderColor={borderColor}
              width={width}
              elementStyle={elementStyle}
              marginLeft={marginLeft}
              style={style ?? props?.styleHint}
              position={position}
              arrow={arrow}
            />
          );
        });
      }
      if (node.type === "tag" && node.name === "mywork") {
        return (
          <React.Fragment key={index}>
            <MyWork
              text={props?.mywork}
              colobg={props?.colorBG}
              wordColor={props?.wordColor}
              boxColor={props?.boxColor}
              imgList={props?.myImage}
            />
          </React.Fragment>
        );
      }
    },
    [countInput, props, state]
  );

  // const handleClick = React.useCallback(()=>{
  //   let calculate
  //   if(Array.length > 0){ calculate = giaiThua.reduce((sum, cal)=> sum * cal)}
  //   setKetqua(calculate)

  // },[giaiThua, ketqua])

  const handleTestButton = () => {
    const res = props.questions[0].answer.map((item, index) => [index, item]);
    const FieldsValue = Object.fromEntries(res);
    form.setFieldsValue(FieldsValue);
    setState((pre) => ({
      ...pre,
      studentAnswer: FieldsValue,
      isDisabledSubmit: false,
    }));
  };

  if (!state.sentences) return null;

  return (
    <Container className="fluid">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Row style={{ marginLeft: "-100px" }}>
            <TitleQuestion titleQuestion={state.sentences.titleQuestion} />
          </Row>
          <Row>
            {/* {state.sentences.titleImage && (
              <img
                alt="..."
                style={{
                  width: props?.imgSize ?? "100%",
                  marginBottom: 10,
                  position: "relative",
                  left: "-50px",
                }}
                src={state.sentences.titleImage}
              />
            )} */}
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Form
              autoComplete="off"
              form={form}
              onFinish={onFinish}
              onFieldsChange={handleChange}
            >
              <div style={{ fontSize: 24, marginBottom: 50 }}>
                {ReactHtmlParser(state.sentences[0].title, { transform })}
              </div>
            </Form>
          </Row>
          {props.isHello !== true ? (
            <Modal centered isOpen={modal} style={{ maxWidth: 640 }}>
              <ModalBody>
                {/* {renderContent} */}
                {state.star >= 1 && <Firework />}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 240,
                  }}
                >
                  <Animate resultString={state.star} />
                </div>
              </ModalBody>
            </Modal>
          ) : (
            <React.Fragment />
          )}
        </div>
        {state.videoUrl && (
          <div class="d-flex justify-content-center">
            <div style={{ width: "35vw", marginBottom: 50 }}>
              <Player>
                <BigPlayButton position="center" />
                <source src={props.video} type="video/mp4" default />

                <track
                  kind="captions"
                  src={props.videoSub}
                  srcLang="en"
                  label="English"
                />
                <ControlBar autoHide={false}>
                  <ReplayControl seconds={10} order={1.1} />
                  <ForwardControl seconds={10} order={1.2} />
                  <CurrentTimeDisplay order={4.1} />
                  <ClosedCaptionButton order={7} />
                </ControlBar>
              </Player>
            </div>
          </div>
        )}
        {props?.recorder && (
          <Row className="justify-content-center" style={{ marginBottom: 30 }}>
            <Recorder
              // onRecording={onRecording}
              onStop={onStopRecording}
            />
            {state.recordURL && (
              <Listen
                custom
                audioURL={state.recordURL}
                style={{ padding: 0, borderWidth: 0 }}
              >
                <Button color="primary" id="tooltipRepeat" type="button">
                  <i style={{ fontSize: 15 }} className="fas fa-volume-up" />
                </Button>
              </Listen>
            )}
          </Row>
        )}
        {/* ------------------------------------ */}
        {/* <TestButton onClick={handleTestButton} /> */}
        {/* ------------------------------------ */}
        <FooterIeltsMindset
          onRetry={onRetry}
          onSubmit={onSubmit}
          onPlayVideo={onPlayVideo}
          audioUrl={state.audioUrl}
          result={state?.booleanArray}
          isHello={props.isHello}
          KeyMau={props.KeyMau}
          exerciseKey={props.exerciseKey}
          isDisabledRetry={state.isDisabledRetry}
          isDisabledSubmit={state.isDisabledSubmit}
          hideBtnFooter={props.hideBtnFooter ?? false} //not answer
        />
      </div>
    </Container>
  );
};
TypeIn6.propTypes = {
  question: PropTypes.instanceOf(Object),
};
export default React.memo(TypeIn6);
//*********
// version v3 (24/9/21)
//
function count() {
  let n = 0;
  return {
    next: () => n++,
    reset: () => (n = 0),
  };
}
function TestButton({ onClick }) {
  return <Button onClick={onClick}>điền đáp án test</Button>;
}
