import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  7: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page80/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E1",
    exerciseKey: "img/FriendsPlus/Page80/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the article. What is Jane Clark’s problem?`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px; margin-left: 50px'>
        <img style="width:25cm; height:25cm; margin-left: -50px" src='img/FriendsPlus/Page80/E1/1.jpg' /><br>
       <div><b>Answer:</b></div>
        #
        </div>
        `,
        // InputRong: true,
        answer: ["She's frightened of heights and speed."],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E2",
    exerciseKey: "img/FriendsPlus/Page80/Key/E2answerKey.png",
    audio: "Audios/2.17.mp3",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "left",
      width: 150,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.17 src='Audios/2.17 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the article again 
        and complete the sentences.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px; line-height: 50px'>
        <img style="width:25cm; height:22cm;" src='img/FriendsPlus/Page80/E1/1.jpg' />
        <div style='display: flex;  width: 25cm; margin-top: 20px'>
          <div>
            <div><b>1 </b>&ensp;</div>
            <div><b>2 </b>&ensp;</div>
            <div><b>3 </b>&ensp;</div>
            <div><b>4 </b>&ensp;</div>
            <div><b>5 </b>&ensp;</div>
            <div><b>6 </b>&ensp;</div>
          </div>
          <div>
            <div>Jane Clark is scared of # and #.</div>
            <div>Jane is going on <i>Oblivion</i> because she wants to be #.</div>
            <div>Jane’s # have come with her on the ride.</div>
            <div>Jane feels # after the ride.</div>
            <div>Some people love extreme sports because they love # and excitement.</div>
            <div># in your car is more dangerous than riding on a roller coaster.</div>
          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "heights",
          "speed",
          "brave",
          "friends",
          "happy",
          "danger",
          "Driving",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 880,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "3",
        title: `Read the article again. Write <i>true</i> or <i>false</i> and 
        correct the false statements.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:25cm; height:22cm;" src='img/FriendsPlus/Page80/E1/1.jpg' />
        <div style='display: flex;  width: 27cm; line-height: 44px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;Lots of people love roller coasters because 
            they are not afraid of danger. &ensp; <select id=5></select></div>
            <div>#</div>

            <div style="display: flex"><b>2</b> &ensp;Jane still feels scared after being on her 
            first roller coaster. &ensp;<select id=6></select></div> 
            <div>#</div>

            <div style="display: flex"><b>3</b> &ensp;Roller coasters are a real fear to some 
            people. &ensp;<select id=7></select></div>
            <div>#</div>

            <div style="display: flex"><b>4</b> &ensp;For those who prefer taking risks, extreme 
            sports are absolute musts. &ensp;<select id=8></select></div>
            <div>#</div>

            <div style="display: flex"><b>5</b> &ensp;According to the article, it is likely to have 
            an accident when you try a roller coaster. &ensp; <select id=9></select></div>
            <div>#</div>

          </div>
        </div>
        `,
        InputRong: true,
        answer: [
          "Lots of people love roller coasters because they know they're in no real danger.",
          "Jane feels happy after being on her first roller coaster.",
          "",
          "",
          "The chance of a fatal accident is one in 50 billion.",
          "false",
          "false",
          "true",
          "true",
          "false",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E4",
    exerciseKey: "img/FriendsPlus/Page80/Key/E4answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 190,
      paddingTop: 10,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "<s>common</s>",
          "damaged",
          "fashionable",
          "fragile",
          "rare",
          "tough",
          "undamaged",
          "unpopular",
          "useful",
          "valuable",
        ],
        width: 880,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color: #D92F3B; font-weight: 600'>VOCABULARY PLUS</span>
        Find the noun form of the words in <span style='color: rgb(54,166,215)'>blue</span> 
        in the text.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:25cm; height:22cm;" src='img/FriendsPlus/Page80/E1/1.jpg' />
        <div style='position: relative; font-size: 22px; line-height: 55px; ; margin-left: 150px'>
	      
        <img src="img/FriendsPlus/Page80/E4/2.jpg" style='height:12cm; width: 15cm '/>
          <div style="position: absolute; top: 66px; left: 97px; text-align: center">
          <div style='display: flex'>
            <div style='margin-right: 71px'>
              <div>frightened</div>
              <div>ridiculous</div>
              <div>brave</div>
              <div>excited</div>
              <div>risky</div>

              <div>fatal</div>
              
            </div>
            <div style='margin-left: 40px'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        // InputRong: true,
        answer: [
          "fright",
          "ridicule",
          "bravery",
          "excitement",
          "risk",
          "fatality",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E5",
    exerciseKey: "img/FriendsPlus/Page80/Key/E5answerKey.png",
    // audio: "Audios/2.17.mp3",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "left",
      width: 150,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the correct 
        forms of the nouns from exercise 4.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:18cm; height: 13cm; margin-left: 100px" src='img/FriendsPlus/Page80/E5/1.png' />
        <div style='display: flex;  width: 27cm; line-height: 50px; margin-top: 0px'>
          <div>
            <div><b>1 </b></div>
            <div><b>2 </b></div>
            <div><b>3 </b></div>
            <div><b>4 </b></div>
            <div><b>5 </b></div>
          </div>
          <div>
            <div>&ensp; The firefighter's # and risk saved the whole family from a burning building. </div>
            <div>&ensp; Skydiving is one of the extreme sports that are worth taking a #.</div>
            <div>&ensp; The accident on the highway caused a massive tailback, but there were no #.</div>
            <div>&ensp; The team's poor performance drew # from their fans.</div>
            <div>&ensp; The roller coaster ride at Universal Studios Singapore filled us with both # and #.</div>
          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "bravery",
          "risk",
          "fatalities",
          "ridicule",
          "fright",
          "excitement",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P80-E6",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    // maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/1.jpg'/>
        Work in pairs. Ask and answer the questions. Do you like any extreme 
        sports? What is it? Why / Why not?`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 150px' >
        <img style="width:19.5cm; height: 8cm; margin-left: -10px" src='img/FriendsPlus/Page80/E6/1.jpg' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
