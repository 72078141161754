import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E1",
    exerciseKey: "img/FriendsPlus/Page23/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/2.12.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      textAlign: "center",
      width: 220,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "1",
        title: `Study the examples and the rules. Write 
        <i>present perfect</i> or <i>past simple</i> for 1–6.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 23px; line-height: 50px; margin-left: 50px'>
          <div>
            <div>I decided my subjects a long time ago. <sup><b>1&ensp;</b></sup>#</div>
            <div>I haven’t decided anything. <sup><b>2&ensp;</b></sup>#</div>
            <div>I gained a lot of confidence when I was there. <sup><b>3&ensp;</b></sup>#</div>
            <div>I’ve gained a lot of confidence this year. <sup><b>4&ensp;</b></sup>#</div>
          </div>
          <img style="width:16.5cm; height:10.5cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
          <div style='display: flex; position: absolute; top: 288px; left: 38px; width: 14cm; line-height: 51px'>
            <div>
              <div>We use the <sup><b>5&ensp;</b></sup># when we talk about 
              finished time periods.</div>

              <div>We use the <sup><b>6&ensp;</b></sup># when we talk about 
              unfinished time periods or when we don’t mention a time period.</div> 

            </div>
          </div>
               
        </div>
        `,
        answer: [
          "past simple",
          "present perfect",
          "past simple",
          "present perfect",
          "past simple",
          "present perfect",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E2",
    exerciseKey: "img/FriendsPlus/Page23/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct options.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "I've_got_used_to / I_got_used_to",
          "I've_really_got_into / I_really_got_into", //2
          "David's_got_a_lot_out_of / David_got_a_lot_out_of", //3
          "have_you_finished / did_you_finish",
          "Have_you_got / Did_you_get",
        ],
        answers: ["1-0", "2-4", "4-4", "5-0", "6-4", "7-4", "0-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; line-height: 50px; font-size: 23px'>
       
         <div>
           <div style=""><b>1</b>&ensp;Since I came here, <input id='0' type='Circle' /> working.</div>
    
           <div style=""><b>2</b>&ensp;<input id='1' type='Circle' /> hockey in recent months.</div>
    
           <div style=""><b>3</b>&ensp;<input id='2' type='Circle' /> the lecture yesterday.</div>
    
           <div style=""><b>4</b>&ensp;When <input id='3' type='Circle' /> your work experience?</div>

           <div style=""><b>5</b>&ensp;<input id='4' type='Circle' /> in touch with Amy while she was here last week?</div>
         </div>
       
       </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E3",
    exerciseKey: "img/FriendsPlus/Page23/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/2.12.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "3",
        title: `Complete the text with the correct form of the 
        verbs in brackets. Use the present perfect or past simple.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative;'>
          <img style="width:24.5cm; height:17.5cm" src='img/FriendsPlus/Page23/E3/1.jpg' />
          <div style='position: absolute; top: 104px; left: 65px; width: 12.8cm; line-height: 34px; font-size: 20px'>
            I <sup><b>1&ensp;</b></sup># (start) my TY in September, and in the last three months I <sup><b>2&ensp;</b></sup># (be) really busy.
            Every week, there are lectures from interesting people. Yesterday, a forensic scientist <sup><b>3&ensp;</b></sup># (talk) to us about her job. 
            She <sup><b>4&ensp;</b></sup># (see) some interesting - and horrible! - crimes in her career and she <sup><b>5&ensp;</b></sup># (describe) some of them to us.<br>

            So far, I <sup><b>6&ensp;</b></sup># (not do) any work experience, but I'd love to follow a detective or a forensics expert like her for a week. 
            I <sup><b>7&ensp;</b></sup># (not get) the best results in my last science exams, but I think I can 
            improve and I <sup><b>8&ensp;</b></sup># (decide) to do some
            science subjects for my Leaving Certificate.
          </div>
               
        </div>
        `,
        answer: [
          "started",
          "'ve been|have been",
          "talked",
          "has seen",
          "described",
          "haven't done",
          "didn't get",
          "'ve decided|have decided",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E4",
    exerciseKey: "img/FriendsPlus/Page23/Key/E4answerKey.png",
    component: T6,
    // audio: "Audios/2.12.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "4",
        title: `Study the examples and complete the rules with
        <i>clause, gerund</i> or <i>noun phrase</i>.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; width: 26cm; font-size: 23px'>
          <div>
            <div><b>1</b>&ensp;Although / Though / Even though I was really 
            nervous about things like kayaking, I really got into it later.</div>
            <div><b>2</b>&ensp;Despite many difficulties, I learned about all of the instruments and helped with X-rays and mixing stuff.</div>
            <div><b>3</b>&ensp;In spite of being busy with our study, we've already raised over a thousand euros.</div>
          </div>
          <img style="width:20.5cm; height:12.5cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
          <div style='display: flex; position: absolute; top: 292px; left: 53px; width: 19cm; line-height: 48px'>
            <div>
              <div><b>1</b>&ensp; We use clauses and phrases of concession to 
              contrast two opposite meanings and show unexpected results.</div>

              <div><b>2</b>&ensp;<i>Though, although</i> and <i>even though</i> are followed by a #.</div>

              <div><b>3</b>&ensp;<i>Despite</i> and <i>in spite of</i> are followed by a # or #.</div> 

            </div>
          </div>
               
        </div>
        `,
        answer: ["clause", "gerund", "noun phrase"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E5",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page23/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct options.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "though / despite",
          "Even_though / Despite", //2
          "Although / In_spite_of", //3
          "though / in_spite_of",
          "although / despite",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 23px; line-height: 50px'>
       
         <div>
           <div style=""><b>1</b>&ensp;My brother has managed to save a lot of 
           money <input id='0' type='Circle' /> his small salary.
           </div>
    
           <div style=""><b>2</b>&ensp;<input id='1' type='Circle' /> it rained a lot, we 
           enjoyed our holiday.
           </div>
    
           <div style=""><b>3</b>&ensp;<input id='2' type='Circle' /> being very tired, they carried on walking.
           </div>
    
           <div style=""><b>4</b>&ensp;
           Many people still prefer to travel by plane 
           <input id='3' type='Circle' /> airfares have increased greatly.
           </div>

           <div style=""><b>5</b>&ensp;I got very wet in the rain <input id='4' type='Circle' />
           I had an umbrella.</div>
         </div>
       
    
       </div>
      
      `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E6",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/8.jpg'/> Write six sentences about your life. 
        Include adverbial clauses or phrases of concession.
        Compare your sentences with a partner's.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],

    questions: [
      {
        title: `
    
        <div style='color: gray; width: 25cm'>Although I only started learning how to play chess last year, I 
        have won the school competition.</div>
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='10'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P23-E7",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Imagine you are on a TY. Write a paragraph 
        about things you have already done and things 
        you want to do but haven’t done yet.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='10'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
