import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Match 1-5 with question tags a-e.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "302px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "302px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "302px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "124px",
              left: "302px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "157px",
              left: "302px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "22px",
              left: "445px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "56px",
              left: "445px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "87px",
              left: "445px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "121px",
              left: "445px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "154px",
              left: "445px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
        ],
        answers: ["0-7", "1-9", "2-5", "3-6", "4-8"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width:17cm; height:6cm" src='img/FriendsPlus/Page9/E1/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />

        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
				
       </div>
      
      `,
    },
  },
  2: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E2",
    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    component: T6,
    // audio: "Audios/2.12.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      borderStyle: "dotted",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 85,
      textAlign: "center",
      fontSize: 19,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "2",
        title: `Write <i>true</i> or <i>false</i> for rules 1-4.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative;'>
        <img style="width:22.5cm; height:12.5cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
        <div style='display: flex; position: absolute; top: 110px; left: 38px; width: 20cm; line-height: 44px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;We use question tags after a question form. &ensp; <select id=0></select></div>

            <div style="display: flex"><b>2</b> &ensp;We use the auxiliary verb do in all question 
            tags. &ensp; <select id=1></select></div> 
            
            <div style="display: flex"><b>3</b> &ensp;When a sentence is affirmative, we use a 
            negative question tag.&ensp;<select id=2></select></div>

            <div style="display: flex"><b>4</b> &ensp;When a sentence is negative, we use an 
            affirmative question tag. &ensp; <select id=3></select></div>

          </div>
        </div>
               
        </div>
        `,
        answer: ["false", "false", "true", "true"],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    component: T6,
    //  maxLength: 30,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 45px; margin-top: 20px'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div># an app for that, isn’t there?
         </div>
         <div># swim, can you?
         </div>
         <div># use my laptop, did she?
         </div>
         <div># got that new console, hasn’t he?
         </div>
         <div># buying his old console, aren’t you?
         </div>
         <div>Your parents # watch TV, do they?
         </div>
        </div>
        </div>
        
        `,
        answer: [
          "There's",
          "You can't",
          "She didn't use",
          "He's",
          "You're",
          "don't",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E4",
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Write question tags for sentences 1-5.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 45px; margin-top: 20px'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>We’ve got geography next lesson, # ?
         </div>
         <div>You don’t like her new film, #?
         </div>
         <div>The new VR headsets are good, #?
         </div>
         <div>Paul’s got a 3D projector, #?
         </div>
         <div>They went to the USA last year, #?
         </div>
         
        </div>
        </div>
        
        `,
        answer: [
          "haven't we",
          "do you",
          "aren't they",
          "hasn't he",
          "didn't they",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E5",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: `Study the examples and choose the correct options in rules 1-4.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //2
          "use_to / used_to", //3
          "use_to / used_to",
        ],
        answers: ["0-0", "1-0", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width:22.5cm; height:12.5cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
       <div style='display: flex; position: absolute; top: 110px; left: 38px; width: 20cm; line-height: 44px'>
         <div>
           <div style=""><b>1</b> &ensp;We use <i>used to</i> + infinitive when we talk about a habit or state in the past which is <input id='0' type='Circle' /> now.
           </div>
    
           <div style=""><b>2</b> &ensp;After <i>used to</i> we use the <input id='1' type='Circle' /> form of the verb.
           </div>
    
           <div style=""><b>3</b> &ensp;
           The negative form is <i>didn’t</i> <input id='2' type='Circle' />.
           </div>
    
           <div style=""><b>4</b> &ensp;
           The question form is <i>Did</i> + subject + <input id='3' type='Circle' /> + verb.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },

  6: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E6",
    exerciseKey: "img/FriendsPlus/Page9/Key/E6answerKey.png",
    component: T6,
    //  //  maxLength: 30,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "dotted 2px",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 250,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences and questions about life in the 1960s with the correct form of <i>used to</i> and the verbs in the box.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    hintBox: [
      {
        src: ["do", "exist", "have", "listen", "navigate", "wear", "write"],
        width: 800,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='margin-top: 20px'>In the 1960s, ...</div>
        <div style='display: flex; font-size: 22px; line-height: 45px'>
        
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          <b>7</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>we # with real maps instead 
         of GPS.
         </div>
         <div>people # to music on vinyl 
         records.
         </div>
         <div>people # games consoles.
         </div>
         <div>satellite TV #.
         </div>
         <div>teenagers # different clothes.
         </div>
         <div># people # letters 
         instead of emails?
         </div>
         <div>what # teenagers # 
         after school?</div>
        </div>
        </div>
        
        `,
        answer: [
          "used to navigate",
          "used to listen",
          "didn't use to have|did not use to have",
          "didn't use to exist|did not use to exist",
          "used to wear",
          "Did",
          "use to write",
          "did",
          "use to do",
        ],
      },
    ],
  },
  7: {
    unit: "Starter",
    id: "SB9-2024-Starter-P9-E7",
    exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "7",
        title: `<prefix></prefix> Write questions about your habits when you were younger. Use <i>used to</i> and the words in the box. Then ask and answer your questions with a partner.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    hintBox: [
      {
        src: ["eat", "spend", "have", "like", "play", "read", "speak", "watch"],
        width: 870,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img style="width:15cm; height:5cm; margin-top: 20px" src='img/FriendsPlus/Page9/E7/1.jpg' />
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='5'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
