import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P30-E3",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "55%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page30/E3/1.jpg" }]],
  },
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P30-E1",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 170,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "<s>common</s>",
          "damaged",
          "fashionable",
          "fragile",
          "rare",
          "tough",
          "undamaged",
          "unpopular",
          "useful",
          "valuable",
        ],
        width: 880,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the quiz. Then copy and complete 
        the table with the words in <span style='color: rgb(54,166,215)'>blue</span>.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page30/E1/1.jpg" style='height:37cm; width: 24cm '/>  
        <div style='position: relative; font-size: 20px; line-height: 35px; ; margin-left: 100px'>
	      
        <img src="img/FriendsPlus/Page30/E1/2.jpg" style='height:20cm; width: 15cm '/>
          <div style="position: absolute; top: 66px; left: 74px; text-align: center; line-height: 43px">
          <div style='display: flex'>
            <div style='margin-right: 40px'>
            <div style='font-weight: 600; color: gray; text-align: center'>afford</i></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 40px'>
              <div style='font-weight: 600; color: gray; text-align: center'>products</i></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: [
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|cost | promote | rent | save | spend",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
          "|advertisements | bargain | brands | business | company | consumers | luxury | marketing | price | producer | retailer | rip-off | status symbols | value",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P30-E2",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E2answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "2",
        title: `Do the quiz. Then compare your 
        answers with a partner's.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 5px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 5px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; b &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; c",
          "a &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; b &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; c",
          "a <br> b <br> c",
          "a &ensp; b &ensp;&ensp; c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-4", "2-0", "3-0", "4-0", "5-8"],
        initialValue: [],
      },
      Layout: `

      <div style='position:relative; line-height:43.4px; font-size: 20px'>
      <img src="img/FriendsPlus/Page30/E2/1.jpg" style='height:40cm; width: 27cm '/>  
        
        <div style='position: absolute; top: 410px; left: 40px; display:flex;justify-content:space-around; width:6.2cm; height: 1.1cm'><input id='0' type='Circle'/></div>
        <div style='position: absolute; top: 459px; left: 567px; display:flex;justify-content:space-around;width:6.5cm; height: 1.2cm'><input id='1' type='Circle'/></div>
        <div style='position: absolute; top: 735px; left: 697px; line-height: 26px'><input id='2' type='Circle'/></div>
        <div style='position: absolute; top: 1011px; left: 218px; display:flex;justify-content:space-around;width:6.1cm; height: 1.1cm'><input id='3' type='Circle'/></div>
        <div style='position: absolute; top: 1180px; left: 736px; line-height: 26px'><input id='4' type='Circle'/></div>
        <div style='position: absolute; top: 1341px; left: 117px; line-height: 25px'><input id='5' type='Circle'/></div>
          
      </div>

      `,
    },
  },
};

export default json;
