import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P45-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 50,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page45/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P45-E1",
    exerciseKey: "img/FriendsPlus/Page45/Key/E1answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "left",
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    stylesTextInput: {
      width: 400,
      paddingTop: 15,
      background: "none",
      borderBottom: "solid 1.5px",
      fontSize: 21,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "1",
        title: `Read the forum post. What advice does Kim 
        give to Linh? What do you think of the advice?`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello: true,
    // KeyMau: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; width: 26cm;'>
        <img src="img/FriendsPlus/Page45/E1/1.jpg" style='height:33cm; width: 14cm; margin-left: 150px'/>
          

          <div style=' margin-top: 10px; margin-left: 50px; '>
              <div>
                <div><b>1&ensp;</b>What advice does Kim give to Linh? What do you think of the advice?</div>
                <textarea id='0' rows='2'>
              </div>

          </div>
        </div>
        `,
        answer: [
          "Talk to them, offer them help with schoolwork, make other friends",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P45-E2",
    exerciseKey: "img/FriendsPlus/Page45/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:5,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 430,
      paddingTop: 10,
      fontWeight: 600,
      // textTransform: 'uppercase',
      // textAlign: "center",
    },
    textareaStyle: {
      // background: "none",
      width: 400,
      fontSize: 20,
      // height: 70,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "2",
        title: `Complete the Key Phrases.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 36px'>
          <img style='height:12cm; width: 20cm;' src="img/FriendsPlus/Page45/E2/1.jpg"/>
          <div style='position: absolute; top: 115px; left: 41px'>
            <div>Hey.</div>
            <div style='display: flex'><div>Let’s <sup><b>1</b></sup>&ensp;</div>#.</div>
            <div style='display: flex'><div>For a <sup><b>2</b></sup>&ensp;</div>#.</div>
            <div style='display: flex'><div>What do you <sup><b>3</b></sup>&ensp;</div>#?</div>
            <div style='display: flex'><div><sup><b>4</b></sup>&ensp;</div># else?</div>
            <div>I bet / I reckon …</div>
            <div style='display: flex'><div>And one more <sup><b>5</b></sup>&ensp;</div>#.</div>
            <div>Anyway, …</div>
          </div>
        </div>
       
        `,
        answer: ["think", "start", "reckon", "What", "thing"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P45-E3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E3answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "left",
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    stylesTextInput: {
      width: 750,
      paddingTop: 15,
      background: "none",
      borderBottom: "solid 1.5px",
      fontSize: 21,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "3",
        title: `Study points 1–4 and find examples in the post.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    isHello: true,
    KeyMau: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; width: 27cm'>
        <div style='margin-left: 130px'>
        <img style="width:15cm; height:30.5cm" src='img/FriendsPlus/Page45/E1/1.jpg' />
        </div>
        <div><i style='font-size:18px; color: rgb(245,54,92)'>*Write the examples and between them seperated by commas (,).</i></div>
          <div style=' margin-top: 10px; margin-left: 30px '>
            <div>A text with a conversational tone …</div>
              <div>
                <div><b>1&ensp;</b>includes questions for the reader.</div>
                <textarea id='0' rows='2'>
              </div>
              <div>
                <div><b>2&ensp;</b>uses informal and conversational language.</div>
                <textarea id='1' rows='2'>
              </div>
              <div>
                <div><b>3&ensp;</b>uses question tags.</div>
                <textarea id='2' rows='2'>
              </div>

              <div>
                <div><b>4&ensp;</b>sometimes uses CAPITALS, <b>bold</b>, <i>italic</i>, 
                <u>underlined</u> words or emojis for emphasis.</div>
                <textarea id='3' rows='2'>
              </div>

          </div>
        </div>
        `,
        answer: [
          "What could you do?/ What do you reckon? / Are you meeting a lot of new people at the moment?",
          "(key phrases)",
          "OK, well this is a slightly annoying situation, isn't it?",
          "LOADS",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P45-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/>
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style=' margin-left: -30px' >
        <img style="width:15cm; height:21cm; margin-left: 0px; margin-left: 130px" src='img/FriendsPlus/Page45/E4/1.jpg' />
        <textarea id='0' rows='10'>
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
