import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  5: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P27-E5",
    exerciseKey: "img/FriendsPlus/Page27/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct options.`,
        color: "#4F3C94",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [""],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "was_building / built / ’d_built",
          "'d_written / was_writing /  wrote",
          "was_finding / found /  had_found",
          "'d_looked / looked / was_looking",
          "was / was_being / had_been",
          "had_opened / was_opening / opened",
          "married / had_married / was_marrying",
        ],
        answers: ["0-0", "1-10", "2-4", "3-8", "4-8", "5-8", "6-0"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; width:27cm; font-size: 23px'>
        <b>1</b>&ensp;Ake Viking was in the middle of some work.  He <input id='0' type='Circle'/> a boat.<br>
        <b>2</b>&ensp;He was lonely. So he <input id='1' type='Circle'/> a letter and put it in a bottle.<br>
        <b>3</b>&ensp;One day, a fisherman <input id='2' type='Circle'/> the bottle while he<br> <input id='3' type='Circle'/> through his nets.<br>
        <b>4</b>&ensp;The message <input id='4' type='Circle'/> in the  bottle for a long time when the fisherman <input id='5' type='Circle'/> it.<br>
        <b>5</b>&ensp;Later Ake <input id='6' type='Circle'/> Paolina, the fisherman’s daughter.<br>
      </div>
      `,
    },
  },
  6: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P27-E6",
    exerciseKey: "img/FriendsPlus/Page27/Key/E6answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Choose the correct words.`,
        color: "#4F3C94",
        left: -30,
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
        ],
        answers: [""],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-0", "2-4", "3-8", "4-0", "5-4", "6-8", "7-0"],
        initialValue: [],
      },
      Layout: `

      <div style='width:20cm'>
          Have I <sup><b>1</b></sup>............. you about the time I got into trouble 
          at the shops? It was the first day <sup><b>2</b></sup>............. the 
          summer holidays and I was shopping in town with 
          friends.<br>
          We were walking around a fashionable gift shop 
          and looking at some lava lamps <sup><b>3</b></sup>............. I suddenly 
          knocked one of them over! It fell onto the floor 
          and it was damaged. I remember <sup><b>4</b></sup>............. that I 
          was in big trouble! <sup><b>5</b></sup>............., the shop assistant 
          quickly called the manager. As you can imagine, I 
          felt upset and very afraid. The manager was a bit 
          angry, but <sup><b>6</b></sup>............. he said that I didn’t need to pay 
          for it.<br>
          And that’s <sup><b>7</b></sup>............. . All in all, it <sup><b>8</b></sup>............. a great 
          experience, but I learned to be more careful in 
          shops!
          
      </div>
      <div style='position:relative; line-height:43.4px'>
        
        <div style='position:relative'>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='5' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='6' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='7' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;'>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
        </div>
        
        <div style='position:absolute;top:0px;left:65px'>
          <div>said</div>
          <div>of</div>
          <div>while</div>
          <div>to</div>
          <div>Meanwhile</div>
          <div>lucky</div>
          <div>those</div>
          <div>wasn’t</div>
        </div>
        <div style='position:absolute;top:0px;left:320px'>
          <div>tell</div>
          <div>on</div>
          <div>when</div>
          <div>feel</div>
          <div>While</div>
          <div>luckily</div>
          <div>them</div>
          <div>was</div>
        </div>
        <div style='position:absolute;top:0px;left:580px'>
          <div>told</div>
          <div>at</div>
          <div>during</div>
          <div>feeling</div>
          <div>When</div>
          <div>luck</div>
          <div>it</div>
          <div>weren’t</div>
        </div>
      </div>

      `,
    },
  },

  7: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P27-E7",
    exerciseKey: "img/FriendsPlus/Page27/Key/E7answerKey.png",
    component: T6,
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 750,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the words.`,
        color: "#4F3C94",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img src='img/FriendsPlus/Page5/E7/1.jpg' alt='' style='margin:0px 20px;height:11cm'/>
        <div style='line-height:50px;width:23cm'>
            It all started <sup>1</sup># 7.30 in the morning 
            when my family got to Phong Nha town. We 
            were all eager to visit some of the fascinating 
            caves there. <sup>2</sup># , we enjoyed the scenery 
            of Việt Nam on our long journey. From the 
            town centre, we took a 30-minute boat ride on 
            the Son River to Phong Nha cave. <br>
            <sup>3</sup># that we arrived, we were impressed 
            by its rock formations and its underground 
            river. The cave is quite well-lit with high ceilings 
            and large dry areas. <sup>4</sup># we climbed 
            more than 500 steps to reach Tiên Sơn cave 
            on the right. The inside landscape looks 
            attractive and mysterious. <br>
            <sup>5</sup># I was walking through the 
            stalagmites and stalactites, my brother started 
            making a deep drumming sound by tapping on 
            one of them. <br>
            <sup>6</sup># , we were able to explore both caves 
            in only one trip. The time spent there was 
            evidence of the incredible power of nature. 
            When we <sup>7</sup># got back, it was 9.00.
        </div>
        `,
        answer: [
          "at",
          "Meanwhile",
          "The moment",
          "Afterwards",
          "While",
          "Amazingly",
          "finally",
        ],
      },
    ],
  },
};

export default json;
