import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Clil",
    id: "SB9-2024-Cl-P91-E1",
    exerciseKey: "img/FriendsPlus/Page91/Key/E1answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "consequences",
          "deceived",
          "envious",
          "identities",
          "reward",
          "trust",
          "unfair",
        ],
        width: 850,
        borderColor: "#D92F3B",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Check the meaning of the words in 
        the box. Then read and complete the text.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative; margin-top: 20px; margin-left: 0px'>
          <img style="width:24cm; height:37cm" src='img/FriendsPlus/Page91/E1/1.jpg' />

          <div style='position: absolute; top: 473px; left: 60px; font-size: 20px; line-height: 38.5px; width: 21.5cm'>
          <b>Life is full of decisions, but some are more difficult than others. 
          Sometimes we have to make a decision where every option seems 
          like a bad one, and those are the most difficult of all.</b><br>

          Marc and his sister Jasmine had important exams at school 
          recently. Marc studied hard, but he found the exams difficult 
          and his results were poor. However, Jasmine’s results were 
          much better. Her parents were delighted and bought her 
          a new computer as a <sup><b>1</b></sup>#. Marc was a bit 
          <sup><b>2</b></sup># – he’d worked hard, too – but he was pleased 
          for his sister. However, a few weeks later, Jasmine admitted to 
          Marc that she had cheated in the exams. She made him promise 
          not to say anything. Marc was shocked and angry that Jasmine 
          had <sup><b>3</b></sup># everyone, particularly their parents. 
          However, he knew that Jasmine would hate him if he broke his 
          promise. In the end, although he felt that it was completely 
          <sup><b>4</b></sup># , he decided to keep quiet.<br>

          In situations like this, the action that a person takes will depend 
          on their moral values. These are the principles that make us decide 
          what are the right and wrong things to do. In the same way that 
          people have different appearances and personalities, we have 
          different moral <sup><b>5</b></sup>#. A value that is extremely 
          important to one person, such as honesty, might not be as 
          important to another person. In Marc’s situation, he faced a moral 
          dilemma, but his moral values helped him to make a decision. He 
          strongly wanted Jasmine to face the <sup><b>6</b></sup># of her 
          actions, but he felt that breaking his sister’s <sup><b>7</b></sup>#
          would be even worse. Jasmine was lucky – other people might feel 
          that uncovering her dishonesty was the most important thing.
          </div>
        </div>
        
        `,
        answer: [
          "reward",
          "envious",
          "deceived",
          "unfair",
          "identities",
          "consequences",
          "trust",
        ],
      },
    ],
  },
  2: {
    unit: "Clil",
    id: "SB9-2024-Cl-P91-E2",
    exerciseKey: "img/FriendsPlus/Page91/Key/E2answerKey.png",
    audio: "Audios/3.03.mp3",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "consequences",
          "deceived",
          "envious",
          "identities",
          "reward",
          "trust",
          "unfair",
        ],
        width: 850,
        borderColor: "#D92F3B",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=3.03 src='Audios/3.03 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Check your answers to exercise 1.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 20px; margin-left: 0px'>
          <img style="width:24cm; height:37cm" src='img/FriendsPlus/Page91/E1/1.jpg' />

          <div style='position: absolute; top: 473px; left: 60px; font-size: 20px; line-height: 41.5px; width: 21.5cm'>
          <b>Life is full of decisions, but some are more difficult than others. 
          Sometimes we have to make a decision where every option seems 
          like a bad one, and those are the most difficult of all.</b><br>

          Marc and his sister Jasmine had important exams at school 
          recently. Marc studied hard, but he found the exams difficult 
          and his results were poor. However, Jasmine’s results were 
          much better. Her parents were delighted and bought her 
          a new computer as a <sup><b>1</b></sup><b>reward</b>. Marc was a bit 
          <sup><b>2</b></sup><b>envious</b> – he’d worked hard, too – but he was pleased 
          for his sister. However, a few weeks later, Jasmine admitted to 
          Marc that she had cheated in the exams. She made him promise 
          not to say anything. Marc was shocked and angry that Jasmine 
          had <sup><b>3</b></sup><b>deceived</b> everyone, particularly their parents. 
          However, he knew that Jasmine would hate him if he broke his 
          promise. In the end, although he felt that it was completely 
          <sup><b>4</b></sup><b>unfair</b> , he decided to keep quiet.<br>

          In situations like this, the action that a person takes will depend 
          on their moral values. These are the principles that make us decide 
          what are the right and wrong things to do. In the same way that 
          people have different appearances and personalities, we have 
          different moral <sup><b>5</b></sup><b>identities</b>. A value that is extremely 
          important to one person, such as honesty, might not be as 
          important to another person. In Marc’s situation, he faced a moral 
          dilemma, but his moral values helped him to make a decision. He 
          strongly wanted Jasmine to face the <sup><b>6</b></sup><b>consequences</b> of her 
          actions, but he felt that breaking his sister’s <sup><b>7</b></sup><b>trust</b>
          would be even worse. Jasmine was lucky – other people might feel 
          that uncovering her dishonesty was the most important thing.
          </div>
        </div>
        
        `,
        answer: [
          "reward",
          "envious",
          "deceived",
          "unfair",
          "identities",
          "consequences",
          "trust",
        ],
      },
    ],
  },
  3: {
    unit: "Clil",
    id: "SB9-2024-Cl-P91-E3",
    exerciseKey: "img/FriendsPlus/Page91/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 86,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["M", "J", "P", "N"],
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Write <i>M</i> (Marc), 
        <i>J</i> (Jasmine), <i>P</i> (their parents) or <i>N</i> (no 
        one) for sentences 1–8.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 20px; margin-left: 0px'>
          <img style="width:24cm; height:37cm" src='img/FriendsPlus/Page91/E1/1.jpg' />

          <div style='position: absolute; top: 473px; left: 60px; font-size: 20px; line-height: 41.5px; width: 21.5cm'>
          <b>Life is full of decisions, but some are more difficult than others. 
          Sometimes we have to make a decision where every option seems 
          like a bad one, and those are the most difficult of all.</b><br>

          Marc and his sister Jasmine had important exams at school 
          recently. Marc studied hard, but he found the exams difficult 
          and his results were poor. However, Jasmine’s results were 
          much better. Her parents were delighted and bought her 
          a new computer as a <sup><b>1</b></sup><b>reward</b>. Marc was a bit 
          <sup><b>2</b></sup><b>envious</b> – he’d worked hard, too – but he was pleased 
          for his sister. However, a few weeks later, Jasmine admitted to 
          Marc that she had cheated in the exams. She made him promise 
          not to say anything. Marc was shocked and angry that Jasmine 
          had <sup><b>3</b></sup><b>deceived</b> everyone, particularly their parents. 
          However, he knew that Jasmine would hate him if he broke his 
          promise. In the end, although he felt that it was completely 
          <sup><b>4</b></sup><b>unfair</b> , he decided to keep quiet.<br>

          In situations like this, the action that a person takes will depend 
          on their moral values. These are the principles that make us decide 
          what are the right and wrong things to do. In the same way that 
          people have different appearances and personalities, we have 
          different moral <sup><b>5</b></sup><b>identities</b>. A value that is extremely 
          important to one person, such as honesty, might not be as 
          important to another person. In Marc’s situation, he faced a moral 
          dilemma, but his moral values helped him to make a decision. He 
          strongly wanted Jasmine to face the <sup><b>6</b></sup><b>consequences</b> of her 
          actions, but he felt that breaking his sister’s <sup><b>7</b></sup><b>trust</b>
          would be even worse. Jasmine was lucky – other people might feel 
          that uncovering her dishonesty was the most important thing.
          </div>
        </div>

        <div style='display: flex; font-size: 22px;  width: 27cm; margin-left: 50px'>
        
        <div>
          <div style='margin-bottom: 26px'><b>1</b>&ensp;</div>
          <div style='margin-bottom: 23px'><b>2</b>&ensp;</div>
          <div style='margin-bottom: 28px'><b>3</b>&ensp;</div>
          <div style='margin-bottom: 24px'><b>4</b>&ensp;</div>
          <div style='margin-bottom: 25px'><b>5</b>&ensp;</div>
          <div style='margin-bottom: 24px'><b>6</b>&ensp;</div>
          <div style='margin-bottom: 25px'><b>7</b>&ensp;</div>
          <div style='margin-bottom: 25px'><b>8</b>&ensp;</div>
        </div>

        <div style=''>
          <div>
            <div style="display: flex"><select id=0></select>&ensp;didn’t get good exam results.</div>

            <div style="display: flex"><select id=1></select>&ensp;acted dishonestly.</div>

            <div style="display: flex"><select id=2></select>&ensp;experienced negative 
            emotions about what had happened.</div>

            <div style="display: flex"><select id=3></select>&ensp; didn’t find out that Jasmine 
            had cheated.</div>

            <div style="display: flex"><select id=4></select>&ensp;had a difficult decision.</div>

            <div style="display: flex"><select id=5></select>&ensp;broke a promise.</div>
            <div style="display: flex"><select id=6></select>&ensp;benefited from someone 
            else’s moral values.</div>
            <div style="display: flex"><select id=7></select>&ensp;changed their moral values as 
            a result of the situation.</div>


          </div>
        </div>
        `,
        // InputRong: true,
        answer: ["M", "J", "M", "P", "M", "N", "J", "N"],
      },
    ],
  },
  4: {
    unit: "Clil",
    id: "SB9-2024-Cl-P91-E4",
    // exerciseKey: "img/FriendsPlus/Page91/Key/E4answerKey.png",
    // audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. Discuss questions 1–6.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 20px; margin-left: 0px'>
          <img style="width:24cm; height:37cm" src='img/FriendsPlus/Page91/E1/1.jpg' />

          <div style='position: absolute; top: 473px; left: 60px; font-size: 20px; line-height: 41.5px; width: 21.5cm'>
          <b>Life is full of decisions, but some are more difficult than others. 
          Sometimes we have to make a decision where every option seems 
          like a bad one, and those are the most difficult of all.</b><br>

          Marc and his sister Jasmine had important exams at school 
          recently. Marc studied hard, but he found the exams difficult 
          and his results were poor. However, Jasmine’s results were 
          much better. Her parents were delighted and bought her 
          a new computer as a <sup><b>1</b></sup><b>reward</b>. Marc was a bit 
          <sup><b>2</b></sup><b>envious</b> – he’d worked hard, too – but he was pleased 
          for his sister. However, a few weeks later, Jasmine admitted to 
          Marc that she had cheated in the exams. She made him promise 
          not to say anything. Marc was shocked and angry that Jasmine 
          had <sup><b>3</b></sup><b>deceived</b> everyone, particularly their parents. 
          However, he knew that Jasmine would hate him if he broke his 
          promise. In the end, although he felt that it was completely 
          <sup><b>4</b></sup><b>unfair</b> , he decided to keep quiet.<br>

          In situations like this, the action that a person takes will depend 
          on their moral values. These are the principles that make us decide 
          what are the right and wrong things to do. In the same way that 
          people have different appearances and personalities, we have 
          different moral <sup><b>5</b></sup><b>identities</b>. A value that is extremely 
          important to one person, such as honesty, might not be as 
          important to another person. In Marc’s situation, he faced a moral 
          dilemma, but his moral values helped him to make a decision. He 
          strongly wanted Jasmine to face the <sup><b>6</b></sup><b>consequences</b> of her 
          actions, but he felt that breaking his sister’s <sup><b>7</b></sup><b>trust</b>
          would be even worse. Jasmine was lucky – other people might feel 
          that uncovering her dishonesty was the most important thing.
          </div>
        </div>

        <div style='font-size: 22px; line-height: 30px; width: 26cm'>
          <div style='font-weight: 600'><b>1</b>&ensp;Do you think Marc made the right 
          decision?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;Does it make a difference that 
          Jasmine is Marc’s sister?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;What would you do if you were 
          Jasmine’s mum or dad and you found out what had happened?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;Can you think of a reason why 
          Jasmine cheated?</div>
          <div><textarea id='3' rows='2'></div>

          <div style='font-weight: 600'><b>5</b>&ensp;Is it important to keep a promise? 
          Why / Why not?</div>
          <div><textarea id='4' rows='2'></div>

          <div style='font-weight: 600'><b>6</b>&ensp;Have you ever been in a situation like 
          this? What happened?</div>
          <div><textarea id='5' rows='2'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Clil",
    id: "SB9-2024-Cl-P91-E5",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/10.jpg'/> 
        Write about a moral dilemma that you have experienced. 
        Describe the situation and what you did. What happened in the end?`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 50px'><textarea id='0' rows='8'></div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
