import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Clil",
    id: "SB9-2024-Cl-P93-E1",
    exerciseKey: "img/FriendsPlus/Page93/Key/E1answerKey.png",
    audio: "Audios/3.05.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 500,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "consequences",
          "deceived",
          "envious",
          "identities",
          "reward",
          "trust",
          "unfair",
        ],
        width: 850,
        borderColor: "#D92F3B",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=3.05 src='Audios/3.05 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the article. What is 
        the name for the chemicals that help to create our emotions?`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 20px; margin-left: -30px; font-size: 22px'>
          <img style="width:29cm; height:24cm" src='img/FriendsPlus/Page93/E1/1.jpg' />

          <div style='position: absolute; top: 20px; left: 529px; width: 13cm'>
          <b>What is the name for the chemicals that help to create our emotions?</b><br>
          #
          </div>
        </div>
        
        `,
        answer: ["neurotransmitters"],
      },
    ],
  },
  2: {
    unit: "Clil",
    id: "SB9-2024-Cl-P93-E2",
    // exerciseKey: "img/FriendsPlus/Page93/Key/E1answerKey.png",
    // audio: "Audios/3.05.mp3",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 500,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "consequences",
          "deceived",
          "envious",
          "identities",
          "reward",
          "trust",
          "unfair",
        ],
        width: 850,
        borderColor: "#D92F3B",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Check the meaning of the words in <span style='color: #5cc9dd'>blue</span> in the article.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 20px; margin-left: -30px; font-size: 22px'>
          <img style="width:29cm; height:24cm" src='img/FriendsPlus/Page93/E1/1.jpg' />

        </div>
        
        `,
        answer: ["neurotransmitters"],
      },
    ],
  },
  3: {
    unit: "Clil",
    id: "SB9-2024-Cl-P93-E3",
    exerciseKey: "img/FriendsPlus/Page93/Key/E3answerKey.png",
    // audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Read the article again. Answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:29cm; height:24cm" src='img/FriendsPlus/Page93/E1/1.jpg' />

        <div style='font-size: 22px; line-height: 30px; width: 26cm'>
          <div style='font-weight: 600'><b>1</b>&ensp;According to the article, what is the purpose of 
          emotions?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;What do receptors do when they receive 
          information from our environment?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;What is a synapse?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;How does the electrical impulse go from one 
          neuron to another?</div>
          <div><textarea id='3' rows='2'></div>

        </div>
       
        `,
        answer: [
          "They help us to survive by making us feel good when we do something that is good for us.",
          "They send electrical impulses.",
          "A small gap between two neurons.",
          "It is carried across the synapse by neurotransmitters.",
        ],
      },
    ],
  },
  4: {
    unit: "Clil",
    id: "SB9-2024-Cl-P93-E4",
    exerciseKey: "img/FriendsPlus/Page93/Key/E4answerKey.png",
    // audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 50,
      paddingTop: 10,
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/10.jpg'/> 
        Read the information about dopamine, serotonin and endorphins then choose the sentence 
        from A‒C to complete each paragraph. `,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px; line-height: 37px'>
        <img style="width:22.5cm; height: 30cm" src='img/FriendsPlus/Page93/E4/1.jpg' />
        

        <div style='position: absolute; top: 620px; left: 68px; width: 20cm'>
          This neurotransmitter plays an important part in the brain’s 
          reward system. If you are enjoying a bar of chocolate, your 
          body produces more dopamine and you feel good. 
          <sup><b>1</b></sup>#. Dopamine is also important for sleep.
        </div>

        <div style='position: absolute; top: 779px; left: 68px; width: 20cm'>
        An increase in serotonin makes you feel happy. 
        <sup><b>2</b></sup>#. Your body produces serotonin when you 
        exercise and when you are in the sun. In winter, when the 
        days are shorter and there isn’t much sunshine, a lot of 
        people experience feelings of sadness. 
        </div>

        <div style='position: absolute; top: 963px; left: 68px; width: 20cm'>
        If you hurt yourself or if you are feeling stressed, your body 
        will produce endorphins to block the pain signals. But you 
        don’t need to be in pain to produce more endorphins: 
        <sup><b>3</b></sup>#.
        </div>

        </div>
        
        `,
        answer: ["B", "C", "A"],
      },
    ],
  },
};

export default json;
