import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Clil",
    id: "SB9-2024-Cl-P95-E1",
    // exerciseKey: "img/FriendsPlus/Page94/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the rules and play the personality game.`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
        <img style="width:25cm; height:22cm; margin-left: -20px" src='img/FriendsPlus/Page95/E1/1.jpg' />
        </div>
       
        `,
        answer: [""],
      },
    ],
  },
  2: {
    unit: "Clil",
    id: "SB9-2024-Cl-P95-E2",
    // exerciseKey: "img/FriendsPlus/Page94/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // recorder: true,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Work in groups. Make a personality game. Follow the steps in the project checklist.`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
        <img style="width:26cm; height:6cm; margin-left: -20px" src='img/FriendsPlus/Page95/E2/1.jpg' />
        </div>
       
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Clil",
    id: "SB9-2024-Cl-P95-E3",
    // exerciseKey: "img/FriendsPlus/Page94/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // recorder: true,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Play a new personality game in groups of four. Make a note of your score. 
        Who knows the people in your group the best?`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
        <textarea id='0' rows='10'>
        </div>
       
        `,
        answer: [""],
      },
    ],
  },
};
export default json;
