import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P26-E1",
    exerciseKey: "img/FriendsPlus/Page26/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 250,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 650,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the correct form 
        of the verbs. There are two extra verbs.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>I # an old diary yesterday.</div>
         <div>I # the handwriting - it was mine!</div>
         <div>I'll never # the first time I flew in a plane.</div>
         <div>The diary # me of when I was twelve.</div>
         <div>Now, I’ # to reading all of it.</div>
         <div>On the front of the diary it says ‘This diary # 
         Marina’.</div>
        </div>
        </div>
        
        `,
        answer: [
          "came across",
          "recognised",
          "forget",
          "reminds",
          "m looking forward",
          "belongs to",
        ],
      },
    ],
  },

  2: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P26-E2",
    exerciseKey: "img/FriendsPlus/Page26/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Match the first half to the correct second half 
        of the sentence.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "47px",
              left: "310px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "98px",
              left: "310px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "160px",
              left: "310px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "310px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "296px",
              left: "310px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "33px",
              left: "526px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "93px",
              left: "526px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "188px",
              left: "526px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "279px",
              left: "526px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "526px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: ["0-8", "1-5", "2-9", "3-6", "4-7"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width: 23cm; height: 11cm" src='img/FriendsPlus/Page26/E2/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />

        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
			  <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        
				
       </div>
      
      `,
    },
  },
  3: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P26-E3",
    exerciseKey: "img/FriendsPlus/Page26/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 750,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct past 
        perfect form of the verbs in the box.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>I realised that I # my history book at home.</div>
         <div># the shop # by the time you got there?</div>
         <div>When I # the book, I returned it to the library.</div>
         <div>Nina # a computer until her dad 
         bought one.</div>
         <div>How much # they # before 
         the exam? </div>
         <div>I # the play before I went to the 
         theatre to see it.</div>
        </div>
        </div>
        
        `,
        answer: [
          "had left",
          "Had",
          "closed",
          "had finished",
          "hadn't used",
          "had",
          "studied",
          "hadn't read",
        ],
      },
    ],
  },
  4: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P26-E4",
    exerciseKey: "img/FriendsPlus/Page26/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/1.16.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.16 src='Audios/1.16 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to the dialogue. Write 
        <i>true</i> or <i>false</i>.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; height: 600px; font-size: 23px'>
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 44px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;The genre of the dialogue is a radio news 
            programme.&ensp; <select id=0></select></div>
           

            <div style="display: flex"><b>2</b> &ensp;The collection included a lot of very 
            common Action Man toys. &ensp;<select id=1></select></div> 
           

            <div style="display: flex"><b>3</b> &ensp;The collector had worked for a film 
            company. &ensp;<select id=2></select></div>
            
            <div style="display: flex"><b>4</b> &ensp;The toys in the collection were unused. &ensp; <select id=3></select></div>
            

            <div style="display: flex"><b>5</b> &ensp;Some empty boxes in the collection were 
            valuable, too. &ensp; <select id=4></select></div>

            <div style="display: flex"><b>6</b> &ensp;Some rare Action Man clothes sold for 
            £5,480. &ensp; <select id=5></select></div>
          </div>
        </div>
               
        </div>
        `,
        InputRong: true,
        answer: ["true", "false", "false", "true", "true", "false"],
      },
    ],
  },
};

export default json;
