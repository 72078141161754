import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P59-E1",
    audio: "Audios/Page59/audio-e1.mp3",
    video: "",
    component: DesignUnderLine,
    // maxLength: 1,
    // fontSize: 27,
    exerciseKey: "img/FriendsPlus/Page59/E1/Key/answerKey.png",
    totalInput: 3,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and circle a or b. <headphone name='94' src='Audios/Page59/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page59/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page59/E1/2.jpg" },
        { url: "img/FriendsPlus/Page59/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page59/E1/4.jpg" },
        { url: "img/FriendsPlus/Page59/E1/5.jpg", input: 2 },
        { url: "img/FriendsPlus/Page59/E1/6.jpg" },
        { url: "img/FriendsPlus/Page59/E1/7.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page59/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page59/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page59/E1/10.jpg" },
        { url: "img/FriendsPlus/Page59/E1/11.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page59/E1/12.jpg" },
        { url: "img/FriendsPlus/Page59/E1/13.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page59/E1/14.jpg" },
        { url: "img/FriendsPlus/Page59/E1/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page59/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page59/E1/17.jpg" }],
    ],
  },

  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P59-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Point, ask and answer.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page59/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P59-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page59/E3/Key/answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    maxLength: 6,
    textAlign: "center",
    component: D1,
    stylesTextInput: {},
    fontSize: 26,
    // recorder: true,

    questionImage: [
      [{ url: "img/FriendsPlus/Page59/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page59/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page59/E3/3.jpg",
          input: true,
          answer: "water",
        },
        { url: "img/FriendsPlus/Page59/E3/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page59/E3/5.jpg" },
        {
          url: "img/FriendsPlus/Page59/E3/6.jpg",
          input: true,
          answer: "gloves",
        },
        { url: "img/FriendsPlus/Page59/E3/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page59/E3/8.jpg" },
        { url: "img/FriendsPlus/Page59/E3/9.jpg", input: true, answer: "kite" },
        { url: "img/FriendsPlus/Page59/E3/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page59/E3/11.jpg" }],
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P59-E4",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page59/E4/Key/answerKey.png",
    component: T6,
    inputSize: 40,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      height: 40,
      borderRadius: 10,
      border: "2px solid",
    },
    textareaStyle: { width: 700 },
    // maxLength: ,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write a weather report for today. Look at page 58 to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
        <div  style='line-height:50px'>
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: ["water 2 gloves 3 kite"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P59-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "5",
        title:
          "You are on vacation. Write a postcard to a friend. Look at page 42 to help you.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='position: relative;margin-left:20px'>
              <img style='margin-left:100px' src='img/FriendsPlus/Page42/E1/1.jpg'/>
            <span style='position: absolute; top: 420px; left:-80px'>
              <textarea id='0' rows='4'></textarea>
            
            </span>
            
            
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
