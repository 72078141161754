import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P85-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page85/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P85-E1",
    exerciseKey: "img/FriendsPlus/Page85/Key/E1answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the paragraph. Where did the writer’s 
        family have an unpleasant experience? How did they feel at that time?`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:17cm; height:21cm; margin-left: 140px" src='img/FriendsPlus/Page85/E1/1.jpg' /><br>
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'> Where did the writer’s 
          family have an unpleasant experience?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'>How did they feel at that time?</div>
          <div><textarea id='1' rows='2'></div>

        </div>
       
        `,
        answer: [
          "They had the unpleasant experience at a nearby park.",
          "They felt disappointed and frustrated.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P85-E2",
    // exerciseKey: "img/FriendsPlus/Page85/Key/E1answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Study the Key Phrases.`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:17cm; height:11cm; margin-left: 100px" src='img/FriendsPlus/Page85/E2/1.jpg' /><br>
       
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P85-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page85/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Study the <span style='color: #5cc9dd'>blue</span> words in the paragraph. Then 
        complete the sentences with the words. `,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div># I stumbled over a rock, I sprained my ankle. </div>
         <div>Jane fell off her bike # she was riding 
         home from school yesterday.</div>
         <div>We went paragliding # it had stopped raining.</div>
         <div>Read your book # your teacher comes back.</div>
        </div>
        
        `,
        answer: ["As soon as", "when", "after", "until"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P85-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/> 
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:14cm; height:17cm; margin-left: 170px" src='img/FriendsPlus/Page85/E4/1.jpg' />
        <div style='margin-left: 50px'><textarea id='0' rows='8'></div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
