import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  12: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P69-E12",
    exerciseKey: "img/FriendsPlus/Page69/Key/E12answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 86,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["D", "ND"],
    titleQuestion: [
      {
        num: "12",
        title: `Complete the sentences with the correct 
        relative pronouns and use commas where 
        necessary. Then write <i>D</i> (defining) or <i>ND</i> (non-defining).`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='display: flex; font-size: 22px;  width: 27cm'>
        
        <div>
          <div style='margin-bottom: 26px'><b>1</b>&ensp;</div><br>
          <div style='margin-bottom: 27px'><b>2</b>&ensp;</div><br>
          <div style='margin-bottom: 28px'><b>3</b>&ensp;</div><br>
          <div style='margin-bottom: 60px'><b>4</b>&ensp;</div><br>
          <div style='margin-bottom: 25px'><b>5</b>&ensp;</div><br>
        </div>

        <div style=''>
          <div>
            <div style="">My friend Lan # is a nature-lover, often 
            does volunteer work. &ensp; <select id=5></select></div>

            <div style="">Hà Nội # is the capital of Việt Nam, has 
            many places of interest. &ensp;<select id=6></select></div> 

            <div style="">This is the office # you can learn more 
            about work experience. &ensp;<select id=7></select></div>

            <div style="">The Việt Nam National Museum of History # is one of the top historical museums 
           in Việt Nam, has 110,000 documents and 
           antiques from the prehistoric time to 1945. &ensp; <select id=8></select></div>

            <div style="">Are you the student # mother organises 
            the music camp? &ensp; <select id=9></select></div>

          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "who",
          "which",
          "where",
          "which",
          "whose",
          "ND",
          "ND",
          "D",
          "ND",
          "D",
        ],
      },
    ],
  },
  13: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P69-E12",
    exerciseKey: "img/FriendsPlus/Page69/Key/E13answerKey.png",
    component: T6,
    // audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 280,
      paddingTop: 5,
      // fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "in your life",
          "Most people say",
          "that I'm easy-going",
          "That sounds wonderful",
          "That's a good question",
          "which involves art",
          "that I'm a hard worker",
          "Tell me about yourself",
          "Let me see",
        ],
        width: 760,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "13",
        title: `Complete the dialogue. Then practise with your partner.`,
        color: "#4F3C94",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative; height: 550px; font-size: 22px'>
          <div style=' position: absolute; top: 15px; left: 14px; width: 27cm; line-height: 45px; font-size: 21px'>
          
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Interviewer</div>
            <div style='width: 21.2cm'>What kind of work interests you?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Jessica</div>
            <div style='width: 21.2cm'>I’d like something <sup><b>1</b></sup>#.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Interviewer</div>
            <div style='width: 21.2cm'><sup><b>2</b></sup>#.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Jessica</div>
            <div style='width: 21.2cm'><sup><b>3</b></sup># ,... I see myself as 
            independent. <sup><b>4</b></sup># that 
            I’m a good leader. I’d also say <sup><b>5</b></sup>#.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Interviewer</div>
            <div style='width: 21.2cm'> What are your strengths?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Jessica</div>
            <div style='width: 21.2cm'><sup><b>6</b></sup>#. I’m friendly and 
            relaxed, so I suppose <sup><b>7</b></sup>#.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Interviewer</div>
            <div style='width: 21.2cm'>Tell me about a challenging 
            experience <sup><b>8</b></sup>#.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Jessica</div>
            <div style='width: 21.2cm'>Last year I cycled twenty 
            kilometres for charity.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 3.5cm; font-weight: 600; margin-right: 10px'>Interviewer</div>
            <div style='width: 21.2cm'><sup><b>9</b></sup>#.</div>
          </div>
          </div>

          
          
        </div>
          
        </div><br>
        `,
        answer: [
          "which involves art",
          "Tell me about yourself",
          "Let me see",
          "Most people say",
          "that I'm a hard worker",
          "That's a good question",
          "that I'm easy-going",
          "in your life",
          "That sounds wonderful",
        ],
      },
    ],
  },
  14: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P69-E14",
    exerciseKey: "img/FriendsPlus/Page69/Key/E14answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "14",
        title: `Choose the correct words.`,
        color: "#4F3C94",
        left: -30,
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-4", "2-4", "3-0", "4-8", "5-4", "6-8", "7-0"],
        initialValue: [],
      },
      Layout: `

      <div style='width:25cm; font-size: 22px'>
        Dear Ms Lê Xuân Mai,<br>
        I am a fourteen-year-old student in Year 9. <br>
        I <sup><b>1</b></sup>..................... to request further information on the 
        volunteer programme at ABC Youth Drama Centre. <br>
        The programme really <sup><b>2</b></sup>..................... me <sup><b>3</b></sup>..................... I take part 
        in a lot of drama productions, and I'm 
        <sup><b>4</b></sup>..................... work with the theatre directors at the Centre. I 
        plan to study drama at university to become an actor 
        one day.<br>
        I consider <sup><b>5</b></sup>..................... an independent person, so I could 
        work on my own with small groups of students. I'm 
        also very reliable, so I <sup><b>6</b></sup>..................... that I could be a helpful 
        member of the programme. <br>
        I'd be very grateful if you could email me some more 
        information. I am <sup><b>7</b></sup>..................... go on an exchange visit to 
        Huế, but I will be available from 5<sup>th</sup> May.<br>

        I look forward <sup><b>8</b></sup>..................... from you.<br>
        Yours sincerely,<br>
        Trần Thị Thanh
      </div>
      <div style='position:relative; line-height:43.4px; font-size: 22px; margin-left: 100px'>
        
        <div style='position:relative'>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='5' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='6' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='7' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;'>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
         
        </div>
        
        <div style='position:absolute;top:0px;left:65px'>
          <div>write</div>
          <div>interesting</div>
          <div>so that</div>
          <div>keen to</div>
          <div>herself</div>
          <div>’m believing</div>
          <div>aim to</div>
          <div>to hearing</div>
        </div>
        <div style='position:absolute;top:0px;left:320px'>
          <div>’ll write</div>
          <div>interests</div>
          <div>because</div>
          <div>keen on</div>
          <div>yourself</div>
          <div>believe</div>
          <div>around to</div>
          <div>to hear</div>
        </div>
        <div style='position:absolute;top:0px;left:580px'>
          <div>’m writing</div>
          <div>interest</div>
          <div>so</div>
          <div>intend to</div>
          <div>myself</div>
          <div>believed</div>
          <div>about to</div>
          <div>hear</div>
        </div>
      </div>

      `,
    },
  },
};

export default json;
