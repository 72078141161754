import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P67-E4",
    exerciseKey: "img/FriendsPlus/Page67/Key/E5answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["come", "design", "enter", "learn", "leave", "swim", "watch"],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the correct 
        conditional form of the verbs in brackets.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 26cm'>
         <div>If you # (go) outside tonight at 11.00, 
         you #(see) a meteor in the sky. (first conditional)</div>

         <div> If an astronaut #(not do) exercise, he 
         or she #(become) ill. (zero conditional)</div>

         <div>She #(not win) the prize unless she # (work) hard. (first conditional)</div>

         <div> If you # (speak) to the inventor of the 
         internet, what # (you / say)? (second conditional)</div>

         <div>Do you wish you # (become) a film star in the near future?</div>

         <div>I wish someone # (invent) a teleporting machine in real life.</div>
        </div>
        </div>
        
        `,
        answer: [
          "go",
          "'ll see",
          "doesn't do",
          "becomes",
          "won't win",
          "works",
          "spoke",
          "would you say",
          "would become",
          "invented",
        ],
      },
    ],
  },
  6: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P67-E6",
    exerciseKey: "img/FriendsPlus/Page67/Key/E6answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Choose the correct words.`,
        color: "#4F3C94",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "true / truth / truly",
          "not / nothing / no_doubt",
          "’m_believe / believe / belief",
          "to / around / about",
          "far / long / best",
          "In / On / Of",
          "what / why / who",
          "doubted / doubts / doubt",
        ],
        answers: ["0-0", "1-8", "2-4", "3-8", "4-0", "5-0", "6-4", "7-8"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; width:24cm; font-size: 21px'>

        In future, should drivers pay to take their cars into the 
        centre of big cities in order to stop pollution?
        Well, it’s <sup><b>1</b></sup><input id='0' type='Circle'/> that scientists are 
        concerned about increasing pollution levels in our 
        cities and there’s <sup><b>2</b></sup><input id='1' type='Circle'/> in my 
        mind that this could be a big problem in the future. 
        I <sup><b>3</b></sup><input id='2' type='Circle'/> that we all need to 
        take this threat seriously because when you think <sup><b>4</b></sup><input id='3' type='Circle'/> it, air quality affects everybody.
        As <sup><b>5</b></sup><input id='4' type='Circle'/> as I can see, making drivers pay 
        at busy times to go into city centres could help the 
        problem. <br>
        <sup><b>6</b></sup><input id='5' type='Circle'/> my opinion, it would reduce the amount 
        of trafic and pollution. Of course, I can see <sup><b>7</b></sup><input id='6' type='Circle'/> some people think it’s a bad idea – nobody 
        likes paying for things! If the government made drivers 
        pay, I <sup><b>8</b></sup><input id='7' type='Circle'/> that it would be 
        popular at first, but in the end, everybody would see the benefit of cleaner air.
      </div>
      `,
    },
  },
  7: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P67-E7",
    exerciseKey: "img/FriendsPlus/Page67/Key/E7answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Finally",
          "Furthermore",
          "suggests",
          "I believe",
          "in favour of",
          "In the first place",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the words and phrases.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative; '>
          <img style="width:23cm; height:30cm" src='img/FriendsPlus/Page67/E7/1.jpg' />
          
          <div style='position: absolute; top: 32px; left: 73px; font-size: 21px; line-height: 50px; width: 19cm'>
          If multilingual devices were widely used in daily 
          life, would we need to learn English?<br>
          The need to communicate worldwide <sup><b>1</b></sup>#
          popularising the wide use of multilingual devices. 
          However, I am definitely <sup><b>2</b></sup># learning 
          English because human communication cannot 
          be carried out by technology alone. Moreover, 
          English is currently widely spoken and is one 
          of the key languages of international business, 
          scientific research, etc.<br>
          Learning a foreign language including English 
          has many benefits. <sup><b>3</b></sup>#, it improves 
          intelligence and memory, lowers the risk of brain 
          aging and Alzheimer's disease. <sup><b>4</b></sup>#, it 
          develops soft skills such as problem-solving 
          skill. <sup><b>5</b></sup>#, knowing English has practical 
          advantages in various situations. In fact, 
          multilingual devices can translate accurately in 
          terms of grammar, vocabulary but they cannot 
          understand cultural contexts to know the real 
          meaning of words in a certain context. In business 
          settings, it can build stronger relationships when 
          discussions are carried out through the same 
          language without the use of a multilingual device.<br>
          In summary, <sup><b>6</b></sup># that multilingual 
          devices can make communication easier, but 
          learning English still brings about great benefits 
          in terms of cognitive development and practical applications.
          </div>
        </div>
        
        `,
        answer: [
          "suggests",
          "in favour of",
          "In the first place",
          "Furthermore",
          "Finally",
          "I believe",
        ],
      },
    ],
  },
};

export default json;
