import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P22-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page22/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P22-E1",
    exerciseKey: "img/FriendsPlus/Page22/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 250,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the leaflet below and match the phrases in <span style='color:#5cc9dd'>blue</span> with 
        definitions (1–8).`,
        color: "#F78024",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px'>
	        <img src="img/FriendsPlus/Page22/E1/1.jpg" style='height:18cm; width: 25cm; margin-left: -20px '/>
          
          <div style="margin-top: 10px">
          <div>benefit from &ensp; <span style='margin-left: 30px; color: gray'><i>get a lot out of</i></span></div>
          <div style='display: flex; margin-top: 30px'>
            <div>
              <div><b>1</b>&ensp;become accustomed to<br>#</div>
              <div><b>2</b>&ensp;contact<br>#</div>
              <div><b>3</b>&ensp;be part of<br>#</div>
              <div><b>4</b>&ensp;prepare<br>#</div>
              
            </div>
            <div style='margin-left: 100px'>
              <div><b>5</b>&ensp;become interested in<br>#</div>
              <div><b>6</b>&ensp;have the opportunity<br>#</div>
              <div><b>7</b>&ensp;learn more about someone<br>#</div>
              <div><b>8</b>&ensp;meet in order to do something<br>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        answer: [
          "get used to",
          "get in touch with",
          "get involved with",
          "get ready",
          "get into",
          "get the chance",
          "get to know",
          "get together with",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P22-E2",
    exerciseKey: "img/FriendsPlus/Page22/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 240,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "get into",
          "getting ready",
          "getting used to",
          "got the chance to",
          "got to know",
        ],
        width: 840,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the phrases in the box.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27.5cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I listen to music when I’m # for school in the morning.</div>
         <div>Last year, I # do work experience in my dad’s company. It was great!</div>
         <div>I # my friends really well when we went camping together.</div>
         <div>I’m # walking to school, instead of taking the bus.</div>
         <div>How did you # karate?</div>
        </div>
        </div>
        
        `,
        answer: [
          "getting ready",
          "got the chance to",
          "got to know",
          "getting used to",
          "get into",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P22-E3",
    audio: "Audios/1.12.mp3",
    exerciseKey: "img/FriendsPlus/Page22/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.12 src='Audios/1.12 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to four students talking to their 
        Transition Year coordinator. Who has had positive experiences so far?`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 3,
        listWords: [
          "1&ensp;_Joey<br>  2&ensp;_Rita<br>  3&ensp;_Martin_and_Laura",
        ],
        answers: ["0-0", "0-4", "0-8"],
        initialValue: [],
      },
      Layout: `
      
       <div style='width: 20cm; line-height: 44px; margin-left: 100px; font-size: 23px; margin-bottom: 200px'>
       <div><input id='0' type='Circle' /></div>  
       
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P22-E4",
    exerciseKey: "img/FriendsPlus/Page22/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/1.12.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 100,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.12 src='Audios/1.12 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Study sentences 1–8. Then listen again and write <i>true</i> or <i>false</i>.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; height: 600px; font-size: 23px'>
          
          <div style="display: flex"><b>1</b> &ensp;Joey hasn’t really enjoyed Transition Year yet.&ensp; <select id=0></select></div>
          <div style="display: flex"><b>2</b> &ensp;Joey got a lot out of the outdoor activities.&ensp; <select id=1></select></div>
          <div style="display: flex"><b>3</b> &ensp;Rita has already done two weeks of work experience. &ensp; <select id=2></select></div>  
          <div style="display: flex"><b>4</b> &ensp;She benefited from her time with the dentist.&ensp; <select id=3></select></div>
          
          <div style="display: flex"><b>5</b> &ensp;She has just decided her fifth year subjects. &ensp; <select id=4></select></div>
          <div style="display: flex"><b>6</b> &ensp;Martin and Laura still haven’t been to India.&ensp; <select id=5></select></div>
          <div style="display: flex"><b>7</b> &ensp;They’ve already raised enough money to fly to India.&ensp; <select id=6></select></div>
          <div style="display: flex"><b>8</b> &ensp;Both Martin and Laura have decided their fifth year 
          subjects.&ensp; <select id=7></select></div>
     
        </div>
        `,
        InputRong: true,
        answer: [
          "false",
          "true",
          "false",
          "true",
          "false",
          "true",

          "false",
          "false",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P22-E4",
    // exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/9.jpg' /> 
        Imagine that you are proposing a 
        Transition Year for your school. Read the aims in the 
        TY leaflet. Choose three things that you think are most 
        interesting and important. Explain your choices to a partner.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page22/E1/1.jpg" style='height:18cm; width: 25cm; margin-left: -20px '/>
        <div style='margin-left: 50px'><textarea id='0' rows='8'></div>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
