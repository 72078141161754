import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P73-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page73/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Study the examples a–d. Then choose the 
          correct options to complete the rules.`,
        color: "#5cc9dd",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["adjective / adverb", "adjective / noun", "adverb / noun"],
        answers: ["0-0", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style='width:26cm'>
        <b>a</b>&ensp;London is such an interesting destination with lots of sports events that as a sports fan, there's no reason to get bored. <br>
        <b>b</b>&ensp;The stadium has such a powerful sound system that the music can be heard clearly from every corner.<br>
        <b>c</b>&ensp;It's so popular that you can see some of the most talented skaters.<br>
        <b>d</b>&ensp;The grass on Wimbledon's Centre Court is maintained so carefully that it's often considered the best grass court in the world.<br>
      </div>
      <div>
      <div style='position: relative;'>
              <img src="img/FriendsPlus/Page73/E1/1.jpg" style='height:63mm'/>
              <div style=" position: absolute; top: 90px; left: 35px;line-height:45px ">
                <b>1</b>&ensp;such + (a / an) + <input id='0' type='Circle'/> + noun + that<br>
                <b>2</b>&ensp;so + <input id='1' type='Circle'/> + that<br>
                <b>3</b>&ensp;so + <input id='2' type='Circle'/> + that<br>
              </div>   
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P73-E2",
    exerciseKey: "img/FriendsPlus/Page73/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 100,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the text using <i>so</i> or <i>such</i>.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:22cm;background:rgb(254,252,204);padding:5px 20px;box-shadow:3px 2px 5px gray; font-size: 23px; line-height: 50px'>
          Huế is a historically rich city located in 
          central Việt Nam. The Imperial City in Huế is 
          preserved <sup>1</sup># well that it's a UNESCO 
          World Heritage Site and one of the most 
          popular tourist destinations in Việt Nam. The 
          Perfume River in Huế is <sup>2</sup># a beautiful 
          and romantic river that it becomes the deep 
          inspiration to numerous artists, poets, and 
          composers. The cuisine in Huế is <sup>3</sup>#
          unique that it's known for its imperial cuisine, 
          which was once only served to the royal 
          family but is now available in some of the 
          best restaurants in the city. The city has <sup>4</sup>#
          charming streets and traditional architecture 
          that Huế has become a must-visit city in 
          Việt Nam for history and culture lovers. 
        </div>
      
        
        `,
        answer: ["so", "such", "so", "such"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P73-E3",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    // maxLength: 5,
    hideBtnFooter: true,
    recorder: true,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<prefix></prefix> Work in pairs. Ask and answer 
          questions about your city using <i>so ... that</i> and
          <i>such ... that</i>. Use some suggestions in the box to 
          help you. `,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <img style='height:7cm;margin-left:100px' src="img/FriendsPlus/Page73/E3/1.jpg"/>
      
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
