import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  6: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P50-E1",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "55%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page50/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P50-E1",
    exerciseKey: "img/FriendsPlus/Page50/Key/E1answerKey.png",
    // audio: "Audios/1.21.mp3",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted gray",
      fontSize: 19,
      height: 27,
      width: 140,
      paddingTop: 10,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 19,
      // height: 70,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "capsule",
          "drug",
          "electricity",
          "headaches",
          "life",
          "microchip",
          "radar",
          "species",
        ],
        width: 860,
        borderColor: "#203c8f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the gaps in the stories about 
        discoveries 1–8 with the words in the box. 
        Which sentence do you think is false?`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative; font-size: 19px; line-height: 27px; margin-left: 20px'>
	        <img src="img/FriendsPlus/Page50/E1/1.jpg" style='height:36cm; width: 29cm; margin-left: -65px; '/>
          <div style='position: absolute; top: 28px; left: 230px; width: 4cm'>
            People <span style='color: rgb(54,166,215)'>invented</span>
            the wheel more than 5,000 years ago. Recently, in a survey of the most important inventions of
            all time, people chose the wheel, the internet and <input id=0 />.
          </div>

          <div style='position: absolute; top: 573px; left: 165px; width: 6cm'>
            Before Robert Watson-Watt <span style='color: rgb(54,166,215)'>designed</span> a modern <input id=1 /> system, 
            people had more primitive methods for detecting an enemy.
          </div>

          <div style='position: absolute; top: 779px; left: 100px; width: 10.4cm'>
            John Pemberton was a pharmacist 
            who hoped to <span style='color: rgb(54,166,215)'>cure</span> <input id=2 /> 
            with a mixture of coca leaves and cola nuts. An assistant accidentally 
            added water, <span style='color: rgb(54,166,215)'>creating</span> a drink 
            which later became Coca-Cola.
          </div>

          <div style='position: absolute; top: 38px; left: 871px; width: 4cm'>
            We have been 
            <span style='color: rgb(54,166,215)'>exploring</span> space 
            since the 1960s. Nowadays, scientists are researching how 
            we can <span style='color: rgb(54,166,215)'>adapt</span> to <input id=3 /> in space in the future.
          </div>

          <div style='position: absolute; top: 322px; left: 718px; width: 5.5cm'>
            In 1865, Jules Verne wrote <i>From the Earth to the Moon</i>. In this 
            story, he <span style='color: rgb(54,166,215)'>imagined</span>
            astronauts travelling from Florida to the moon in 
            an aluminium <input id=4 />. In 1969, that really happened.
          </div>

          <div style='position: absolute; top: 946px; left: 86px; width: 9cm'>
            People sometimes <span style='color: rgb(54,166,215)'>experiment</span> with their own 
            bodies. Professor Kevin Warwick implanted a <input id=5 /> into his arm and into his wife’s 
            arm. Now, when she feels something, he feels it too.
          </div>

          <div style='position: absolute; top: 885px; left: 556px; width: 9.5cm'>
            When researchers <span style='color: rgb(54,166,215)'>develop</span> a new <input id=6 />, they often use animals 
            to <span style='color: rgb(54,166,215)'>test</span> it, but they also pay people - 
            'human guinea pigs'. These people stay in 
            comfortable clinics and receive good care while they do this risky job.
          </div>

          <div style='position: absolute; top: 1074px; left: 549px; width: 9cm'>
            Scientists identify approximately 18,000 new <input id=7 /> every year. In 2015, 
            they <span style='color: rgb(54,166,215)'>discovered</span> this beautiful sea creature 
            near Japan. They also found the fossil of a 
            unicorn, which has <span style='color: rgb(54,166,215)'>become extinct</span>.
          </div>

          <div style='margin-top: 20px'>
            <div>Which sentence do you think is false?</div>
            
            <textarea id=8 rows='2'> .
          </div>
            
          </div>  
        </div> 
        `,
        // InputRong: true,
        answer: [
          "electricity",
          "radar",
          "headaches",
          "life",
          "capsule",
          "microchip",
          "drug",
          "species",
          "They also found the fossil of a unicorn, which has become extinct.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P50-E2",
    exerciseKey: "img/FriendsPlus/Page50/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 190,
      paddingTop: 10,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "<s>common</s>",
          "damaged",
          "fashionable",
          "fragile",
          "rare",
          "tough",
          "undamaged",
          "unpopular",
          "useful",
          "valuable",
        ],
        width: 880,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Study the words in <span style='color: rgb(54,166,215)'>blue</span> 
        in the stories about discoveries. Copy and complete the table with 
        the base form of each verb and a noun for each of the verbs.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page50/E2/1.png" style='height:34cm; width: 26cm '/>  
        <div style='position: relative; font-size: 22px; line-height: 55px; ; margin-left: 100px'>
	      
        <img src="img/FriendsPlus/Page50/E2/2.jpg" style='height:20cm; width: 15cm '/>
          <div style="position: absolute; top: 66px; left: 57px; text-align: center">
          <div style='display: flex'>
            <div style='margin-right: 19px'>
            <div style='font-weight: 600; color: gray; text-align: center'>invent</i></div>
            <div style='font-weight: 600; color: gray; text-align: center'>design</i></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              
            </div>
            <div style='margin-left: 40px'>
              <div style='font-weight: 600; color: gray; text-align: center'>invention</i></div>
              <div style='font-weight: 600; color: gray; text-align: center'>design</i></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>

              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: [
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",
          "cure | create | explore | adapt | imagine | experiment | develop | test | discover | become extinct",

          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
          "cure | creation | exploration | adaptation | imagination | experiment | development | test | discovery | extinction",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P50-E3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E3answerKey.png",
    audio: "Audios/1.32.mp3",
    component: T6,
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    giaiThua: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.32 src='Audios/1.32 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen to four people talking about inventions. Write down the 
        inventions or discoveries they mention.`,
        color: "#203c8f",
        left: 50,
        width: "25cm",
      },
    ],
    character: ",",
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>Answer:</b>&ensp;</div>
        <div><i style='font-size:18px; color: rgb(245,54,92)'>*Write the words you listen and between words seperated by commas (,).</i></div>
        <textarea id='0' rows='3'></div>
        
        
      </div>
        
        `,
        answer: [
          "smartphone, bicycle, social media, penicillin, cures or vaccines for illnesses, dishwasher",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P50-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/7.jpg'/>
        Think of answers to questions 1–3. Then compare your ideas with a partner. Use the key phrases.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 130px' >
        <img style="width:14cm; height:7cm; margin-left: 30px" src='img/FriendsPlus/Page50/E4/1.jpg' /><br>
        </div>
        <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>1</b>&ensp;What do you think is the best and the worst discovery or invention?</div>
        <textarea id='0' rows='3'></div>
        <div><div><b>2</b>&ensp;Which three discoveries or inventions are
        most useful for school?</div>
        <textarea id='1' rows='3'></div>
        <div><div><b>3</b>&ensp;What invention do we need in the future?</div>
        <textarea id='2' rows='3'></div>
        
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P50-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Think of an invention. Write what it does 
        and how it works. Read your description to 
        a partner for him / her to guess.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],

    questions: [
      {
        title: `
        <textarea id='0' rows='10'>
        
        `,
        answer: [""],
      },
    ],
  },
};
export default json;
