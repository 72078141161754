import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P47-E5",
    exerciseKey: "img/FriendsPlus/Page47/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct options.`,
        color: "#4F3C94",
        left: -30,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "she's_going_to_get / she'll_get / she_gets",
          "will_leave / leaves / leave",
          "I_pay / I'll_pay / I'm_paying",
          "I'm_visiting / I_visit / I'll_visit",
          "aren't_going / don't_go / won't_go",
          "do_you_buy / are_you_buy / are_you_going_to_buy",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-8", "5-8"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; width:25cm; font-size: 23px'>
        <b>1</b>&ensp;Nina has saved some money and now
        <input id='0' type='Circle'/> that 
        new smartphone.<br>
        <b>2</b>&ensp;We need to get up early tomorrow. Our train 
        <input id='1' type='Circle'/> at 7.30.<br>
        <b>3</b>&ensp;‘I’ve forgotten my purse!’  ‘Don’t worry. <input id='2' type='Circle'/> for your ticket.’<br>
        <b>4</b>&ensp;‘Can you meet me at the shopping centre at 
        7.00?’  ‘No, sorry. <input id='3' type='Circle'/>
        my grandparents this evening.’<br>
        <b>5</b>&ensp;In the future, people <input id='4' type='Circle'/> to the shops any longer.<br>
        <b>6</b>&ensp;‘I want to buy some trainers next week.’ <br>
        ‘Which brand <input id='5' type='Circle'/>?’<br>
      </div>
      `,
    },
  },
  6: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P47-E6",
    exerciseKey: "img/FriendsPlus/Page47/Key/E6answerKey.png",
    component: T6,
    // audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 70,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 750,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete Ruth’s presentation with phrases a–h.`,
        color: "#4F3C94",
        left: 100,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; margin-left: 130px'>
        <img src='img/FriendsPlus/Page47/E6/1.jpg' alt='' style='height:5.5cm; width: 14cm; margin-left: 100px'/>
        <img src='img/FriendsPlus/Page47/E6/2.jpg' alt='' style='height:15cm; width: 20cm'/>
        
        <div style='position: absolute; top: 226px; left: 47px; line-height: 43px; width: 17cm'>
          Hi. I’m Ruth, and today <sup><b>1</b></sup># new product. 
          It’s a special towel for sport or holidays.
          <sup><b>2</b></sup># showing you some of the different 
          features of this product. <sup><b>3</b></sup># much smaller 
          than normal towels. <sup><b>4</b></sup># see, I can easily 
          hold it in one hand. <sup><b>5</b></sup># mentioning that it’s 
          very light and it’s perfect for the beach or 
          the sports centre.<br>
          <sup><b>6</b></sup># that it dries more quickly than a 
          traditional towel. It feels dry after just thirty 
          minutes. <br>
          <sup><b>7</b></sup># the towel is that it comes in twelve 
          different colours. So you’ll definitely find 
          a colour that you like. <sup><b>8</b></sup># agree that the 
          product is convenient and practical. It’s in 
          the shops now, so don’t miss out!
        </div>

        </div>
        `,
        answer: ["e", "h", "d", "b", "g", "a", "c", "f"],
      },
    ],
  },
  7: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P47-E7",
    exerciseKey: "img/FriendsPlus/Page47/Key/E7answerKey.png",
    component: T6,
    // audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 230,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "To achieve",
          "as well as",
          "much consideration",
          "positive impact",
          "great potential",
          "competitive",
          "I'm confident that",
          "stay in touch",
        ],
        width: 880,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the words and phrases.`,
        color: "#4F3C94",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position: relative; font-size: 21px'>
        <img src='img/FriendsPlus/Page47/E6/2.jpg' alt='' style='height:27cm; width: 26cm'/>

        <div style='position: absolute; top: 20px; left: 52px; line-height: 43px; width: 17cm'>
        <div>
        <div style='width: 23cm; display: flex; align-items: flex-end; flex-direction: column'>
          <div>380/8A Trần Phú Street</div>
          <div>Hồ Chí Minh City</div>
          <div>26<sup>th</sup> May, 20…</div>
        </div>
        <div style='width: 23cm; '>
          Dear Amy,<br>
        </div>
        <div style='width: 23cm; '>
        It has been for a while since we last met in 
        Hà Nội. How is everything? I hope this letter 
        finds you in good health and high spirits.<br>
        </div>
        <div style='width: 23cm; '>
          I'm so glad to share some exciting news with 
          you. After <sup><b>1</b></sup># , I decided to pursue 
          a career in AI. As you know, AI is a rapidly 
          growing field with <sup><b>2</b></sup># for both 
          innovation and impact. I'm really into in how 
          AI can be used to solve complex problems and 
          improve people's lives. <sup><b>3</b></sup># this goal, I'll 
          try to work hard to develop my skills 
          <sup><b>4</b></sup># my knowledge in computer science. 
          I'm planning to enrol at a renowned university 
          close to my home to obtain a master degree in 
          computer science and enhance my knowledge 
          and expertise. I know this field is not only 
          <sup><b>5</b></sup># but also challenging; however, I'm 
          excited about the opportunities it offers. 
          <sup><b>6</b></sup># my passion and dedication will 
          allow me to succeed in this career and make a 
          <sup><b>7</b></sup># on the world.
        </div>
        <div style='width: 23cm; '>
          I'd love to hear about your own future career 
          and any exciting plans you have for the future. 
          As always, it's great to <sup><b>8</b></sup># with you.<br>
        </div>
        <div style='width: 23cm; '>
        Hope to hear from you soon.<br>
        </div>
        <div style='width: 23cm; '>
          With love,<br>
          Uyên<br>
        </div>
      </div>

        </div>
        `,
        answer: [
          "much consideration",
          "great potential",
          "To achieve",
          "as well as",
          "competitive",
          "I am confident that",
          "positive impact",
          "stay in touch",
        ],
      },
    ],
  },
};

export default json;
