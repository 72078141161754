import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P14-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page34/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P34-E1",
    exerciseKey: "img/FriendsPlus/Page34/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    // stylesTextInput: { width: 170, paddingTop: 10 },

    hintBox: [
      {
        src: [
          "deliver",
          "exchange",
          "fit",
          "labels",
          "scans",
          "special offers",
          "try on",
          "window shopping",
        ],
        width: 830,
        borderColor: "#F78024",
        marginLeft: 0,
        color: "rgb(54,166,215)",
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the words in the 
        box. Which of the words in <span style=' color: #5cc9dd'>blue</span> are nouns?`,
        color: "#F78024",
        // left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          background: "none",
          // borderBottom: "2px dotted",
          fontSize: 22,
          height: 27,
          // textTransform: "uppercase",
          textAlign: "center",
          width: 230,
        },
        answers: [
          "exchange",
          "special offers",
          "deliver",
          "fit",
          "try on",
          "window shopping",
          "scans",
          "labels",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          color: "#00aeef",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          // color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
          color: "#00aeef",
        },
        limitSelect: 8,
        listWords: [
          "deliver &ensp; exchange &ensp; fit &ensp; labels &ensp; scans &ensp; special_offers &ensp; try_on &ensp; window_shopping",

          "return &ensp; receipt &ensp; changing_room &ensp; checkout &ensp; shop_assistant &ensp; bar_codes &ensp; shelves", //2
        ],
        answers: [
          "2-4",
          "4-4",
          "5-0",
          "6-4",
          "7-4",
          "3-4",
          "1-8",
          "1-20",
          "1-12",
          "0-12",
          "1-4",
          "1-24",
          "1-16",
          "0-20",
          "0-28",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 27cm; line-height: 50px; font-size: 22px'>
      <hintbox id='0'></hintbox>
        <div style='display: flex; font-size: 22px; line-height: 45px'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br><br>
          <b>7</b>&ensp;<br><br>
          <b>8</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>You can <span style='color: rgb(54,166,215)'>return</span> or <input id='0'/> a product if 
         you’ve got the <span style='color: rgb(54,166,215)'>receipt</span>.</div>
         <div>When there are <input id='1'/>, products are cheaper than usual.</div>
         <div>Shops normally <input id='2'/> big or heavy items.</div>
         <div>When your clothes are the right size, they <input id='3'/> you perfectly.</div>
         <div>A <span style='color: rgb(54,166,215)'>changing room</span> is a place where you can <input id='4'/> clothes.</div>
         <div>When you’re <input id='5'/>, you’re outside shops looking at the products in the windows.</div>
         <div>At the <span style='color: rgb(54,166,215)'>checkout</span>, a <span style='color: rgb(54,166,215)'>shop assistant</span> <input id='6'/> the 
         <span style='color: rgb(54,166,215)'>bar codes</span> on products to find out their prices.</div>
         <div>Shops must always show prices on <input id='7'/> or <span style='color: rgb(54,166,215)'>shelves</span>.</div>
        </div>
        </div> 

         <div>
         <div><b>Which of the words in <span style=' color: rgb(54,166,215)'>blue</span> are nouns?</b></div>
           <div style=""><input id='0' type='Circle' /></div>
    
           <div style=""><input id='1' type='Circle' /></div>
    
           
    
         </div>
       
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P34-E2",
    exerciseKey: "img/FriendsPlus/Page34/Key/E2answerKey.png",
    audio: "Audios/1.20.mp3",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 160,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "scan",
          "screen",
          "shopping",
          "fashion",
          "store",
          "assistant",
          "show",
          "shelf",
        ],
        width: 820,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.20 src='Audios/1.20 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        <span style='color: #F78024'>PRONUNCIATION: /s/ and /ʃ/</span> Listen and put the words into the correct row.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; font-size: 23px; line-height: 35px; margin-left: 120px'>
	        <img src="img/FriendsPlus/Page34/E2/1.jpg" style='height:14cm; width: 15cm '/>
          <div style="position: absolute; top: 95px; left: 62px; text-align: center; line-height: 50px">
          <div style='display: flex'>
            <div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 100px'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: [
          "scan | screen | store | assistant",
          "scan | screen | store | assistant",
          "scan | screen | store | assistant",
          "scan | screen | store | assistant",
          "",
          "",
          "",
          "",
          "shopping | fashion | show | shelf",
          "shopping | fashion | show | shelf",
          "shopping | fashion | show | shelf",
          "shopping | fashion | show | shelf",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P34-E3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E3answerKey.png",
    audio: "Audios/1.21.mp3",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 100,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "scan",
          "screen",
          "shopping",
          "fashion",
          "store",
          "assistant",
          "show",
          "shelf",
        ],
        width: 820,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.21 src='Audios/1.21 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Study the diagram of the future of 
        shopping. Then listen. In what order do you hear about topics A–F?`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px; line-height: 35px; margin-left: 20px'>
	        <img src="img/FriendsPlus/Page34/E3/1.jpg" style='height:17cm; width: 24cm '/>
          <div style=" text-align: center; line-height: 50px">
            <div style='display: flex; margin-left: 20px'>
            <div>
              <div><b>1</b> - #</div>
              <div><b>2</b> - #</div>
              <div><b>3</b> - #</div>
            </div>
            <div style='margin-left: 100px'>
              <div><b>4</b> - #</div>
              <div><b>5</b> - #</div>
              <div><b>6</b> - #</div>
            </div>
              
            </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: ["E", "B", "A", "C", "F", "D"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P34-E4",
    exerciseKey: "img/FriendsPlus/Page34/Key/E4answerKey.png",
    audio: "Audios/1.21.mp3",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px solid gray",
      fontSize: 20,
      height: 27,
      width: 200,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    textareaStyle: {
      // background: 'none',
      width: 200,
      fontSize: 20,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.21 src='Audios/1.21 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen again and complete the notes in A–F.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 30px; margin-left: 20px'>
	        <img src="img/FriendsPlus/Page34/E4/1.jpg" style='height:27cm; width: 27cm '/>
          <div style='position: absolute; top: 85px; left: 40px; width: 7cm'>
            Scanners measure you. The screen 
            will show you how you’ll look in 
            different clothes. To change the 
            colour or size, # .
          </div>
          <div style='position: absolute; top: 85px; left: 407px; width: 6cm'>
          There won’t be human shop 
          assistants, but these robots will 
          fill shelves and # .
          </div>
          <div style='position: absolute; top: 128px; left: 724px; width: 7cm'>
          Shops will have drones which deliver products in #.
          </div>
          <div style='position: absolute; top: 780px; left: 37px; width: 8cm'>
          Use an app here to scan # of products. Your shopping will be at your house before you arrive.
          </div>
          <div style='position: absolute; top: 811px; left: 395px; width: 10cm'>
          Cameras will detect your age, sex and the style 
          of clothes #. The # in the window will change to suit you.
          </div>
          <div style='position: absolute; top: 711px; left: 792px; width: 6cm'>
            No need for cards or 
            cash. Your phone will 
            link by Wi-Fi to the 
            checkout robot. You’ll 
            receive messages <textarea id='6' rows='2'> .
          </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: [
          "touch the screen",
          "take your shopping to the checkouts",
          "less than an hour",
          "the bar codes",
          "you are wearing",
          "advertisements",
          "when there are special offers",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P14-E5",
    // exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/Page14/E5/1.jpg' /> 
        Write five sentences 
        to describe your perfect shop. Then compare your sentences with a partner's. Choose the best ideas.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='color: gray'>It delivers the things you order on the same day.</div>
        <textarea id='0' rows='10'>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
