import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P66-E1",
    exerciseKey: "img/FriendsPlus/Page66/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with one of the words from each pair.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>Scientists now think that there is water on Mars. That’s an amazing #.</div>

         <div>Ava developed some great ideas for our design project. She has a fantastic #.</div>

         <div>Scientists want to # the Amazon and 
         look for new types of animals and plants.</div>

         <div>Hezârfen Ahmed Çelebi created a new # in the 17th century – it was a set of 
        special ‘bird wings’ that he could fly with.</div>

         <div>Could you # to living in space?</div>

         <div>The two governments want to work together 
         and # a space programme.</div>
        </div>
        </div>
        
        `,
        answer: [
          "discovery",
          "imagination",
          "explore",
          "invention",
          "adapt",
          "create",
        ],
      },
    ],
  },
  2: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P66-E2",
    exerciseKey: "img/FriendsPlus/Page66/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the noun form of the words in brackets.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>The # of space has led to numerous scientific breakthroughs. (explore)</div>

         <div>The # of technology has significantly 
         impacted our daily lives. (develop)</div>

         <div>The # in digital technology have improved the way we perform daily tasks such 
         as navigating traffic. (advance)</div>

         <div>Alexander Graham Bell's # of the telephone led to modern telecommunications technology. (invent)</div>

         <div>Effective # plays an important role in building strong relationships. (communicate)</div>

        </div>
        </div>
        
        `,
        answer: [
          "exploration",
          "development",
          "advancement",
          "invention",
          "communication",
        ],
      },
    ],
  },
  3: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P66-E3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["come", "design", "enter", "learn", "leave", "swim", "watch"],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the dialogue with the gerund or infinitive form of the verbs in the box.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>Molly</b>&ensp;<br><br>
          <b>Anna</b>&ensp;<br>
          <b>Molly</b>&ensp;<br>
          <b>Anna</b>&ensp;<br>
          <b>Molly</b>&ensp;<br>
          <b>Anna</b>&ensp;<br><br>
          <b>Molly</b>&ensp;<br>
          
        </div>

        <div style='width: 26cm'>
         <div>Hi! Are you interested in <sup><b>1</b></sup># 
         to the beach later? We’re planning 
         <sup><b>2</b></sup># from here at 4.00.</div>

         <div>Well, I love <sup><b>3</b></sup># in the sea, but I 
         can’t come. I have design club after school.</div>

         <div>Design club? What’s that?</div>

         <div>It’s for <sup><b>4</b></sup># about invention and 
         design.</div>

         <div><sup><b>5</b></sup># new things sounds fun!</div>

         <div>Yes, why don’t you join? We’ve created a 
         flying robot and we’re going <sup><b>6</b></sup>#
         it for a competition. </div>

         <div>Perhaps I’ll come <sup><b>7</b></sup># next week.</div>
        </div>
        </div>
        
        `,
        answer: [
          "coming",
          "to leave",
          "swimming",
          "learning",
          "Designing",
          "to enter",
          "to watch",
        ],
      },
    ],
  },
  4: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P66-E4",
    exerciseKey: "img/FriendsPlus/Page66/Key/E4answerKey.png",
    audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["come", "design", "enter", "learn", "leave", "swim", "watch"],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.08 src='Audios/2.08 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to the radio programme about spacewalks and complete the sentences.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style='width: 26cm'>
         <div>Most people think that # are the 
         biggest threat on a spacewalk.</div>

         <div>Parmitano was doing a spacewalk from the 
         International Space Station # kilometres above #.</div>

         <div>The problem in Parmitano’s helmet was made 
         worse because of the zero #.</div>

         <div>There haven’t been any deaths on spacewalks 
         since they first started in #.</div>

         <div>People can sometimes see # on a 
         spacewalk. It isn’t always dark.</div>

        </div>
        </div>
        
        `,
        answer: ["meteors", "400", "Earth", "gravity", "1965", "the sun"],
      },
    ],
  },
};

export default json;
