import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P42-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page42/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P42-E1",
    exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.26.mp3",
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 250,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the blog and match six of the words in 
        <span style='color: #5cc9dd'>blue</span> with definitions 1–6.`,
        color: "#F78024",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px'>
	        <img src="img/FriendsPlus/Page42/E1/1.jpg" style='height:18cm; width: 25cm; margin-left: -20px '/>
          
          <div style="">
          <div style='display: flex; margin-top: 10px; margin-left: 100px'>
            <div>
              <div><b>1</b>&ensp;go faster<br>#</div>
              <div><b>2</b>&ensp;make louder<br>#</div>
              <div><b>3</b>&ensp;become happier<br>#</div>
              
            </div>
            <div style='margin-left: 100px'>
              <div><b>4</b>&ensp;make more<br>#</div>
              <div><b>5</b>&ensp;become more relaxed<br>#</div>
              <div><b>6</b>&ensp;prevent concentration<br>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        answer: [
          "speed up",
          "turn up",
          "cheer up",
          "increase",
          "calm down",
          "distract",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P42-E2",
    audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 140,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    // hintBox: [
    //   {
    //     src: [
    //       "<s>common</s>",
    //       "damaged",
    //       "fashionable",
    //       "fragile",
    //       "rare",
    //       "tough",
    //       "undamaged",
    //       "unpopular",
    //       "useful",
    //       "valuable",
    //     ],
    //     width: 880,
    //     borderColor: "#F78024",
    //     marginLeft: 0,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.26 src='Audios/1.26 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen to situations 1–4, and for each 
        one choose a word from box A and a word 
        from box B to identify the speakers’ mood and purpose.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; margin-left: 80px'>
          <img src="img/FriendsPlus/Page42/E2/2.jpg" style='height:4.5cm; width: 19cm '/>
	        <img src="img/FriendsPlus/Page42/E2/1.jpg" style='height:11cm; width: 24cm '/>
          <div style="position: absolute; font-weight: 600; top: 203px; left:205px">Situations 1</div>
          <div style="position: absolute; font-weight: 600; top: 203px; left:377px">Situations 2</div>
          <div style="position: absolute; font-weight: 600; top: 203px; left:553px">Situations 3</div>
          <div style="position: absolute; font-weight: 600; top: 203px; left:730px">Situations 4</div>
          
          <div style="position: absolute; font-weight: 600; top: 293px; left:48px; width: 3cm">Speakers’ mood</div>
          <div style="position: absolute; font-weight: 600; top: 440px; left:48px; width: 3cm">Speakers’ purpose</div>
          
          <div style="position: absolute; top: 281px; left: 194px; text-align: center; line-height: 134px">
          <div style='display: flex'>
            <div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 26px'>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 25px'>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 31px'>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: [
          "energetic",
          "instructions",
          "angry",
          "argument",
          "patient",
          "explanation",
          "happy",
          "discussion",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P42-E3",
    audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page42/Key/E3answerKey.png",
    // audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.26 src='Audios/1.26 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen again and choose the correct answers.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["1-8", "0-0", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `

      <div style='position:relative; line-height:43.4px; height: 500px; width: 26cm; margin-left: 50px; font-size: 23px'>
        
        <div style='position:relative; margin-top: 80px; margin-left: -16px; z-index: 999;'>
          <div style='display:flex;justify-content:space-around;width:20cm; height: 1.1cm'><input id='0' type='Circle'/></div><br>
          <div style='display:flex;justify-content:space-around;width:20cm; height: 1.2cm'><input id='1' type='Circle'/></div><br>
          <div style='display:flex;justify-content:space-around;width:20cm; height: 1.2cm'><input id='2' type='Circle'/></div><br>
          <div style='display:flex;justify-content:space-around;width:20cm; height: 1.1cm'><input id='3' type='Circle'/></div><br>
        </div>
        <div style='position:absolute; top:-42px; left:0px; font-weight:bold;'>
          <div><b>1</b>&ensp; The teacher wants Kevin to … .</div><br>
          <div><b>2</b>&ensp; The man wants the young man to … .</div><br>
          <div><b>3</b>&ensp; The dentist says endorphins can help to … pain.</div><br>
          <div><b>4</b>&ensp; The mother wants her son to … .</div><br>
         
        </div>
        
        <div style='position:absolute; top:2px;left:65px'>
          <div>slow down</div><br>
          <div>turn down his music</div><br>
          <div>reduce</div><br>
          <div>practise less</div><br>
        </div>
        <div style='position:absolute; top:2px;left:390px'>
          <div>speed up</div><br>
          <div>relax</div><br>
          <div>increase</div><br>
          <div>practise more</div><br>
        </div>
        <div style='position:absolute; top:2px; left:711px'>
          <div>stop</div><br>
          <div>take off his headphones</div><br>
          <div>stop</div><br>
          <div>stop practising</div>
        </div>
      </div>

      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P42-E4",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 21,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/9.jpg' /> 
        Work in pairs. Ask and answer the music questions.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 0px; line-height: 48px'>
        <div><div><b>1</b>&ensp;What music do you put on to relax or calm down?</div>
        <textarea id='0' rows='2'></div>
        <div><div><b>2</b>&ensp;Does music distract you or help you to 
        concentrate when you study?</div>
        <textarea id='1' rows='2'></div>
        <div><div><b>3</b>&ensp;Do your parents ever ask you to turn your 
        music down or to turn it off?</div>
        <textarea id='2' rows='2'></div>
        <div><div><b>4</b>&ensp;What songs do you sing along to? Do you 
        turn up the volume when you hear them?</div>
        <textarea id='3' rows='2'></div>
        
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
