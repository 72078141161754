import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  7: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page20/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E1",
    // exerciseKey: "img/FriendsPlus/Page20/Key/E1answerKey.png",
    // audio: "Audios/1.05.mp3",
    component: T6,
    textAlign: "left",
    // maxLength: 1,
    stylesTextInput: { width: 500, paddingTop: 10 },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Look at the title, headings and photos in the 
        article on this page. What is different about 
        these people’s lifestyles? Why do you think 
        they decided to live this way?`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
	        <img src="img/FriendsPlus/Page20/E1/1.jpg" style='height:26cm; width: 26cm '/>
          <div style="margin-left: 20px; margin-top: 20px; font-size: 22px">
            <div><b>1</b>&ensp;What is different about 
            these people’s lifestyles?<br>  <textarea id='0' rows='3'></div>
            <b>2</b>&ensp;Why do you think 
            they decided to live this way?<br> <textarea id='1' rows='3'><br>
            
          </div>   
        </div> 
        `,
        answer: [""],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E2",
    exerciseKey: "img/FriendsPlus/Page20/Key/E2answerKey.png",
    audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      width: 45,
      // height: 20,
      paddingTop: 15,
      background: "none",
      borderBottom: "dotted 2px",
      fontSize: 21,
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.11 src='Audios/1.11 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Read the article. Match two sentences from a–d with gaps 1–2 in the text. Then listen and check.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px'>
	        <img src="img/FriendsPlus/Page20/E1/1.jpg" style='height:26cm; width: 26cm '/>
          
          <div style='position: absolute; top: 850px; left: 21px'><img src="img/FriendsPlus/Page20/E2/1.jpg" style='height:4cm; width: 11cm '/></div>
          
          <div style='margin-left: 50px;display:flex; margin-top: 50px '>
              <b>Answer:&ensp;</b><br>
              &ensp;<b>1 - #&ensp;&ensp;&ensp;&ensp;</b>
              &ensp;<b>2 - #</b>
              
          </div>
        </div> 
        `,
        answer: ["d", "b"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E3answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 21,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px'>
	        <img src="img/FriendsPlus/Page20/E3/1.png" style='height:26cm; width: 27cm; margin-left: -50px '/>
          
          <div style='margin-left: 50px; margin-top: 10px '>
              <div>
                <div><b>1&ensp;</b>Why did Lauren change her lifestyle?</div>
                <textarea id='0' rows='2'>
              </div>
              <div>
                <div><b>2&ensp;</b>How does Lauren help other people live without waste?</div>
                <textarea id='1' rows='2'>
              </div>
              <div>
                <div><b>3&ensp;</b>What have Mark Boyle and Daniel Suelo got in common?</div>
                <textarea id='2' rows='2'>
              </div>
              <div>
                <div><b>4&ensp;</b>How are their lifestyles different?</div>
                <textarea id='3' rows='2'>
              </div>
          </div>
        </div> 
        `,
        answer: [
          "Because she was shocked that people are so wasteful.",
          "Her blog gives advice and ideas for people who want to reduce their impact on the environment.",
          "They both live without money.",
          "Daniel moves around to find food and shelter. Mark doesn't travel around. He lives in one place.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E4",
    exerciseKey: "img/FriendsPlus/Page20/Key/E4answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 21,
      // height: 100,
    },
    selectStyle: {
      height: 35,
      width: 105,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["-ful", "-less"],
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color: #D92F3B; font-weight: 900'>VOCABULARY PLUS</span> &ensp;Find words 1–4 in the 
        text. Which suffix do we use when something 
        has a quality? Which suffix indicates that 
        something lacks a quality?`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px'>
          <img src="img/FriendsPlus/Page20/E1/1.jpg" style='height:26cm; width: 26cm '/>
          <div style='margin-top: -100px; margin-left: 100px'>
          <div><b>1&ensp;</b>moneyless</div>
          <div><b>2&ensp;</b>useful</div>
          <div><b>3&ensp;</b>homeless</div>
          <div><b>4&ensp;</b>wasteful</div>
          </div>
          <div style='margin-left: 10px; margin-top: 30px; line-height: 45px '>
              <div>
                <div>Which suffix do we use when something has a quality?</div>
                <div style='display: flex'><select id=0></select> &ensp; indicates that something has a quality</div>
              </div>
              <div>
                <div> Which suffix indicates that something lacks a quality?</div>
                <div style='display: flex'><select id=1></select>&ensp; indicates that something lacks a quality</div>
              </div>
          </div>
        </div> 
        `,
        answer: ["-ful", "-less"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E5",
    exerciseKey: "img/FriendsPlus/Page20/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["care", "fear", "<s>harm</s>", "taste", "thought", "use"],
        width: 650,
        borderColor: "#D92F3B",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences. Use the words in the 
        box and the suffixes <i>-ful</i> or <i>-less</i>.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='font-size: 22px; line-height: 40px'>Wash hands after use. This product contains harmful chemicals.</div>
        <div style='display: flex; font-size: 22px; line-height: 50px'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style='width: 20cm'>
         <div>Be # when you’re cycling. This road is dangerous.</div>
         <div>Thanks for the beautiful present. That was very # of you.</div>
         <div>We need to add more salt to the pasta. It’s really #.</div>
         <div>This calculator is old and broken. I’m afraid it’s #.</div>
         <div>Jennie isn’t scared of anything. She’s totally #.</div>
        </div>
        </div>
        
        `,
        answer: ["careful", "thoughtful", "tasteless", "useless", "fearless"],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P20-E6",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 21,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/1.jpg' /> 
        Work in pairs. Ask and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>1</b>&ensp;What do you think of the people in the text?</div>
        <textarea id='0' rows='2'></div>
        <div><div><b>2</b>&ensp;Would you like to try any of their lifestyles? Why / Why not?</div>
        <textarea id='1' rows='2'></div>
        <div><div><b>3</b>&ensp;Do you think that we really need technology and money?</div>
        <textarea id='2' rows='2'></div>
        
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
