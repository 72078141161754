import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  12: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P89-E12",
    exerciseKey: "img/FriendsPlus/Page89/Key/E12answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "12",
        title: `Choose the correct options.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "I_was / was_I / am_I",
          "they_did_live / they_do_live / they_lived", //2
          "help / helping / to_help",
          "to_go / go / going",
          "don’t_to_move / not_to_move / not_move",
          "he_would_return / will_he_return / he_return",
        ],
        answers: ["0-0", "1-8", "2-8", "3-4", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; font-size: 23px; line-height: 50px'>

           <div style=""><b>1</b> &ensp;The man asked if <input id='0' type='Circle' /> all right. 
           </div>
    
           <div style=""><b>2</b> &ensp;The police officer asked where <input id='1' type='Circle' />.
           </div>

           <div style=""><b>3</b> &ensp;The shop manager came outside and offered 
           <input id='2' type='Circle' /> us.
           </div>

           <div style=""><b>4</b> &ensp;My friend suggested that we <input id='3' type='Circle' />
           home at once.
           </div>

           <div style=""><b>5</b> &ensp;The detective told the thief <input id='4' type='Circle' />.
           </div>

           <div style=""><b>6</b> &ensp;We wanted to know if he <input id='5' type='Circle' />.
           </div>
    
       </div>
      
      `,
    },
  },
  13: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P89-E13",
    exerciseKey: "img/FriendsPlus/Page89/Key/E13answerKey.png",
    // audio: "Audios/2.22.mp3",
    component: T6,
    // audio: "Audios/1.30.mp3",
    // inputSize: 500,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 350,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "It really hurts",
          "I think you should see a doctor",
          "I've hurt my leg",
          "Can you move it",
          "What's wrong",
          "No, I can't move it at all",
          "Let me see",
        ],
        width: 700,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "13",
        title: `Complete the dialogue with the key phrases. 
        Then practise with your partner.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; font-size: 21px; margin-top: 20px'>
          <div style='width: 28cm; line-height: 45px'>
          
          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Charlotte</div>
            <div style='width: 22.2cm'>Hi, Joe! <sup><b>1</b></sup>#? Are you OK?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Not really. <sup><b>2</b></sup>#.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Charlotte</div>
            <div style='width: 22.2cm'>Oh no! What happened?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>I was cycling down the hill when I 
            fell off my bike. It’s very painful.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Charlotte</div>
            <div style='width: 22.2cm'><sup><b>3</b></sup>#.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Ouch! Don’t touch. <sup><b>4</b></sup>#.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Charlotte</div>
            <div style='width: 22.2cm'>Your ankle is red <sup><b>5</b></sup>#?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'><sup><b>6</b></sup>#. It’s too painful. Do you think I’ve broken it?</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Charlotte</div>
            <div style='width: 22.2cm'>I don’t know. <sup><b>7</b></sup># I’ll call my 
            dad and he can take you to the hospital. He’s at home.</div>
          </div>

          <div style='display: flex'>
            <div style='width: 4cm; font-weight: 600; margin-right: 10px'>Joe</div>
            <div style='width: 22.2cm'>Thanks, Charlotte.</div>
          </div>

          </div>
        </div>
          
        </div>

        `,
        answer: [
          "What's wrong",
          "I've hurt my leg",
          "Let me see",
          "It really hurts",
          "Can you move it",
          "No, I can't move it at all",
          "I think you should see a doctor",
        ],
      },
    ],
  },
  14: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P89-E14",
    exerciseKey: "img/FriendsPlus/Page89/Key/E14answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "14",
        title: `Choose the correct words.`,
        color: "#4F3C94",
        left: -30,
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-8", "2-8", "3-4", "4-0", "5-4", "6-0", "7-8"],
        initialValue: [],
      },
      Layout: `

      <div style='width:25cm; font-size: 22px'>
      Last summer, I decided to take a road trip with 
      a group of friends to a popular beach town. 
      We were <sup><b>1</b></sup>.................................... about the adventure and 
      had planned the trip for weeks in advance. 
      However, the trip <sup><b>2</b></sup>.................................... out to be an 
      unpleasant experience. First, our car broke 
      down on the way. The heat was unbearable, 
      and we were in the middle of nowhere, with no 
      food or water <sup><b>3</b></sup>.................................... a mechanic arrived. 
      <sup><b>4</b></sup>.................................... his great efforts, he unsuccessfully 
      fixed the car. After having to rent another car, 
      we had to squeeze all our luggage in the small 
      trunk. We <sup><b>5</b></sup>.................................... arrived at the beach town, 
      but it was not what we had expected. The 
      beach was crowded and littered with rubbish, 
      and the water was polluted. We tried to make 
      the most <sup><b>6</b></sup>.................................... the situation by exploring 
      the town, but it was filled with tourist traps 
      and overpriced restaurants. <sup><b>7</b></sup>.................................... matters 
      worse, one of my friends fell ill and had to 
      be hospitalised. We had to cut our trip short 
      and return home early, disappointed and 
      exhausted. It was a frustrating and <sup><b>8</b></sup>....................................
      experience, and we learned the importance 
      of being prepared for unexpected challenges 
      during road trips.
      </div><br>
      <div style='position:relative; line-height:43.4px; font-size: 22px; margin-left: 100px'>
        
        <div style='position:relative'>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='5' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='6' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.15cm'><input id='7' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;'>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
         
        </div>
        
        <div style='position:absolute;top:0px;left:65px'>
          <div>excited</div>
          <div>became</div>
          <div>after</div>
          <div>Though</div>
          <div>finally</div>
          <div>in</div>
          <div>To make</div>
          <div>tire</div>
        </div>
        <div style='position:absolute;top:0px;left:320px'>
          <div>keen</div>
          <div>got</div>
          <div>while</div>
          <div>Despite</div>
          <div>next</div>
          <div>of</div>
          <div>Making</div>
          <div>tired</div>
        </div>
        <div style='position:absolute;top:0px;left:580px'>
          <div>fascinated</div>
          <div>turned</div>
          <div>until</div>
          <div>In spite</div>
          <div>firstly</div>
          <div>on</div>
          <div>Made</div>
          <div>tiring</div>
        </div>
      </div>

      `,
    },
  },
};

export default json;
