import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Study the examples and choose the correct 
        options to complete the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "can / could",
          "can / could", //2
          "be_able_to / could",
        ],
        answers: ["0-4", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
      <img style="width:16cm; height:3.1cm; margin-left: 100px" src='img/FriendsPlus/Page43/E1/1.jpg' />
       <img style="width:18cm; height:9cm; margin-left: 100px" src='img/FriendsPlus/Page43/E1/2.jpg' />
       <div style='display: flex; position: absolute; top: 196px; left: 137px; width: 17cm; line-height: 40px'>
         <div>
           <div style=""><b>1</b> &ensp;We use <input id='0' type='Circle' /> to express an ability in the past. 
           </div>
    
           <div style=""><b>2</b> &ensp;We use <input id='1' type='Circle' /> to express an ability in the present.
           </div>

           <div style=""><b>3</b> &ensp;We use <input id='2' type='Circle' /> to express an ability 
           in the future.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E2",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form 
        of <i>can, could</i> or <i>be able to</i>.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>Why don’t you turn up the music? Then we’ll # all sing along.</div>
         <div>I’m going to have singing lessons. Then I might # join a band.</div>
         <div>We # turn on the TV because it was broken.</div>
         <div>I really # exercise at the moment. I’m too tired.</div>
         <div># you speak English when you were three?</div>
        </div>
        </div>
        
        `,
        answer: ["be able to", "be able to", "couldn't", "can't", "Could"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Make sentences using phrases from the boxes.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 23px; width: 26cm'>
        <img style="width:18cm; height:9cm" src='img/FriendsPlus/Page43/E3/1.jpg' /><br>
          <textarea id='1' rows='8'>
        
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E4",
    exerciseKey: "img/FriendsPlus/Page43/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/1.25.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 126,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["O / P", "N / O", "A"],
    titleQuestion: [
      {
        num: "4",
        title: `Read examples 1–6. Write <i>A</i> (advice), <i>O / P</i> (obligation or prohibition) or <i>N / O</i> (no 
        obligation).`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; height: 600px; font-size: 23px'>
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 44px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;You must be careful.&ensp; <select id=0></select></div>
            
            <div style="display: flex"><b>2</b> &ensp;You mustn’t listen to music when you’re 
            cycling. &ensp;<select id=1></select></div> 
            
            <div style="display: flex"><b>3</b> &ensp;You have to concentrate if you’re on the road. &ensp;<select id=2></select></div>

            <div style="display: flex"><b>4</b> &ensp;You don’t have to practise all day every day. &ensp; <select id=3></select></div>

            <div style="display: flex"><b>5</b> &ensp;You should look where you’re going. &ensp; <select id=4></select></div>

            <div style="display: flex"><b>6</b> &ensp;You could have a break sometimes. &ensp; <select id=5></select></div>
          </div>
        </div>
        `,
        InputRong: true,
        answer: ["O / P", "O / P", "O / P", "N / O", "A", "A"],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E5",
    audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    recorder: true,
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.27 src='Audios/1.27 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        <span style='color: #5cc9dd'>PRONUNCIATION: Silent letters</span> Listen. Which letters are silent? Listen again and 
        repeat.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 5,
        listWords: [
          "It could distract him",
          "We mustn't copy in exams", //2
          "I couldn't finish my project",
          "They should help us",
        ],
        answers: ["0-2", "1-2", "2-2", "3-2"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 100px'>
       <div style='display: flex; width: 17cm; line-height: 50px; '>
         <div>
           <div style=""><b>1</b> &ensp; <input id='0' type='Circle' />. 
           </div>
    
           <div style=""><b>2</b> &ensp; <input id='1' type='Circle' />.
           </div>

           <div style=""><b>3</b> &ensp; <input id='2' type='Circle' />.
           </div>

           <div style=""><b>4</b> &ensp; <input id='3' type='Circle' />.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  6: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E6",
    // audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E6answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    recorder: true,
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "6",
        title: `Choose the correct options.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "mustn't / shouldn't",
          "don't_have_to / mustn't", //2
          "must / should",
          "mustn't / don't_have_to",
          "have_to / don't_have_to",
          "shouldn't / don't_have_to",
        ],
        answers: ["0-4", "1-0", "2-0", "3-0", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 50px; font-size: 23px'>
       <div style='display: flex; width: 25cm; line-height: 50px; '>
         <div>
           <div style=""><b>1</b> &ensp; You <input id='0' type='Circle' /> wake her up. She looks 
           so peaceful. </div>
    
           <div style=""><b>2</b> &ensp; You <input id='1' type='Circle' /> help, but you can 
           if you’ve got time.
           </div>

           <div style=""><b>3</b> &ensp; You <input id='2' type='Circle' /> turn off all music 
           after midnight. That’s the law.
           </div>

           <div style=""><b>4</b> &ensp; I <input id='3' type='Circle' /> eat 
           chocolate. I’m allergic to it.
           </div>

           <div style=""><b>5</b> &ensp; You <input id='4' type='Circle' /> 
           be in bed to feel relaxed.
           </div>

           <div style=""><b>6</b> &ensp; We <input id='5' type='Circle' /> 
           distract her. She’s concentrating.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  7: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P43-E7",
    // exerciseKey: "img/FriendsPlus/Page43/Key/E4answerKey.png",
    component: T6,
    // audio: "Audios/1.25.mp3",
    // maxLength:4,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "7",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/>
        Work in pairs. Complete the sentences with your own ideas.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        For the world to be a peaceful place, …<br>
        <b>1</b>&ensp; we should #.<br>
        <b>2</b>&ensp; we shouldn’t #.<br>
        <b>3</b>&ensp; we must #.<br>
        <b>4</b>&ensp; we mustn’t #.<br>
        <b>5</b>&ensp; we don’t have to #.<br><br>

        To be in a good mood every day, …<br>
        <b>6</b>&ensp; you should #.<br>
        <b>7</b>&ensp; you shouldn’t #.<br>
        <b>8</b>&ensp; you have to #.<br>
        <b>9</b>&ensp; you don’t have to #.<br>

        </div>
        `,

        answer: [""],
      },
    ],
  },
};

export default json;
