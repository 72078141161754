import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  5: {
    unit: "Starter",
    id: "SB9-2024-Starter-P8-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page8/E5/1.jpg" }]],
  },
  1: {
    unit: "Starter",
    id: "SB9-2024-Starter-P8-E1",
    exerciseKey: "img/FriendsPlus/Page8/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter: true,
    textAlign: "left",
    stylesTextInput: {
      width: 200,
      fontSize: 22,
      background: "none",
      borderBottom: "dotted 2px",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "app",
          "calculator",
          "games console",
          "GPS",
          "HD TV",
          "remote control",
          "satellite TV",
          "spellchecker",
          "translator",
          "video calling",
          "VR headset",
          "<s>wearable</s>",
        ],
        width: 800,
        borderColor: "#F78326",
        // marginLeft: 10,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match eight words from the box with photos 1–8.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img src="img/FriendsPlus/Page8/E1/1.jpg" style='height:17cm; width: 24cm'/>
        
       
        <div style='margin-left: 50px;margin-top: 10px;display:flex; font-size: 22px'>
        <b style='margin-right: 50px'>ANSWER:</b>
          <div style='margin-right: 50px; display: flex; flex-direction: column'>
            <div><b>1</b>&ensp;<b style="color: gray">wearable</b></div>
            <div><b>2</b>&ensp;<b>#</b></div>
            <div><b>3</b>&ensp;<b>#</b></div>
            <div><b>4</b>&ensp;<b>#</b></div>
          </div>
          <div style='margin-right: 50px; display: flex; flex-direction: column'>
          <div><b>5</b>&ensp;<b>#</b></div>
          <div><b>6</b>&ensp;<b>#</b></div>
          <div><b>7</b>&ensp;<b>#</b></div>
          <div><b>8</b>&ensp;<b>#</b></div>
          </div>
            
              
        </div>
        
        `,
        answer: [
          "calculator",
          "remote control",
          "VR headset",
          "app",
          "games console",
          "GPS",
          "satellite TV",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB9-2024-Starter-P8-E2",
    exerciseKey: "img/FriendsPlus/Page8/Key/E2answerKey.png",
    component: T6,
    audio: "Audios/1.03.mp3",
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    giaiThua: true,
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.03 src='Audios/1.03 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Listen to a conversation. Which things
        from exercise 1 do the people mention?`,
        color: "#F78024",
        left: 50,
        width: "28cm",
      },
    ],
    character: ",",
    questions: [
      {
        title: `

        <img src="img/FriendsPlus/Page8/E2/1.jpg" style='height:20cm; width: 22cm'/>

        <div style='margin-left: 50px;margin-top: 10px; font-size: 22px'>
        <b style='margin-right: 50px'>ANSWER:</b>
        
            <div><textarea id='0' rows='3'></div>

        </div>

        `,
        answer: [
          "HD TV, remote control, satellite TV, video calling, GPS, calculator, spellchecker, app",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB9-2024-Starter-P8-E3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E3answerKey.png",
    component: T6,
    audio: "Audios/1.03.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      borderStyle: "dotted",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 85,
      textAlign: "center",
      fontSize: 19,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.03 src='Audios/1.03 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Listen again and write <i>true</i> or <i>false</i>.`,
        color: "#F78024",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 40px'>
        

        <div style=''>
         <div style="display: flex"><b>1</b>&ensp;Zoe’s grandmother is watching an old TV. &ensp; <select id=0></select></div>
         <div style="display: flex"><b>2</b>&ensp;Zoe’s grandmother really likes video calling. &ensp;<select id=1></select></div>
         <div style="display: flex"><b>3</b>&ensp;She thinks that people had more skills in the past. &ensp;<select id=2></select></div>
         <div style="display: flex"><b>4</b>&ensp;She didn’t use a calculator when she was 
         younger. &ensp;<select id=3></select></div>
         <div style="display: flex"><b>5</b>&ensp;Zoe knows how to make coffee. &ensp;<select id=4></select></div>
         
        </div>
        </div>
        
        `,
        answer: ["false", "true", "false", "true", "true"],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB9-2024-Starter-P8-E4",
    exerciseKey: "img/FriendsPlus/Page8/Key/E6answerKey.png",
    component: T6,
    recorder: true,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 900, paddingTop: 5, fontSize: 22 },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix></prefix> Work in pairs. Study the words 
        in exercise 1. Ask and answer the questions.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "app",
          "calculator",
          "games console",
          "GPS",
          "HD TV",
          "remote control",
          "satellite TV",
          "spellchecker",
          "translator",
          "video calling",
          "VR headset",
          "<s>wearable</s>",
        ],
        width: 800,
        borderColor: "#F78326",
        // marginLeft: 10,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
          <div style='margin-left:0px; font-size: 22px; margin-top: 20px'>
            <b>1</b>&ensp;Which are necessities and which are luxuries?<br>#<br>
            <b>2</b>&ensp;Which are the three most useful?<br>#<br>
            <b>3</b>&ensp;Which are the three least useful?<br>#<br>
            <b>4</b>&ensp;Which are the most and least expensive?<br>#<br>
            <b>5</b>&ensp;Which do your grandparents use?<br>#<br>
            
          </div>
          
        
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
};

export default json;
