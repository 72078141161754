import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  6: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P58-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "60%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page58/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P58-E1",
    exerciseKey: "img/FriendsPlus/Page58/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      width: 230,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Read the quiz and check the meaning of the 
        words in <span style='color: #5cc9dd'>blue</span>. Then match five of the words 
        with an opposite personality type in the box.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    hintBox: [
      {
        src: [
          "<s>dreamer</s>",
          "introvert",
          "leader",
          "pessimist",
          "rebel",
          "team player",
        ],
        width: 880,
        borderColor: "#203c8f",
        marginLeft: 0,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='font-size: 21px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page58/E1/1.jpg" style='height:30.5cm; width: 28cm '/>
          <div style='display: flex; justify-content: center; text-align: center;'>
            <div>
              <div>dreamer<br><i style='color: gray'>realist</i></div>
              <div>introvert<br>#</div>
              <div>leader<br>#</div>
            </div>
            <div style="margin-left: 70px">
              <div>pessimist<br>#</div>
              <div>rebel<br>#</div>
              <div>team player<br>#</div>
            </div>
          
          </div>
           
        </div> 
        `,
        answer: ["extrovert", "follower", "optimist", "conformist", "loner"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P58-E2",
    // exerciseKey: "img/FriendsPlus/Page58/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 21,
      height: 27,
      width: 70,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Do the quiz. Then write a list of 4–6 words that describe you.`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    hintBox: [
      {
        src: [
          "<s>dreamer</s>",
          "introvert",
          "leader",
          "pessimist",
          "rebel",
          "team player",
        ],
        width: 880,
        borderColor: "#203c8f",
        marginLeft: 0,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 21px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page58/E1/1.jpg" style='height:30.5cm; width: 28cm '/>
          <div><b>Answer:</b></div>
          <div style='display: flex; justify-content: flex-start; text-align: center;'>
            <div>
              <div><b>1 - </b>&ensp;#</div>
              <div><b>2 - </b>&ensp;#</div>
              <div><b>3 - </b>&ensp;#</div>
            </div>
            <div style="margin-left: 70px">
              <div><b>4 - </b>&ensp;#</div>
              <div><b>5 - </b>&ensp;#</div>
            </div>
          
          </div>
           <b>Write a list of 4–6 words that describe you.</b><br>
           <div><input id=5 width='800px'/></div>
        </div> 
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P58-E3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E3answerKey.png",
    component: T6,
    audio: "Audios/2.02.mp3",
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 250,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.02 src='Audios/2.02 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen to three pairs of 
        friends talking about their personality. Are they 
        'chalk and cheese' or 'birds of a feather'?`,
        color: "#203c8f",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page58/E3/1.jpg" style='height:4.5cm; width: 18cm '/>
          <div style='margin-left: 50px'>
            <div>
              <div><b>1 </b>Olivia and Tegan<br>#</div>
              <div><b>2 </b>Sam and Joelle<br>#</div>
              <div><b>3 </b>Daniel and Rob<br>#</div>
            </div>
          
          </div>
        </div> 
        `,
        answer: ["chalk and cheese", "chalk and cheese", "birds of a feather"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P58-E4",
    exerciseKey: "img/FriendsPlus/Page58/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/2.02.mp3",
    // textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 750,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.02 src='Audios/2.02 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Study the Key Phrases. Then watch 
        or listen again. What type of person is each speaker?`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    character: /[,/]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 44px'>
	        <img src="img/FriendsPlus/Page58/E4/1.jpg" style='height:8.5cm; width: 18cm; margin-left: 100px '/>
          <div style='margin-left: 50px'>
            <div>
              <div>Olivia - #</div>
              <div>Tegan - #</div>
              <div>Sam - #</div>
              <div>Joelle - #</div>
              <div>Daniel - #</div>
              <div>Rob - #</div>
            </div>
          
          </div>
        </div> 
        `,
        answer: [
          "introvert",
          "extrovert",
          "pessimist",
          "dreamer / optimist",
          "nature lover in summer, couch potato in winter",
          "nature lover in summer, couch potato in winter",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P58-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    hintBox: [
      {
        src: [
          "dreamer",
          "introvert",
          "leader",
          "pessimist",
          "rebel",
          "team player",
        ],
        width: 880,
        borderColor: "#203c8f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/7.jpg'/>
        Work in groups. Discuss 
        personality of a famous people. Use the words from exercise 1.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],

    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='height: 100px'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
